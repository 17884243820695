function Feature() {
  return (
    <div
      id="o-nas"
      className="flex flex-col items-start lg:items-center justify-start lg:justify-center text-white px-6 mt-12 lg:mt-0 lg:px-24"
    >
      <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
        O nas
      </p>
      <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4 text-center lg:text-left">
        Co nas wyróżnia
      </p>
      <span className="mainColorBg w-24 h-1 mb-6"></span>
      <div className="grid grid-cols-1 lg:hidden gap-12 lg:gap-12">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-1.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Aplikacja do ofertowania</p>
            <p className="opacity-60 text-lg">
              Każdy Klient w ramach realizacji otrzymuje bezpłatny i
              bezterminowy dostęp do naszej autorskiej aplikacji Let’s Offer.
              System pozwala na tworzenie szybkich i estetycznych ofert dla
              Twoich potencjalnych Klientów.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-2.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Dedykowany Opiekun</p>
            <p className="opacity-60 text-lg">
              Nie musisz martwić się, o kontakt z nami. Od początku do końca
              będziesz kontaktować się z jedną osobą, która poprowadzi Cię przez
              cały proces realizacji oraz utrzymania projektu.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-3.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Gwarancja</p>
            <p className="opacity-60 text-lg">
              Projekty objęte są 48-miesięczną gwarancją. Nasza polityka zakłada
              rozpatrzenie gwarancji w maksymalnie trzy dni. Uwaga! Jeszcze
              żaden z naszych Klientów nie składał gwarancji!
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-4.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">
              100% projektów w czasie i budżecie
            </p>
            <p className="opacity-60 text-lg">
              Od czasu rozpoczęcia działalności iFil Group zrealizowaliśmy 100%
              projektów w ustalonym terminie oraz określonym budżecie. Z nami
              nie musisz martwić się o dodatkowe koszty oraz czas realizacji.
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:grid grid-cols-2 gap-6 lg:gap-12">
        <div className="flex flex-col gap-6 lg:gap-12">
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-1.png"
              alt="Ikona"
              className="w-24"
            />
            <div>
              <p className="font-bold text-2xl mb-1">
                Aplikacja do ofertowania
              </p>
              <p className="opacity-60 text-lg">
                Każdy Klient w ramach realizacji otrzymuje bezpłatny i
                bezterminowy dostęp do naszej autorskiej aplikacji Let’s Offer.
                System pozwala na tworzenie szybkich i estetycznych ofert dla
                Twoich potencjalnych Klientów.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-2.png"
              alt="Ikona"
              className="w-24"
            />
            <div>
              <p className="font-bold text-2xl mb-1">Dedykowany Opiekun</p>
              <p className="opacity-60 text-lg">
                Nie musisz martwić się, o kontakt z nami. Od początku do końca
                będziesz kontaktować się z jedną osobą, która poprowadzi Cię
                przez cały proces realizacji oraz utrzymania projektu.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-3.png"
              alt="Ikona"
              className="w-24"
            />
            <div>
              <p className="font-bold text-2xl mb-1">Gwarancja</p>
              <p className="opacity-60 text-lg">
                Projekty objęte są 48-miesięczną gwarancją. Nasza polityka
                zakłada rozpatrzenie gwarancji w maksymalnie trzy dni. Uwaga!
                Jeszcze żaden z naszych Klientów nie składał gwarancji!
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/8feature-ico-4.png"
              alt="Ikona"
              className="w-24"
            />
            <div>
              <p className="font-bold text-2xl mb-1">
                100% projektów w czasie i budżecie
              </p>
              <p className="opacity-60 text-lg">
                Od czasu rozpoczęcia działalności iFil Group zrealizowaliśmy
                100% projektów w ustalonym terminie oraz określonym budżecie. Z
                nami nie musisz martwić się o dodatkowe koszty oraz czas
                realizacji.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/mockup-6benefits.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Feature;
