import Hero from "../components/contact/hero";
import Faq from "../components/contact/faq";
import Testimonials from "../components/contact/testimonials";
import LogoSlider from "../components/contact/logoSlider";
import Process from "../components/contact/process";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function Contact() {
  return (
    <>
      <Helmet>
        <title>Kontakt | iFil Group</title>
        <meta name="title" content="Kontakt | iFil Group" />
        <meta
          name="description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta property="og:title" content="Kontakt | iFil Group" />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Process />
        <Faq />
        <Testimonials />
        <LogoSlider />
      </main>
      <Footer />
    </>
  );
}
export default Contact;
