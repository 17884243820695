import React from "react";

import "./index.css";
import App from "./App";

import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";

const tagManagerArgs = {
  gtmId: "GTM-MX5R7CRP",
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
