import Hero from "../components/refresh/hero";
import Content from "../components/refresh/content";
import { Gemini } from "../components/refresh/gemini";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function Refresh() {
  return (
    <>
      <Helmet>
        <title>Odświeżenie wizerunku | iFil Group</title>
        <meta name="title" content="Odświeżenie wizerunku | iFil Group" />
        <meta
          name="description"
          content="Odświeżymy wizerunek Twojej firmy, strony internetowej, sklepu online, aplikacji oraz brandingu, aby przyciągnąć nowych klientów i zwiększyć sprzedaż."
          key="desc"
        />
        <meta
          property="og:title"
          content="Odświeżenie wizerunku | iFil Group"
        />
        <meta
          property="og:description"
          content="Odświeżymy wizerunek Twojej firmy, strony internetowej, sklepu online, aplikacji oraz brandingu, aby przyciągnąć nowych klientów i zwiększyć sprzedaż."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Content />
        <Gemini />
      </main>
      <Footer />
    </>
  );
}
export default Refresh;
