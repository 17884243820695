function Content() {
  return (
    <section className="blackColorBg px-6 lg:px-24 lg:pt-12">
      <div className="flex flex-col items-center justify-center lg:px-24 pt-12">
        <h3 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">
            Wykorzystaj nowoczesne
          </span>{" "}
          <br />
          <span className="whiteHeading fontRegular">technologie w</span>{" "}
          <span className="colorHeading fontRegular">swojej firmie</span>
        </h3>

        <p className="text-white text-xl lg:text-3xl opacity-60 my-4 w-full lg:w-2/3 text-center">
          Zaprojektujemy oraz wdrożymy aplikacje zintegrowane z OpenAI, które
          pozwoli na szybszy rozwój Twojej firmy.
        </p>
      </div>
      <div className="flex flex-col lg:grid grid-cols-3 gap-4 w-full mt-12">
        <div className="col-span-1 rounded-xl overflow-hidden text-white mixedGradients relative p-12">
          <div className="pb-6">
            <p className="text-white fontRegular text-2xl mb-2">
              Bezpieczeństwo
            </p>
            <p>
              Ochrona danych klientów dzięki zaawansowanym protokołom
              bezpieczeństwa.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center lightWhite blackColorBg rounded-full w-full pt-2 pb-4 text-xl">
              <span className="mainColor fontRegular">https</span>
              ://twoja-aplikacja.pl
            </p>
          </div>
        </div>
        <div className="col-span-2 lightWhite pl-12 pt-12 flex flex-col lg:flex-row items-end rounded-xl text-white overflow-hidden gap-12">
          <div className="flex flex-col items-start justify-start w-full h-full">
            <p className="mainColor fontRegular text-2xl mb-2">
              Intuicyjny interfejs
            </p>
            <p>
              Przyjazne dla użytkownika rozwiązania, które skracają czas
              obsługi.
            </p>
          </div>
          <div className="flex flex-row items-end justify-end gap-12">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Application/aplikacje-interfejs.webp"
              alt="Interfejs"
              className="rounded-tl-xl firstShadow w-5/6"
            />
          </div>
        </div>
        <div className="col-span-2 lightWhite rounded-xl text-white flex flex-col lg:flex-row items-center justify-between relative overflow-hidden">
          <div className="flex flex-col w-full p-12">
            <p className="mainColor fontRegular text-2xl mb-2">Responsywność</p>
            <p>
              Elastyczne aplikacje, dostosowane do różnych urządzeń,
              zapewniające doskonałe doświadczenie użytkownika.
            </p>
          </div>
          <div className="flex flex-col items-end justify-end h-full w-full">
            <img
              className="rounded-tl-xl w-full firstShadowDrop"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/iphone-mockup.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white pt-12 overflow-hidden">
          <div className="flex flex-col w-full mb-6 px-12">
            <p className="mainColor fontRegular text-2xl mb-2">
              Kompatybilność
            </p>
            <p>
              Zintegrowane rozwiązania, gwarantujące bezproblemową współpracę z
              istniejącymi infrastrukturami IT.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Application/aplikacje-api-n.png"
              alt="API"
              className="firstShadowDrop"
            />
          </div>
        </div>
        <div className="col-span-1 flex flex-col items-center justify-center lg:block lightWhite rounded-xl text-white relative overflow-hidden">
          <div className="flex flex-col w-full pt-12 px-12">
            <p className="text-white fontRegular text-2xl mb-2 mainColor">
              Skalowalność
            </p>
            <p>
              Oprogramowanie dostosowane do rosnących wymagań, umożliwiające
              płynny rozwój biznesu.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Application/aplikacje-skalowalnosc.png"
            alt="Kwadraty ze strzałkami"
            className="w-24 mb-6 lg:mb-0 lg:absolute bottom-6 right-6"
          />
        </div>
        <div className="col-span-2 mixedGradients flex flex-col lg:flex-row items-end justify-between rounded-xl text-white overflow-hidden">
          <div className="flex flex-col w-full p-12 h-full items-start justify-center">
            <p className="text-white fontRegular text-2xl mb-2">
              Automatyzacje
            </p>
            <p>
              Zautomatyzowane funkcje, które zwiększają wydajność i redukują
              koszty operacyjne.
            </p>
          </div>
          <img
            className="rounded-tl-xl w-3/4 lg:w-1/2 lg:mt-12"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Application/aplikacje-automatyzacje.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
export default Content;
