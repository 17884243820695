import BackgroundSquare from "../common/squareBg";
import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import BottomNav from "./bottomNav";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import { useState } from "react";
import LeftMenu from "./leftMenu";
import TopMenu from "./topMenu";
const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

function Booking() {
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div className="flex flex-row h-screen">
        <LeftMenu />
        <div className="flex flex-col items-center justify-start overflow-hidden relative h-full w-full">
          <TopMenu />
          <div className="blackColorBg w-full h-full relative flex flex-col">
            <div className="w-full h-full absolute z-10">
              <BackgroundSquare />
            </div>
            <div className="w-full h-full z-20 flex flex-col gap-12">
              <iframe
                src="https://outlook.office365.com/owa/calendar/FilipKaniaiFilGroup@ifil.pl/bookings/"
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="z-50 fixed w-full bottom-0">
        <BottomNav />
      </div>{" "}
    </ThemeProvider>
  );
}
export default Booking;
