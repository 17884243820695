import BackgroundSquare from "../common/squareBg";
import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import LeftMenu from "./leftMenu";
import TopMenu from "./topMenu";
import BottomNav from "./bottomNav";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

function NewTicket() {
  const outerTheme = useTheme();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    project: "",
    description: "",
    user_id: "",
  });

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") ?? "");
    const userId = userInfo ? userInfo.user_id : ""; // Pusty ciąg znaków jako wartość domyślna
    console.log("User ID from localStorage:", userId);
    if (userId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: userId,
      }));
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo") ?? "");
      const user_id = userInfo ? userInfo.user_id : ""; // Pusty ciąg znaków jako wartość domyślna
      console.log("Data before sending:", formData); // Dodaj to wyświetlenie danych
      console.log("Id before sending", user_id); // Dodaj to wyświetlenie danych
      const response = await axios.post<any>(
        `${BASE_URL}/api/accounts/create-ticket/`,
        { ...formData, user_id: user_id }
      );
      console.log("Response:", response.data);
      console.log(response.data);
      navigate("/panel");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      } else {
        console.error("Non-Axios Error:", error);
      }
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div className="flex flex-col lg:flex-row lg:h-screen">
        <LeftMenu />
        <div className="flex flex-col items-center justify-start overflow-hidden relative h-full w-full">
          <TopMenu />
          <div className="blackColorBg w-full h-full relative flex flex-col">
            <div className="w-full h-screen lg:h-full fixed lg:absolute z-10">
              <BackgroundSquare />
            </div>
            <div className="w-full h-full z-20 p-6 lg:p-12 flex flex-col gap-6 lg:gap-12">
              <h2 className="text-2xl lg:text-4xl text-white">
                Nowe zgłoszenie
              </h2>
              <form action="" onSubmit={handleSubmit}>
                <div className=" gap-12">
                  {" "}
                  <div className="p-6 lg:p-12 lightWhite rounded-xl backdrop-blur-3xl flex flex-col gap-4 items-start justify-start">
                    <h3 className="text-xl lg:text-2xl text-white">
                      Uzupełnij poniższe dane i złóż dyspozycję
                    </h3>
                    <span className="w-12 h-[1px] bg-white"></span>
                    <TextField
                      id="standard-basic"
                      label="Nazwa zgłoszenia"
                      variant="standard"
                      className="w-full"
                      color="secondary"
                      name="title"
                      onChange={handleChange}
                    />
                    <TextField
                      id="standard-basic"
                      label="Projekt, którego dotyczy zgłoszenie"
                      variant="standard"
                      className="w-full"
                      color="secondary"
                      name="project"
                      onChange={handleChange}
                    />
                    <TextField
                      id="standard-basic"
                      label="Opis zgłoszenia"
                      variant="standard"
                      className="w-full"
                      color="secondary"
                      rows={15}
                      name="description"
                      onChange={handleChange}
                    />
                    <button type="submit" className="firstButton mt-4">
                      Złóż dyspozycję
                    </button>
                  </div>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="z-50 fixed w-full bottom-0">
        <BottomNav />
      </div>{" "}
    </ThemeProvider>
  );
}
export default NewTicket;
