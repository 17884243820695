import BackgroundSquare from "../common/squareBg";
import BriefForm from "../forms/briefForm";
function Content() {
  return (
    <section className="blackColorBg flex flex-col items-center justify-start relative">
      <div className="w-full h-full object-cover absolute inset-0 z-10">
        <div className="w-full lg:h-screen sticky top-0">
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-12 lg:py-36 z-20 px-6 lg:px-36">
        <h1 className="mainColor fontLight text-sm text-center lg:text-xl">
          iFil Group - Brief
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">
            Pomóż nam przygotować
          </span>
          <br />
          <span className="whiteHeading fontRegular">jak najlepszy</span>{" "}
          <span className="colorHeading fontRegular">projekt</span>
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 my-4 w-full lg:w-2/3 text-center mb-12 lg:mb-24">
          Chcemy zrealizować jak najlepszy projekt, który pozwoli na rozwój
          Twojej marki. Poniższe pytania pozwolą nam poznać Twoje oczekiwania
          oraz charakter Twojej działalności
        </p>
        <div className="w-full lg:w-2/3 flex flex-col items-start justify-start p-6 lg:p-12 rounded-xl firstShadowLight backdrop-blur-3xl">
          <BriefForm />
        </div>
      </div>
    </section>
  );
}
export default Content;
