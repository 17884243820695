"use client";
import React from "react";
import { Carousel, Card } from "../additional/cards";

export function OfferCards() {
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="w-full h-full">
      <Carousel items={cards} />
    </div>
  );
}

const data = [
  {
    category: "E-commerce",
    title: "Sklepy internetowe, które sprzedają.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/OfferSlider/portfolio-mockup-5.webp",
    link: "/sklepy-internetowe",
  },
  {
    category: "Strony internetowe",
    title: "Strony, które wyprzedzą konkurencję.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/OfferSlider/portfolio-mockup-1.webp",
    link: "/strony-internetowe",
  },
  {
    category: "Aplikacje",
    title: "Aplikacje, które usprawnią firmę.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-finanse.webp",
    link: "/aplikacje",
  },
  {
    category: "Integracje AI",
    title: "Sztuczna inteligencja optymalizująca koszty.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/OfferSlider/portfolio-mockup-3.webp",
    link: "/integracje-ai",
  },
  {
    category: "Systemy CRM",
    title: "Systemy, które zapanują nad sprzedażą.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-uslugi.webp",
    link: "/systemy-crm",
  },
  {
    category: "Odświeżenie wizerunku",
    title: "Poprawa wizerunku Twojej marki.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/OfferSlider/portfolio-mockup-4.webp",
    link: "/odswiezenie-wizerunku",
  },
  {
    category: "Interfejsy graficzne",
    title: "Projekty, które zachęcają do zakupu.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/OfferSlider/portfolio-mockup-2.webp",
    link: "/projektowanie-interfejsow",
  },
  {
    category: "MVP",
    title: "Sprawdzenie pomysłu niskim kosztem.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-ecommerce.webp",
    link: "/minimum-viable-product",
  },
  {
    category: "Systemy rezerwacji",
    title: "Uwolnij swój czas dzięki systemom rezerwacji.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offercards-reservation%20Duz%CC%87y.jpeg",
    link: "/systemy-rezerwacji",
  },
  {
    category: "Automatyzacje",
    title: "Usprawnimy procesy Twojej firmy.",
    src: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offercards-auto%20Duz%CC%87y.jpeg",
    link: "/automatyzacja-procesow",
  },
];
