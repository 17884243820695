import { useEffect, useRef } from "react";

function Environment() {
  const svgRef1 = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
            entry.target.classList.remove("active");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (svgRef1.current) {
      observer.observe(svgRef1.current);
    }

    return () => {
      if (svgRef1.current) {
        observer.unobserve(svgRef1.current);
      }
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white p-6 lg:px-24">
      <div className="flex-col flex text-xl w-full items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          O nas
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Wspólnie zadbajmy o środowisko
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 mb-2">
          Za każdy zrealizowany projekt sadzimy 10 drzewek! Po zakończonym
          projekcie otrzymasz zaświadczenie od naszego Partnera.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/logo-posadzimy-collab.png"
          alt=""
          className="mt-2 lg:h-24"
        />
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-6 pt-12 lg:pt-0 forestSvg z-50 relative">
        <img
          className="lg:w-2/3"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/sadzimy-drzewa-ifil.webp"
          alt=""
        />
        <svg
          ref={svgRef1}
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          className="absolute bottom-6 lg:bottom-12 right-6 lg:right-12 w-[100px] lg:w-[200px] lg:h-[200px]"
        >
          <path
            className="st0 svg-elem-1"
            fill="none"
            stroke="#6f00d8"
            strokeWidth="2"
            d="M81.7,52.7c1.8-1,3.4-2.2,5-3.6l-8.4-12c1.8-0.9,3.5-2.1,5.1-3.5L66.7,10L54.8,26.9L50,20l-4.8,6.9L33.3,10
                L16.7,33.8c1.5,1.4,3.2,2.5,5.1,3.5l-8.4,12c1.5,1.4,3.2,2.6,5,3.6L10,64.6c4.7,4.3,10.6,7,16.7,8.1v10.6h6.7v-4.1
                c4.1,2.2,8.7,3.5,13.3,3.9V90h6.7v-6.9c4.6-0.4,9.2-1.7,13.3-3.9v4.1h6.7V72.7c6.1-1.1,11.9-3.8,16.7-8.1L81.7,52.7z M63.9,39.8
                c2.6,0.3,5.2,0.2,7.8-0.3l5.6,8c-3.2,1.6-6.9,2.5-10.7,2.5c-1.1,0-2.2-0.1-3.3-0.2l-1.8-2.6c1.8-0.9,3.5-2.1,5.1-3.5L63.9,39.8z
                M66.7,21.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4c-2.6,0-5-0.5-7.2-1.4L66.7,21.6z M60.7,57.5C57.5,59.1,53.8,60,50,60
                c-3.8,0-7.5-0.9-10.7-2.5l5.6-8c3.3,0.7,6.8,0.7,10.1,0L60.7,57.5z M50,31.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4
                c-2.6,0-5-0.5-7.2-1.4L50,31.6z M33.3,21.6l7.2,10.3c-2.2,0.9-4.7,1.4-7.2,1.4c-2.6,0-5-0.5-7.2-1.4L33.3,21.6z M28.3,39.5
                c2.6,0.5,5.2,0.6,7.8,0.3l-2.8,3.9c1.5,1.4,3.2,2.5,5.1,3.5l-1.8,2.6c-1.1,0.1-2.2,0.2-3.3,0.2c-3.8,0-7.5-0.9-10.7-2.5L28.3,39.5z
                M32.3,66.6c-4.7-0.2-9.2-1.4-13.1-3.5l5.4-7.7c2.4,0.7,4.8,1.1,7.2,1.2L30,59.2c1.5,1.4,3.2,2.6,5,3.6L32.3,66.6z M50,76.7
                c-5.1,0-9.9-1.3-14.1-3.6l5.4-7.7c5.7,1.7,11.8,1.7,17.5,0l5.4,7.7C59.9,75.4,55.1,76.7,50,76.7z M67.7,66.6L65,62.7
                c1.8-1,3.4-2.2,5-3.6l-1.8-2.6c2.4-0.1,4.9-0.5,7.2-1.2l5.4,7.7C76.9,65.2,72.4,66.4,67.7,66.6z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Environment;
