import GlobeModel from "../additional/globe";
import GlobeModelMobile from "../additional/globeMobile";
function ProjectGlobe() {
  return (
    <div className="pt-12 lg:px-0 lg:pt-24 flex flex-col items-center justify-center relative">
      <div className="flex flex-col items-center justify-center px-6">
        <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6 lg:mb-0">
          Nasze oferta
        </p>
        <h2 className="text-3xl lg:text-6xl whiteHeading text-center">
          Jeden projekt. Wiele możliwości.
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 w-full lg:w-1/2 text-center mb-6 lg:mb-12">
          Projekt może zostać zrealizowany w wielu technologiach, które wpływają
          na wydajność, funkcjonalności, a także bezpieczeństwo i koszty.
        </p>
        <a href="/bezplatna-konsultacja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span className="lg:block hidden">
              Wspólnie wybierzmy najlepsze rozwiązanie
            </span>
            <span className="lg:hidden block">Porozmawiajmy</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                  stroke="#6f00d8"
                  stroke-width="1.2"
                  stroke-linecap="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <div className="mainLight w-full h-64 translate-y-64 absolute bottom-0"></div>
      <div className="hidden lg:flex w-full flex-col items-center justify-center">
        <GlobeModel />
      </div>
      <div className="overflow-x-hidden w-full flex lg:hidden">
        <GlobeModelMobile />
      </div>
    </div>
  );
}
export default ProjectGlobe;
