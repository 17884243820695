import { useState } from "react";
import ReactDOM from "react-dom";

export function PricingProblemsModal() {
  const [isOpen, setIsOpen] = useState(false);

  const modalContent = isOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"
        onClick={() => setIsOpen(false)}
      ></div>
      <div className="relative bg-black text-white p-6 rounded-lg shadow-lg transform perspective-800 animate-show-modal w-[95%] lg:w-1/3 h-[80%] lg:h-1/2">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-2 right-2 text-xl"
        >
          X
        </button>
        <span className="mainColorBg w-12 h-1 mt-6 block"></span>
        <h2 className="text-white text-3xl mt-2 mb-6">
          Dlaczego o to <span className="mainColor fontRegular">pytamy</span>
        </h2>{" "}
        <p>
          Projekt informatyczny ingeruje w wiele płaszczyzn działania firmy.
          Zgłaszają się do nas firmy, które wskazują m.in. na problemy z
          rosnącymi kosztami marketingu, pozycjonowania, czy wydłużającym się
          procesem sprzedaży.
          <br />
          <br />
          Wspólnie z naszymi klientami analizujemy te problemy, a następnie
          opracowujemy strategię, która z wykorzystaniem najnowszych technologii
          pozwoli na ich rozwiązanie.
        </p>
      </div>
    </div>
  );

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="firstButton max-w-[250px] flex flex-row items-center justify-center gap-2 text-sm mt-2"
      >
        <svg
          width={15}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>{" "}
            <circle cx="12" cy="16" r="1" fill="#ffffff"></circle>{" "}
            <path
              d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>{" "}
          </g>
        </svg>
        <span>Dlaczego o to pytamy?</span>
      </button>
      {ReactDOM.createPortal(modalContent, document.body)}
    </>
  );
}
