import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";

import React, { ChangeEvent, useState } from "react";
import axios from "axios";
import BASE_URL from "../../config";
import { useNavigate } from "react-router-dom";
import { PricingProblemsModal } from "../modals/pricingProblems";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface BriefFormData {
  yourName: string;
  email: string;
  phoneNumber: string;
  currentWebsite: string;
  completionTimeframe: string;
  service: string;
  additionalInformation: string;
  industry: string;
  offeredProductsServices: string;
  marketPresenceDuration: string;
  marketingActivities: string;
  mainCompetition: string;
  projectPurpose: string;
  currentProblems: string;
  purchaseMotivators: string;
  marketScope: string;
  targetGroup: string;
  currentWebsiteLink: string;
  navigationStructure: string;
  projectLanguages: string;
  fontPreferences: string;
  brandColors: string;
  highlightedElements: string;
  visualIdentity: string;
  likedProjects: string;
  consent: boolean;
  integrationInvoiceSystem: boolean;
  integrationPaymentGateway: boolean;
  integrationCourierService: boolean;
  integrationAuctionSites: boolean;
  integrationERP: boolean;
  integrationDropshipping: boolean;
  functionalityBlog: boolean;
  functionalityNewsletter: boolean;
  functionalityLiveChat: boolean;
  functionalityContactForm: boolean;
  functionalitySalesSystem: boolean;
  functionalityTicketSystem: boolean;
}

function BriefForm() {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<BriefFormData>({
    yourName: "",
    email: "",
    phoneNumber: "",
    currentWebsite: "",
    completionTimeframe: "",
    service: "",
    additionalInformation: "",
    industry: "",
    offeredProductsServices: "",
    marketPresenceDuration: "",
    marketingActivities: "",
    mainCompetition: "",
    projectPurpose: "",
    currentProblems: "",
    purchaseMotivators: "",
    marketScope: "",
    targetGroup: "",
    currentWebsiteLink: "",
    navigationStructure: "",
    projectLanguages: "",
    fontPreferences: "",
    brandColors: "",
    highlightedElements: "",
    visualIdentity: "",
    likedProjects: "",
    consent: false,
    integrationInvoiceSystem: false,
    integrationPaymentGateway: false,
    integrationCourierService: false,
    integrationAuctionSites: false,
    integrationERP: false,
    integrationDropshipping: false,
    functionalityBlog: false,
    functionalityNewsletter: false,
    functionalityLiveChat: false,
    functionalityContactForm: false,
    functionalitySalesSystem: false,
    functionalityTicketSystem: false,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const { name } = event.target;
    const value = isCheckbox ? checked : event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/brief-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Failed to submit the form");
    }
  };

  const [service, setService] = React.useState("");
  const handleChangeService = (event: SelectChangeEvent) => {
    setService(event.target.value as string);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <span className="mainColorBg w-12 h-1"></span>
        <h2 className="text-white text-3xl">
          Dane <span className="mainColor fontRegular">kontaktowe</span>
        </h2>

        <TextField
          label="Imię i nazwisko / Nazwa firmy"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.yourName}
          onChange={handleChange}
          name="yourName"
        />
        <TextField
          label="Twój adres e-mail"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.email}
          onChange={handleChange}
          name="email"
        />
        <TextField
          label="Twój numer telefonu"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.phoneNumber}
          onChange={handleChange}
          name="phoneNumber"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Informacje dotyczące{" "}
          <span className="mainColor fontRegular">usługi</span>
        </h2>

        <FormControl fullWidth>
          <InputLabel id="service-label">Usługa</InputLabel>
          <Select
            labelId="service-label"
            id="service-select"
            value={formData.service}
            label="Usługa"
            onChange={handleSelectChange}
            name="service"
          >
            <MenuItem value="Strona internetowa">Strona internetowa</MenuItem>
            <MenuItem value="Sklep on-line">Sklep on-line</MenuItem>
            <MenuItem value="Projekt interfejsu graficznego">
              Projekt interfejsu graficznego
            </MenuItem>
            <MenuItem value="Aplikacja internetowa">
              Aplikacja internetowa
            </MenuItem>
            <MenuItem value="Integracja AI">Integracja AI</MenuItem>
            <MenuItem value="Aplikacja mobilna">Aplikacja mobilna</MenuItem>
            <MenuItem value="System CRM">System CRM</MenuItem>
            <MenuItem value="Automatyzacje">Automatyzacje</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Jaki termin realizacji Cię interesuje?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.completionTimeframe}
          onChange={handleChange}
          name="completionTimeframe"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Pozycja Twojej firmy{" "}
          <span className="mainColor fontRegular">na rynku</span>
        </h2>
        <TextField
          label="Branża Twojej firmy"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.industry}
          onChange={handleChange}
          name="industry"
        />
        <TextField
          label="Oferowane produkty lub usługi"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.offeredProductsServices}
          onChange={handleChange}
          name="offeredProductsServices"
        />
        <TextField
          label="Od kiedy Twoja firma działa na rynku?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.marketPresenceDuration}
          onChange={handleChange}
          name="marketPresenceDuration"
        />
        <TextField
          label="Dotychczasowe działania marketingowe"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.marketingActivities}
          onChange={handleChange}
          name="marketingActivities"
        />
        <TextField
          label="Główna konkurencja Twojej firmy"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.mainCompetition}
          onChange={handleChange}
          name="mainCompetition"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Twoje <span className="mainColor fontRegular">potrzeby</span>
        </h2>
        <TextField
          label="Dlaczego chcesz stworzyć nowy projekt, jaki ma być jego cel?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.projectPurpose}
          onChange={handleChange}
          name="projectPurpose"
        />
        <TextField
          label="Czy są obecnie problemy, z którymi mierzy się Twoja firma?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.currentProblems}
          onChange={handleChange}
          name="currentProblems"
        />
        <TextField
          label="Co najbardziej przekonuje Cię do zakupu, oprócz ceny?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.purchaseMotivators}
          onChange={handleChange}
          name="purchaseMotivators"
        />
        <TextField
          label="Na jakim rynku chcesz działać (lokalnie, cały kraj, kontynent)?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.marketScope}
          onChange={handleChange}
          name="marketScope"
        />
        <TextField
          label="Twoja grupa docelowa"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.targetGroup}
          onChange={handleChange}
          name="targetGroup"
        />
        <TextField
          label="Link do obecnej strony Twojej firmy"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.currentWebsiteLink}
          onChange={handleChange}
          name="currentWebsiteLink"
        />
        <PricingProblemsModal />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Specyfikacja techniczna Twojego{" "}
          <span className="mainColor fontRegular">projektu</span>
        </h2>
        <TextField
          label="Wstępna struktura nawigacji (np. 'Strona główna' / 'Panel Klienta' / 'Kontakt')"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.navigationStructure}
          onChange={handleChange}
          name="navigationStructure"
        />
        <TextField
          label="W jakich językach ma być zrealizowany projekt (np. polski, angielski)?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.projectLanguages}
          onChange={handleChange}
          name="projectLanguages"
        />
        <h3 className="text-white text-xl">Integracje</h3>
        <FormGroup>
          <div className="grid grid-cols-1 lg:grid-cols-3 text-white opacity-60">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationInvoiceSystem}
                  onChange={handleChange}
                  name="integrationInvoiceSystem"
                />
              }
              label="System fakturowy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationPaymentGateway}
                  onChange={handleChange}
                  name="integrationPaymentGateway"
                />
              }
              label="Bramka płatnicza"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationCourierService}
                  onChange={handleChange}
                  name="integrationCourierService"
                />
              }
              label="System kurierski"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationAuctionSites}
                  onChange={handleChange}
                  name="integrationAuctionSites"
                />
              }
              label="Portale aukcyjne"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationERP}
                  onChange={handleChange}
                  name="integrationERP"
                />
              }
              label="System ERP"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.integrationDropshipping}
                  onChange={handleChange}
                  name="integrationDropshipping"
                />
              }
              label="Dropshipping"
            />
          </div>
        </FormGroup>
        <h3 className="text-white text-xl">Funkcjonalności</h3>
        <FormGroup className="text-white opacity-60">
          <div className="grid grid-cols-1 lg:grid-cols-3 text-white">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalityBlog}
                  onChange={handleChange}
                  name="functionalityBlog"
                />
              }
              label="Blog"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalityNewsletter}
                  onChange={handleChange}
                  name="functionalityNewsletter"
                />
              }
              label="Newsletter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalityLiveChat}
                  onChange={handleChange}
                  name="functionalityLiveChat"
                />
              }
              label="Czat na żywo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalityContactForm}
                  onChange={handleChange}
                  name="functionalityContactForm"
                />
              }
              label="Formularz kontaktowy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalitySalesSystem}
                  onChange={handleChange}
                  name="functionalitySalesSystem"
                />
              }
              label="System sprzedażowy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.functionalityTicketSystem}
                  onChange={handleChange}
                  name="functionalityTicketSystem"
                />
              }
              label="System biletowy"
            />
          </div>
        </FormGroup>

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Preferencje dotyczące{" "}
          <span className="mainColor fontRegular">interfejsu graficznego</span>
        </h2>
        <TextField
          label="Preferowane czcionki"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.fontPreferences}
          onChange={handleChange}
          name="fontPreferences"
        />
        <TextField
          label="Jaką kolorystykę ma Twoja marka?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.brandColors}
          onChange={handleChange}
          name="brandColors"
        />
        <TextField
          label="Jakie elementy powinny zostać wyróżnione na stronie?"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.highlightedElements}
          onChange={handleChange}
          name="highlightedElements"
        />
        <TextField
          label="Dodatkowe informacje dot. indentyfikacji wizualnej"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.visualIdentity}
          onChange={handleChange}
          name="visualIdentity"
        />
        <TextField
          label="Linki do 2-3 projektów, które Ci się podobają"
          variant="standard"
          fullWidth
          color="secondary"
          value={formData.likedProjects}
          onChange={handleChange}
          name="likedProjects"
        />

        <span className="mainColorBg w-12 h-1 mt-6"></span>
        <h2 className="text-white text-3xl">
          Dodatkowe <span className="mainColor fontRegular">informacje</span>
        </h2>

        <TextField
          label="Czy chcesz nam coś jeszcze przekazać?"
          variant="standard"
          fullWidth
          color="secondary"
          multiline
          rows={4}
          value={formData.additionalInformation}
          onChange={handleChange}
          name="additionalInformation"
        />

        <div className="flex flex-row items-start justify-start gap-2">
          <Checkbox
            checked={formData.consent}
            onChange={handleChange}
            name="consent"
            id="consent"
            required={true}
          />
          <label
            htmlFor="consent"
            className="text-xs opacity-50 mb-2 text-white"
          >
            Wyrażam zgodę na kontakt telefoniczny oraz mailowy celem realizacji
            niniejszego zapytania. Oświadczam, że zapoznałem/am się z
            Regulaminem Serwisu oraz Polityką Prywatności i akceptuję
            postanowienia nieniejszych dokumentów. Wyrażam zgodę na
            wykorzystanie adresu e-mail w celach marketingowych przez iFil
            Group.
          </label>
        </div>

        <button className="firstButton" type="submit">
          Wyślij
        </button>
      </form>
    </ThemeProvider>
  );
}

export default BriefForm;
