import { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#6f00d8",
      },
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface FormData {
  name: string;
  project: string;
  message: string;
  consent: boolean;
}

function TicketForm() {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    project: "",
    message: "",
    consent: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/ticket-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Błąd :( Napisz na hello@ifil.pl");
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-2 items-start justify-start w-full"
      >
        <TextField
          id="name"
          label="Twoje imię i nazwisko"
          type="text"
          variant="filled"
          value={formData.name}
          onChange={handleChange}
          className="w-full"
          sx={{
            "& .MuiFilledInput-root": {
              backgroundColor: "transparent",
            },
          }}
        />
        <TextField
          id="project"
          label="Nazwa / link Twojego projektu"
          type="text"
          variant="filled"
          value={formData.project}
          onChange={handleChange}
          className="w-full"
          sx={{
            "& .MuiFilledInput-root": {
              backgroundColor: "transparent",
            },
          }}
        />
        <TextField
          id="message"
          label="Treść zgłoszenia"
          type="text"
          variant="filled"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          className="w-full"
          sx={{
            "& .MuiFilledInput-root": {
              backgroundColor: "transparent",
            },
          }}
        />
        <div className="flex flex-row items-start justify-start gap-2">
          <input
            type="checkbox"
            id="consent"
            className="mt-1"
            checked={formData.consent}
            onChange={handleChange}
            required={true}
          />
          <label htmlFor="consent" className="text-xs opacity-80 mb-2">
            Wyrażam zgodę na przetwarzanie moich danych celem realizacji
            niniejszego zgłoszenia. Oświadczam, że zapoznałem/am się z
            Regulaminem Serwisu oraz Polityką Prywatności i akceptuję
            postanowienia nieniejszych dokumentów. Oświadczam, że jestem
            Klientem iFil Group.
          </label>
        </div>
        <button className="firstButton w-full" type="submit">
          Zgłoś
        </button>
      </form>
    </ThemeProvider>
  );
}

export default TicketForm;
