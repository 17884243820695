import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

interface FooterFormData {
  emailFooter: string;
  phoneFooter: string;
  subjectFooter: string;
  consentFooter: boolean;
}

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

const FooterForm: React.FC = () => {
  const navigate = useNavigate();
  const outerTheme = useTheme();
  const [formData, setFormData] = useState<FooterFormData>({
    emailFooter: "",
    phoneFooter: "",
    subjectFooter: "",
    consentFooter: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/footer-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Failed to submit the form");
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextField
          id="emailFooter"
          label="Twój adres e-mail"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.emailFooter}
          onChange={handleChange}
        />
        <TextField
          id="phoneFooter"
          label="Numer telefonu"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.phoneFooter}
          onChange={handleChange}
        />
        <TextField
          id="subjectFooter"
          label="Temat rozmowy"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.subjectFooter}
          onChange={handleChange}
        />
        <div className="flex flex-row items-start justify-start gap-2">
          <input
            type="checkbox"
            id="consentFooter"
            className="mt-1"
            checked={formData.consentFooter}
            onChange={handleChange}
            required={true}
          />
          <label
            htmlFor="consentFooter"
            className="text-xs opacity-50 mb-2 text-white"
          >
            Wyrażam zgodę na kontakt telefoniczny oraz mailowy celem realizacji
            niniejszego zapytania. Oświadczam, że zapoznałem/am się z
            Regulaminem Serwisu oraz Polityką Prywatności i akceptuję
            postanowienia nieniejszych dokumentów. Wyrażam zgodę na
            wykorzystanie adresu e-mail w celach marketingowych przez iFil
            Group.
          </label>
        </div>
        <button className="firstButton" type="submit">
          Wyślij
        </button>
      </form>
    </ThemeProvider>
  );
};

export default FooterForm;
