function Industry() {
  return (
    <section className="blackColorBg pt-12 lg:pt-24 px-6 lg:px-24 flex flex-col items-center justify-center gap-12 overflow-hidden relative">
      <h2 className="text-3xl lg:text-6xl whiteHeading text-center w-full lg:w-3/4">
        Wdrażamy funkcjonalne rozwiązania dla rozwoju{" "}
        <span className="colorHeading fontRegular">Twojego biznesu</span>
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 pb-6 lg:pb-0">
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-1.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">E-commerce</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Oferujemy kompleksowe rozwiązania e-commerce, które nie tylko
              przyciągają klientów, ale także zwiększają sprzedaż i lojalność.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-ecommerce.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-2.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Firmy usługowe</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Dostarczamy innowacyjne narzędzia dla sektora usługowego,
              ułatwiając efektywną komunikację i zwiększając wydajność w
              relacjach biznesowych.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-uslugi.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-3.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Marki osobiste</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Tworzymy indywidualne projekty, aby podkreślić unikalność Twojej
              marki i budować trwałe relacje z widownią.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-marki-osobiste.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-4.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Produkcja i przemysł</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Implementujem rozwiązania, które zoptymalizują procesy w firmie i
              podniosą wydajność w sektorze przemysłowym.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-przemysl-produkcja.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-5.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Finanse</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Dostarczamy nowoczesne aplikacje internetowe, aby zwiększyć
              efektywność operacyjną w sektorze finansowym.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-finanse.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-6.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Medycyna i fizjoterapia</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Oferujemy projekt oraz implementację stron internetowych z
              oprogramowaniem do rezerwacji wizyt.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-medycyna.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-7.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Prawo</h4>
            <p className="text-white w-full mt-2 opacity-60">
              Wprowadzamy nowoczesne rozwiązania, do prowadzenia wirtualnych
              kancelarii prawnych.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-prawo.webp"
            alt=""
          />
        </div>
        <div className="rounded-xl overflow-hidden relative industryBox">
          <div className="absolute w-full h-full z-20 industryOverlay bg-black transition duration-300 ease-in-out"></div>
          <div className="absolute w-full h-full z-30 p-6 lg:p-12 flex flex-col items-start justify-end">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/x-8.png"
              alt="Ikona"
              className="w-24 mb-2 hidden lg:flex absolute bottom-6 left-6 lg:top-12 lg:right-12"
            />
            <h4 className="text-white text-2xl">Agroturystyka i hotelarstwo</h4>
            <p className="text-white w-full mt-2 opacity-60">
              W atrakcyjny sposbów prezentujemy ofertę hoteli, apartamentów oraz
              region, w którym działa biznes.
            </p>
          </div>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/industry-agroturystyka.webp"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
export default Industry;
