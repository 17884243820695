import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import BackgroundSquare from "../common/squareBg";

import BASE_URL from "../../config";
import { Helmet } from "react-helmet-async";

interface Post {
  title: string;
  content: string;
  created_at: string;
  image_url: string;
  category_title: string;
  timing: string;
  meta_title: string;
  meta_description: string;
}

function Post() {
  const [post, setPost] = useState<Post | null>(null);
  const { categorySlug, postSlug } = useParams<{
    categorySlug: string;
    postSlug: string;
  }>();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/blog/post/${categorySlug}/${postSlug}/`)
      .then((res) => setPost(res.data))
      .catch((err) => console.log(err));
  }, [categorySlug, postSlug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{post.meta_title}</title>
        <meta name="title" content={post.meta_title} />
        <meta name="description" content={post.meta_description} key="desc" />
        <meta property="og:title" content={post.meta_title} />
        <meta property="og:description" content={post.meta_description} />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <section className="blackColorBg relative flex lg:min-h-screen">
        <div className="w-full h-full z-10 absolute">
          <div className="sticky top-0 w-full lg:h-screen">
            <BackgroundSquare />
          </div>
        </div>
        <div className="flex flex-col items-center justify-start z-50 py-12 lg:pt-36 w-full gap-2 backdrop-blur-xl">
          <div className="flex flex-col items-start justify-start gap-2 lg:mt-12 w-[90%] lg:w-[60%] xl:w-[50%]">
            <h1 className="text-3xl lg:text-6xl text-white">{post.title}</h1>
            <span className="w-full h-[2px] mainColorBg mt-6"></span>
            <div className="flex flex-row items-center justify-between w-full my-2">
              <a
                href={`/pomoc/baza-wiedzy/${categorySlug}`}
                className="text-white hover:underline hover:opacity-50 transition duration-300 ease-linear text-xl fontRegular"
              >
                {post.category_title}
              </a>
              <p className="text-white text-xl">{post.timing} min</p>
            </div>
            <span className="w-full h-[2px] mainColorBg mb-6"></span>
            <div className="flex flex-col items-center justify-center text-white gap-2 postHtml">
              <div
                className="w-[90%] flex flex-col gap-2"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
            <div className="mixedGradients p-6 rounded-xl w-full mt-6 flex flex-row items-center justify-between">
              <p className="fontRegular text-white text-2xl">
                Sprawdź więcej wpisów z kategorii{" "}
                <span className="fontRegular underline">
                  {post.category_title}
                </span>
              </p>
              <a
                href={`/pomoc/baza-wiedzy/${categorySlug}`}
                className="secondButton shrink-0 text-center"
              >
                zobacz więcej
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Post;
