import BackgroundSquare from "../common/squareBg";
import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface ErrorDetails {
  [key: string]: string[];
}

function Login() {
  const outerTheme = useTheme();
  const [errors, setErrors] = useState<ErrorDetails>({});
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/panel");
    }
  }, [navigate]);

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username");
    const password = formData.get("password");

    try {
      const response = await axios.post(`${BASE_URL}/api/accounts/login/`, {
        username,
        password,
      });
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          username: response.data.username,
          firstName: response.data.firstName,
          user_id: response.data.user_id,
        })
      );
      navigate("/panel");
    } catch (error) {
      console.error("Login failed:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Registration failed:", error.response.data);
        if (error.response.data.error === "Invalid credentials") {
          setErrors({
            username: ["Niepoprawna nazwa użytkownika lub hasło"], // Ustawienie błędu w zależności od odpowiedzi z serwera
            password: ["Niepoprawna nazwa użytkownika lub hasło"],
          });
        } else {
          setErrors({}); // Wyczyszczenie innych błędów
        }
      }
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <section className="blackColorBg flex flex-col items-center justify-start overflow-hidden py-24 lg:py-0 relative lg:h-screen">
        <div className="w-full h-full object-cover absolute inset-0">
          {" "}
          <BackgroundSquare />
        </div>
        <div className="flex flex-col items-center justify-center gap-4 z-20 w-full h-full">
          <form
            onSubmit={handleSubmit}
            action=""
            className="lightWhite p-12 rounded-xl firstShadowLight backdrop-blur-2xl flex flex-col gap-4"
          >
            <h2 className="text-white text-2xl">Zaloguj się</h2>{" "}
            <TextField
              id="standard-basic"
              label="Nazwa uzytkownika"
              variant="standard"
              className="w-72"
              color="secondary"
              name="username"
              error={!!errors.username}
              helperText={errors.username ? errors.username.join(" ") : ""}
            />
            <PsdField
              id="standard-basic"
              label="Hasło"
              variant="standard"
              className="w-72"
              color="secondary"
              type="password"
              name="password"
              error={!!errors.password}
              helperText={errors.password ? errors.password.join(" ") : ""}
            />
            <button className="firstButton mt-4">Zaloguj się</button>
            {/* <a
              href="/panel/rejestracja"
              className="text-white opacity-60 hover:opacity-40 transition duration-300 ease-linear text-center"
            >
              Nie mam konta
            </a> */}
          </form>
        </div>
      </section>
    </ThemeProvider>
  );
}
export default Login;
