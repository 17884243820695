import Slider from "react-slick";

import GlobeModel from "../additional/globe";
import GlobeModelMobile from "../additional/globeMobile";
function HowWeCanHelp() {
  var settings = {
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <section className="blackColorBg z-24">
      <div className="hidden lg:block relative py-6 overflow-hidden">
        <div className="w-full h-full logoSliderGradient absolute z-50"></div>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
        <Slider {...settings}>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-3.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-4.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-5.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-6.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-7.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-8.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-9.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-10.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-12.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-13.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-14.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-15.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-17.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-18.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-19.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-20.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-21.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-22.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
          <div className="container">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-23.png"
              alt="Project 23"
              className="px-12"
            />
          </div>
        </Slider>
      </div>
      <div className="flex flex-row items-center justify-between px-6 lg:px-24 pt-12">
        <h3 className="text-3xl lg:text-6xl whiteHeading">
          Jak możemy
          <br />
          pomóc Twojej <span className="colorHeading fontRegular">marce</span>?
        </h3>
      </div>
      <div className="flex flex-col px-6 lg:px-24">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 py-12">
          <a href="/strony-internetowe">
            <div className="overflow-hidden flex relative rounded-xl text-white grayLight offerCard">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-1.webp"
                className="z-10"
                alt=""
              />
              <span className="absolute bottom-0 w-full h-1 mixedGradients z-50"></span>
              <div className="overlay w-full h-full bg-black absolute z-20"></div>
              {/* <div className="overlayPurple w-full h-full absolute z-30"></div> */}
              <div className="absolute z-30 w-full h-full flex flex-col items-start justify-end">
                <div className="firstView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <h4 className="text-2xl mb-2">Strony internetowe</h4>
                  <p className="text-lg opacity-90 leading-tight">
                    Dowiedz się więcej
                  </p>
                </div>
                <div className="secondView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <p className="text-lg opacity-90 leading-tight">
                    Zapewniamy nie tylko wirtualną wizytówkę dla Twojej firmy,
                    ale również narzędzie do generowania zysków. Nasze projekty
                    są nie tylko atrakcyjne, ale nastawione na rozwój biznesu.
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a href="/sklepy-internetowe">
            {" "}
            <div className="overflow-hidden flex relative rounded-xl text-white grayLight offerCard">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-6.webp"
                className="z-10"
                alt=""
              />
              <span className="absolute bottom-0 w-full h-1 mixedGradients z-50"></span>
              <div className="overlay w-full h-full bg-black absolute z-20"></div>
              {/* <div className="overlayPurple w-full h-full absolute z-30"></div> */}
              <div className="absolute z-30 w-full h-full flex flex-col items-start justify-end">
                <div className="firstView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <h4 className="text-2xl mb-2">Sklepy on-line</h4>
                  <p className="text-lg opacity-90 leading-tight">
                    Dowiedz się więcej
                  </p>
                </div>
                <div className="secondView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <p className="text-lg opacity-90 leading-tight">
                    Twój sklep to nie tylko miejsce transakcji, to platforma,
                    która buduje doświadczenie zakupowe i wzmacnia lojalność
                    klientów. Tworzymy sklepy, których proces zakupu jest
                    intuicyjny.
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a href="/aplikacje">
            <div className="overflow-hidden flex relative rounded-xl text-white grayLight offerCard">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-2.webp"
                className="z-10"
                alt=""
              />
              <span className="absolute bottom-0 w-full h-1 mixedGradients z-50"></span>
              <div className="overlay w-full h-full bg-black absolute z-20"></div>
              {/* <div className="overlayPurple w-full h-full absolute z-30"></div> */}
              <div className="absolute z-30 w-full h-full flex flex-col items-start justify-end">
                <div className="firstView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <h4 className="text-2xl mb-2">Aplikacje i systemy CRM</h4>
                  <p className="text-lg opacity-90 leading-tight">
                    Dowiedz się więcej
                  </p>
                </div>
                <div className="secondView absolute p-6 w-full h-full flex flex-col items-start justify-end">
                  <p className="text-lg opacity-90 leading-tight">
                    Skupiamy się na projektowaniu rozwiązań, które są nie tylko
                    efektywne, ale także intuicyjne w obsłudze, dostarczając
                    funkcjonalności spełniające potrzeby Twojego biznesu.
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-end gap-6">
          <p className="text-white text-xl">Sprawdź nasze wszystkie usługi</p>
          <a href="/oferta">
            <button className="firstButton">Zobacz</button>
          </a>
        </div>
      </div>
      <div className="pt-12 lg:px-0 lg:pt-36 flex flex-col items-center justify-center relative">
        <div className="flex flex-col items-center justify-center gap-6 px-6">
          <h2 className="text-3xl lg:text-6xl whiteHeading text-center">
            Jeden projekt. Wiele możliwości.
          </h2>
          <p className="text-white text-xl lg:text-3xl opacity-60 my-4 w-full lg:w-1/2 text-center">
            Projekt może zostać zrealizowany w wielu technologiach, które
            wpływają na wydajność, funkcjonalności, a także bezpieczeństwo i
            koszty.
          </p>
          <a href="/bezplatna-konsultacja">
            <button className="secondButton flex flex-row items-center justify-center gap-1">
              <span>Wspólnie wybierzmy najlepsze rozwiązanie</span>
              <svg
                width={25}
                height={25}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                    stroke="#6f00d8"
                    stroke-width="1.2"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </a>
        </div>
        <div className="mainLight w-full h-64 translate-y-64 absolute bottom-0"></div>
        <div className="hidden lg:flex w-full flex-col items-center justify-center">
          <GlobeModel />
        </div>
        <div className="overflow-x-hidden w-full flex lg:hidden">
          <GlobeModelMobile />
        </div>
      </div>
    </section>
  );
}
export default HowWeCanHelp;
