import { CiCircleCheck } from "react-icons/ci";
import BackgroundSquare from "../common/squareBg";
import LandingFormPayNow from "../forms/landingFormPayNow";

function PayNow() {
  return (
    <section className="blackColorBg lg:min-h-screen flex flex-col items-center justify-center gap-12 overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0 z-10">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 z-20 relative p-6 lg:p-24 w-full h-full max-w-[1400px] mx-auto">
        <div className="flex flex-col items-start justify-center gap-2">
          <h1 className="mainColor fontLight text-sm lg:text-xl">
            Bramka płatnicza PayNow
          </h1>
          <h2 className="text-3xl lg:text-7xl mb-6">
            <span className="whiteHeading fontRegular">Rozwijaj sklep,</span>{" "}
            <span className="colorHeading fontRegular">obniżaj koszty</span>
          </h2>

          <p className="text-white text-xl lg:text-2xl opacity-60 w-full max-w-[500px]">
            Skorzystaj z dedykowanej dla Klientów iFil Group oferty bramki
            płatniczej PayNow już dziś!
          </p>
          <div className="mt-4 flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              Prowizja <span className="fontSemiBold">tylko 0.75%</span>
            </p>
          </div>
          <div className="flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              60 dni <span className="fontSemiBold">za 0%</span>
            </p>
          </div>
          <div className="flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              <span className="fontSemiBold">Brak opłat</span> za rejestrację
            </p>
          </div>
          <div className="flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              Umowa na <span className="fontSemiBold">czas nieokreślony</span>
            </p>{" "}
          </div>
          <div className="flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              Pieniądze <span className="fontSemiBold">od razu na koncie</span>
            </p>{" "}
          </div>
          <div className="mb-4 flex flex-row items-center justify-start gap-2 text-xl text-white">
            <CiCircleCheck className="mainColor" />
            <p className="opacity-60">
              <span className="fontSemiBold">Bezpłatne wdrożenie</span> przez
              iFil Group
            </p>{" "}
          </div>
          <a href="mailto:hello@ifil.pl">
            <button className="secondButton flex flex-row items-center justify-center gap-1">
              <span>
                Masz pytanie?{" "}
                <span className="fontSemiBold">hello@ifil.pl</span>
              </span>
            </button>
          </a>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="w-full blackColorBg p-12 firstShadowLight rounded-xl fancy relative flex flex-col items-center justify-center">
            <div className="w-full lg:w-5/6 z-10">
              <h2 className="text-2xl lg:text-4xl text-white mb-6">
                Wypełnij{" "}
                <span className="colorHeading fontRegular">formularz</span>
              </h2>
              <LandingFormPayNow />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default PayNow;
