import React, { useState, useEffect } from "react";
import axios from "axios";
import BackgroundSquare from "../common/squareBg";
import BASE_URL from "../../config";

interface Post {
  title: string;
  slug: string;
  created_at: string;
  image_url: string;
  timing: string;
  category_title: string;
  category_slug: string;
}

interface Subcategory {
  title: string;
  slug: string;
}

interface Category {
  title: string;
  slug: string;
  description: string;
  posts: Post[];
  subcategories: Subcategory[];
}

function Blog() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [latestPost, setLatestPost] = useState<Post | null>(null);
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/blog/categories/`)
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`${BASE_URL}/api/blog/latest-post/`)
      .then((res) => setLatestPost(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`${BASE_URL}/api/blog/featured-posts/`)
      .then((res) => setFeaturedPosts(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <section className="blackColorBg relative flex py-12 w-full">
        <div className="flex flex-col z-50 pt-6 lg:pt-12 gap-2 px-6 lg:px-36 mx-auto">
          <div className="flex flex-col items-center justify-center">
            <p className="text-white px-6 rounded-xl py-1 mixedGradients">
              Edukacja
            </p>
            <h2 className="text-3xl lg:text-6xl w-full text-center">
              <span className="whiteHeading fontRegular">Dzielimy się</span>{" "}
              <span className="colorHeading fontRegular">wiedzą</span>
            </h2>
          </div>
          <div className="flex flex-col lg:grid grid-cols-5 gap-12 mt-6 lg:mt-12">
            <div className="lg:col-span-3">
              {/* Najnowszy wpis */}
              {latestPost && (
                <a
                  href={`/pomoc/baza-wiedzy/${latestPost.category_slug}/${latestPost.slug}`}
                  className="cursor-pointer rounded-xl flex flex-col overflow-hidden relative hover:opacity-70 transition duration-300 ease-linear group"
                >
                  <img src={latestPost.image_url} alt={latestPost.title} />
                  <div className="top-12 left-0 mixedGradients rounded-r-xl px-6 py-2 text-white text-center absolute">
                    Najnowszy wpis
                  </div>
                  <div className="mixedGradients p-6 flex flex-col gap-2">
                    <h2 className="text-2xl text-white group-hover:underline">
                      {latestPost.title}
                    </h2>
                    <p className="text-white text-base opacity-80">
                      {latestPost.timing} min | {latestPost.category_title}
                    </p>
                  </div>
                </a>
              )}
            </div>
            <div className="lg:col-span-2 flex flex-col items-start justify-between">
              <div className="w-full">
                <p className="text-2xl font-bold text-white">Polecane wpisy</p>
                <span className="my-2 h-[5px] w-full mixedGradients block"></span>
              </div>
              {/* Polecane wpisy */}
              {featuredPosts.map((post) => (
                <a
                  key={post.slug}
                  href={`/pomoc/baza-wiedzy/${post.category_slug}/${post.slug}`}
                  className="flex flex-col gap-1 border-b border-b-white border-opacity-20 py-2 group cursor-pointer w-full"
                >
                  <h3 className="text-lg text-white group-hover:underline group-hover:opacity-50 transition duration-300 ease-linear">
                    {post.title}
                  </h3>
                  <p className="text-white opacity-50">{post.category_title}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
