import React, { useState, useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";

interface HeroProps {
  title: string;
}

function Hero({ title }: { title: string }) {
  const offer = ["aplikacje", "strony", "sklepy", "interfejsy", "systemy CRM"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % offer.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.2], [0.95, 1.9]);

  return (
    <section className="blackColorBg lg:min-h-screen flex flex-col items-center justify-end gap-12 overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center px-6 lg:px-0 pt-12 lg:pt-36 pb-6 gap-4 z-20">
        <img
          className="flex lg:hidden w-1/2"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
          alt=""
        />
        <h1 className="mainColor fontLight text-sm lg:text-xl text-center">
          {title}
        </h1>
        <h2 className="text-3xl lg:text-8xl whiteHeading text-center">
          Tworzymy{" "}
          <motion.span
            key={currentIndex}
            className="fontRegular colorHeading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {offer[currentIndex]}
          </motion.span>
          ,<br />
          które rozwiną Twój biznes
        </h2>
        <span className='block h-1 w-24 bg-white mt-2'></span>
        <h2 className='text-center text-xl lg:text-3xl whiteHeading'>{title}</h2>
        <p className="text-white text-xl lg:text-3xl opacity-60 my-2 w-full lg:w-2/3 text-center">
          Specjalizujemy się w tworzeniu produktów informatycznych, w pełni
          dopasowanych do potrzeb biznesu.
        </p>
        <h3 className='text-center text-lg lg:text-xl text-white opacity-0 mb-2'>{title}</h3>
        <a href="/bezplatna-konsultacja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span>Porozmawiajmy o Twoich potrzebach</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                  stroke="#6f00d8"
                  stroke-width="1.2"
                  stroke-linecap="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <motion.img
        src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/ifil-ui.webp"
        alt=""
        className="rounded-t-3xl w-3/4 firstShadow z-10"
        style={{ scale }}
      />
    </section>
  );
}
export default Hero;
