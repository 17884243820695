import Slider from "react-slick";

function CustomerCarousel() {
  var settings = {
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <div className="hidden lg:block relative py-6 overflow-hidden">
      <div className="w-full h-full logoSliderGradient absolute z-50"></div>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
      />
      <script src="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
      <Slider {...settings}>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-3.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-4.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-5.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-6.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-7.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-8.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-9.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-10.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-12.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-13.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-14.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-15.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-17.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-18.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-19.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-20.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-21.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-22.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
        <div className="container">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-23.png"
            alt="Project 23"
            className="px-12"
          />
        </div>
      </Slider>
    </div>
  );
}
export default CustomerCarousel;
