import { Helmet } from "react-helmet-async";
import FooterShort from "../components/common/footerShort";
import Header from "../components/common/header";
import Content from "../components/partner/content";
function Partner() {
  return (
    <>
      <Helmet>
        <title>Program Partnerski | iFil Group</title>
        <meta name="title" content="Program Partnerski | iFil Group" />
        <meta
          name="description"
          content="Polecaj usługi tworzenia stron internetowych, sklepów online oraz aplikacji i zapewnij sobie dodatkowy dochód."
          key="desc"
        />
        <meta property="og:title" content="Program Partnerski | iFil Group" />
        <meta
          property="og:description"
          content="Polecaj usługi tworzenia stron internetowych, sklepów online oraz aplikacji i zapewnij sobie dodatkowy dochód."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Content />
      </main>
      <FooterShort />
    </>
  );
}
export default Partner;
