function Content() {
  return (
    <section className="blackColorBg px-6 lg:px-24 lg:pt-12">
      <div className="flex flex-col items-center justify-center lg:px-24 pt-12">
        <h3 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">
            Przekształć oczekiwania
          </span>{" "}
          <br />
          <span className="whiteHeading fontRegular">Klientów w</span>{" "}
          <span className="colorHeading fontRegular">rzeczywistość</span>
        </h3>

        <p className="text-white text-xl lg:text-3xl opacity-60 my-4 w-full lg:w-2/3 text-center">
          Nowoczesne interfejsy dostosowane do potrzeb Twoich Klientów.
        </p>
      </div>
      <div className="flex flex-col lg:grid grid-cols-3 gap-4 w-full mt-12">
        <div className="col-span-1 rounded-xl overflow-hidden text-white mixedGradients relative">
          <div className="px-12 pt-12 pb-6">
            <p className="text-white fontRegular text-2xl mb-2">
              Responsywność
            </p>
            <p>
              Zaprojektujmy razem interfejsy, które wzbogacają życie
              użytkowników i budują trwałe relacje z klientami.
            </p>
          </div>
          <img
            className="lg:absolute bottom-0"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/iphone-mockup.png"
            alt=""
          />
        </div>
        <div className="col-span-2 lightWhite p-12 flex flex-col items-center rounded-xl text-white overflow-hidden gap-12">
          <div className="flex flex-col items-start justify-start w-full">
            <p className="mainColor fontRegular text-2xl mb-2">Przyjazne SEO</p>
            <p>
              Zdobądź widoczność online dzięki interfejsowi dostosowanemu do
              wymagań wyszukiwarek, podnosząc pozycję Twojej strony w wynikach
              wyszukiwania.
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-12">
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>Dobre praktyki</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow scale-125">
              <p className="fontRegular text-4xl">100</p>
              <p>SEO</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>Dostępność</p>
            </div>
          </div>
        </div>
        <div className="col-span-2 lightWhite rounded-xl text-white flex flex-col lg:flex-row items-center justify-between relative overflow-hidden">
          <div className="flex flex-col w-full px-12 py-6">
            <p className="mainColor fontRegular text-2xl mb-2">Nawigacja</p>
            <p>
              Zapewnimy intuicyjną nawigację, umożliwiającą użytkownikom łatwe
              odnalezienie informacji i płynne poruszanie się po stronie.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center h-full w-full">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Application/aplikacje-api-n.png"
              alt="API"
              className="firstShadowDrop lg:rotate-90"
            />
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white p-12 overflow-hidden">
          <div className="flex flex-col w-full mb-6">
            <p className="mainColor fontRegular text-2xl mb-2">
              Nacisk na konwersję
            </p>
            <p>
              Oprócz atrakcyjnego interfejsu skupiamy się na zachęceniu
              potencjalnych Klientów do skorzystania z Twojej oferty.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center lightWhite rounded-full w-full pt-1 pb-2 firstShadow text-xl">
              <span className="text-white">Bezpłatna konsultacja</span>
            </p>
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white relative overflow-hidden flex flex-col gap-6">
          <div className="flex flex-col w-full pt-12 px-12">
            <p className="text-white fontRegular text-2xl mb-2 mainColor">
              Spójność
            </p>
            <p>
              Wizualna spójność w każdym detalu - od kolorów po układ elementów,
              tworząc harmonijne i profesjonalne wrażenie na każdej stronie.
            </p>
          </div>
          <div className="flex flex-col items-end justify-end">
            <img
              className="firstShadow rounded-tl-xl w-3/4 lg:w-2/3"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/spojnosc-ui.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-2 mixedGradients flex flex-col lg:flex-row gap-12 items-center justify-between rounded-xl text-white overflow-hidden relative mb-12 lg:mb-0">
          <div className="flex flex-col p-12 w-full lg:w-2/5">
            <p className="text-white fontRegular text-2xl mb-2">
              Kompleksowość
            </p>
            <p>
              Nasze podejście to kompleksowa optymalizacja interfejsu, łącząca
              estetykę z funkcjonalnością, zapewniająca pełne doświadczenie
              użytkownika.
            </p>
          </div>
          <img
            className="lg:absolute hidden lg:flex right-12"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/kompleksowosc.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
export default Content;
