import React, { useEffect, useRef } from "react";
import createGlobe, { COBEOptions } from "cobe";

interface GlobeState {
  phi: number;
}

export default function GlobeModelMobile() {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current!, {
      devicePixelRatio: 2,
      width: 1200 * 2,
      height: 1200 * 2,
      phi: 0,
      opacity: 0,
      theta: -3,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 5,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [0.4, 0, 0.85],
      markers: [],
      onRender: ((state: GlobeState) => {
        state.phi = phi;
        phi += 0.005;
      }) as COBEOptions["onRender"],
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <div>
      <canvas
        className="bg-transparent"
        ref={canvasRef}
        style={{
          width: 800,
          height: 800,
          maxHeight: "50vh",
          rotate: "180deg",
          aspectRatio: 1,
          background: "transparent",
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
      />
    </div>
  );
}
