import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Post from "../components/support/blogPost";

function SupportBlogPost() {
  return (
    <>
      <Header />
      <main className="relative z-40">
        <Post />
      </main>
      <Footer />
    </>
  );
}
export default SupportBlogPost;
