import React, { useState, useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";
import { PinContainer } from "../additional/3d-pin";

function Hero() {
  const offer = ["aplikacje", "strony", "sklepy", "interfejsy", "systemy CRM"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % offer.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.2], [0.95, 1.9]);

  return (
    <section className="blackColorBg flex flex-col items-center justify-start overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center pt-12 lg:pt-36 z-20">
        <h1 className="mainColor fontLight text-sm lg:text-xl">
          iFil Group - Oferta
        </h1>
        <h2 className="text-3xl lg:text-7xl whiteHeading text-center">
          Rozwiązujemy problemy
          <br />
          korzystając z{" "}
          <span className="colorHeading fontRegular">technologii</span>
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 mb-12 w-3/4 text-center">
          Kompleksowo wdrażamy wysokiej jakości oprogramowanie oraz strony
          internetowe. Naszym priorytetem jest dostarczenie rozwiązań, które
          rozwiną Twój biznes.
        </p>
        <a href="/bezplatna-konsultacja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span>Porozmawiajmy o Twoich potrzebach</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                  stroke="#6f00d8"
                  stroke-width="1.2"
                  stroke-linecap="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 w-full z-50 px-24 gap-24 py-24">
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/strony-internetowe">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Strony internetowe
                </h3>
                <p>
                  Tworzymy nowoczesne i responsywne strony internetowe,
                  dostosowane do potrzeb i oczekiwań klientów.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-webdesign.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/sklepy-internetowe">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Sklepy on-line
                </h3>
                <p>
                  Projektujemy i wdrażamy funkcjonalne i bezpieczne sklepy
                  on-line, zintegrowane z systemami płatności i logistyki.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-ecommerce.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/aplikacje">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Aplikacje internetowe
                </h3>
                <p>
                  Opracowujemy aplikacje na różne platformy i urządzenia,
                  wykorzystując najnowsze technologie i narzędzia.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-aplikacje.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/systemy-crm">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Systemy CRM
                </h3>
                <p>
                  Implementujemy systemy CRM, które pomagają zarządzać relacjami
                  z klientami, poprawiając komunikację, sprzedaż i lojalność.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-crm.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 w-full z-50 px-24 gap-24 pb-24">
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/projektowanie-interfejsow">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Interfejsy graficzne
                </h3>
                <p>
                  Zajmujemy się projektowaniem interfejsów użytkownika, dbając o
                  estetykę, ergonomię i użyteczność.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-ui.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/integracje-ai">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Integracje AI
                </h3>
                <p>
                  Wprowadzamy sztuczną inteligencję do twojego biznesu,
                  zwiększając wydajność Twojego biznesu.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-ai.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/minimum-viable-product">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  MVP
                </h3>
                <p>
                  Wdrażamy minimalną wersję produktu, która pozwoli ci
                  zweryfikować potencjalny sukces projektu.
                </p>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-mvp.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[20rem] w-full flex items-center justify-center ">
          <a href="/odswiezenie-wizerunku">
            <PinContainer title="zobacz więcej">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Odświeżenie wizerunku
                </h3>
                <p>
                  Odświeżymy Twoją identyfikację wizualną oraz interfejsy
                  wszystkich Twoich produktów, aby Twoja firma nie stała za
                  konkurencją.
                </p>{" "}
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Offer/offer-refresh.png"
                  alt=""
                  className="w-24 mt-6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
      </div>
    </section>
  );
}
export default Hero;
