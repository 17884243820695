import { useState } from "react";
import ReactDOM from "react-dom";

export function PricingProcessModal() {
  const [isOpen, setIsOpen] = useState(false);

  const modalContent = isOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"
        onClick={() => setIsOpen(false)}
      ></div>
      <div className="relative bg-black text-white p-6 rounded-lg shadow-lg transform perspective-800 animate-show-modal w-[95%] lg:w-1/3 h-[80%] lg:h-1/2">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-2 right-2 text-xl"
        >
          X
        </button>
        <span className="mainColorBg w-12 h-1 mt-6 block"></span>
        <h2 className="text-white text-3xl mt-2 mb-6">
          Co wydarzy się <span className="mainColor fontRegular">dalej</span>
        </h2>{" "}
        <p>
          Po uzupełnieniu formularza, jeszcze tego samego dnia roboczego
          otrzymasz od nas ofertę na maila. Podczas wysyłki oferty poprosimy
          Państwa również o potwierdzenie jej otrzymania.
          <br />
        </p>
      </div>
    </div>
  );

  return (
    <>
      <button type="button" onClick={() => setIsOpen(true)} className="">
        <p className="mb-12 text-white opacity-70 border-b border-white pb-[1px] cursor-pointer animate-pulse">
          Co się stanie po wypełnieniu formularza?
        </p>
      </button>
      {ReactDOM.createPortal(modalContent, document.body)}
    </>
  );
}
