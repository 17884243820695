import BackgroundSquare from "../common/squareBg";
import { MdOutlineSupportAgent } from "react-icons/md";
import { TbHelpHexagonFilled } from "react-icons/tb";
import { FaRegHandshake } from "react-icons/fa";
import HeroSearch from "./heroSearch";

function Hero() {
  return (
    <div className="overflow-x-hidden">
      <div className="absolute z-10 overflow-hidden h-full">
        <div className="w-[99vw] h-screen object-cover inset-0 overflow-hidden relative">
          <BackgroundSquare />
        </div>
      </div>
      <section className="flex flex-col items-center justify-start gap-12 relative z-[9999999] p-6 lg:p-0">
        <div className="lg:min-h-[60vh] flex flex-col items-center justify-start py-12 lg:pt-36 lg:pb-28 gap-4 z-20">
          <h1 className="mainColor fontLight text-center text-sm lg:text-xl">
            Bezpłatne poradniki i wsparcie
          </h1>
          <h2 className="text-3xl lg:text-7xl text-center mb-6">
            <span className="whiteHeading fontRegular">Jak możemy Ci</span>{" "}
            <span className="colorHeading fontRegular">pomóc</span>
          </h2>
          <HeroSearch />
          <p className="text-white text-base opacity-60 text-center">
            Wpisz powyżej, czego szukasz
            <br />i znajdź odpowiedź na swoje pytanie w naszej bazie wiedzy
          </p>
        </div>
      </section>
      <section className="mixedGradients px-6 py-6 lg:py-0 lg:px-24 relative z-40">
        <div className="flex flex-col lg:flex-row items-start justify-center gap-6 lg:xgap-12 lg:-translate-y-12">
          <a
            href="/pomoc/wsparcie"
            className="hover:-translate-y-2 transition duration-300 ease-linear w-full lg:w-auto"
          >
            <div className="px-6 lg:px-12 py-6 bg-white rounded-xl w-full lg:min-w-[400px] flex flex-col items-start justify-start gap-4">
              <div className="flex flex-row items-center justify-start gap-6">
                <MdOutlineSupportAgent className="text-6xl mainColor" />
                <div className="flex flex-col items-start justify-center gap-1">
                  <h3 className="text-2xl mainColor">Wsparcie techniczne</h3>
                  <div className="flex flex-row items-center justify-center gap-2 opacity-80">
                    <span>system zgłoszeń dla Klientów</span>
                  </div>
                </div>
              </div>
              <button className="firstButton w-full">Utwórz zgłoszenie</button>
            </div>
          </a>
          <a
            href="/pomoc/baza-wiedzy"
            className="hover:-translate-y-2 transition duration-300 ease-linear w-full lg:w-auto"
          >
            <div className="px-6 lg:px-12 py-6 bg-white rounded-xl w-full lg:min-w-[400px] flex flex-col items-start justify-start gap-4">
              <div className="flex flex-row items-center justify-start gap-6">
                <TbHelpHexagonFilled className="text-6xl mainColor" />
                <div className="flex flex-col items-start justify-center gap-1">
                  <h3 className="text-2xl mainColor">Baza wiedzy</h3>
                  <div className="flex flex-row items-center justify-center gap-2 opacity-80">
                    <span>blog</span>
                    <span className="bg-gray-900 rounded-full block w-[3px] h-[3px]"></span>
                    <span>poradniki</span>
                  </div>
                </div>
              </div>
              <button className="firstButton w-full">
                Zobacz wszystkie wpisy
              </button>
            </div>
          </a>
          <a
            href="/wycena"
            className="hover:-translate-y-2 transition duration-300 ease-linear w-full lg:w-auto"
          >
            <div className="px-6 lg:px-12 py-6 bg-white rounded-xl w-full lg:min-w-[400px] flex flex-col items-start justify-start gap-4">
              <div className="flex flex-row items-center justify-start gap-6">
                <FaRegHandshake className="text-6xl mainColor" />
                <div className="flex flex-col items-start justify-center gap-1">
                  <h3 className="text-2xl mainColor">Bezpłatna wycena</h3>
                  <div className="flex flex-row items-center justify-center gap-2 opacity-80">
                    <span>formularz wstępnej wyceny</span>
                  </div>
                </div>
              </div>
              <button className="firstButton w-full">Uzyskaj ofertę</button>
            </div>
          </a>
        </div>
      </section>
    </div>
  );
}
export default Hero;
