import { useState, useEffect, useRef } from "react";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";

function LetsOffer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsPlaying(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePlayPause = () => {
    if (isMobile) {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    } else {
      openModal();
    }
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-12 text-white p-6 lg:px-24 lg:mt-12">
        <div className="flex-col flex text-xl w-full items-start justify-start">
          <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
            O nas
          </p>
          <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
            Bezpłatna aplikacja do ofertowania
          </p>
          <span className="mainColorBg w-24 h-1 mb-6"></span>
          <p className="w-full opacity-60 mb-2">
            W ramach każdej realizacji otrzymasz bezpłatny dostęp do naszej
            autorskiej aplikacji do ofertowania on-line. Aplikacja Let's Offer
            pozwala na wysyłanie spersonalizowanych ofert handlowych spójnych z
            identyfikacją wizualną oraz stroną internetową Twojej firmy.
          </p>
          <p className="w-full opacity-60 mb-6">
            Wybierz szablon, wprowadź parametry m.in cenę oraz czas realizacji.
            Wygeneruj link do oferty, która będzie spójna z Twoją identyfikacją
            wizualną. Prześlij ją bezpośrednio do Klienta oraz kontroluj
            wszystkie oferty w jednym miejscu.
          </p>
          <a href="https://letsoffer.pl">
            <button className="firstButton">Dowiedz się więcej</button>
          </a>
        </div>
        <div className="w-full firstShadow mt-12 lg:mt-0">
          <div className="group w-full flex flex-col items-center justify-center relative">
            <video
              ref={videoRef}
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/instuction-video.mov"
              muted
              className="rounded-xl block lg:group-hover:shadow-2xl lg:group-hover:scale-105 transition duration-300 ease-linear"
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onClick={handlePlayPause}
            ></video>
            {!isPlaying && (
              <div
                className="absolute inset-0 flex items-center justify-center rounded-xl cursor-pointer"
                onClick={handlePlayPause}
              >
                <AiOutlinePlayCircle className="mainColor text-6xl group-hover:opacity-60 transition duration-300 ease-linear " />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal tylko na PC */}
      {!isMobile && isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999999999999999999999999999999999]">
          <button
            onClick={closeModal}
            className="absolute top-12 right-12 bg-main text-white text-4xl p-2 rounded-full"
          >
            <AiOutlineClose />
          </button>
          <div className="relative bg-white lg:h-3/4 max-h-full">
            <video
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/instuction-video.mov"
              controls
              autoPlay
              className="w-full h-full"
            ></video>
          </div>
        </div>
      )}
    </>
  );
}

export default LetsOffer;
