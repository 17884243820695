import React, { useState, useEffect } from "react";
import {
  createTheme,
  TextField,
  Theme,
  ThemeProvider,
  useTheme,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import BASE_URL from "../../config";
import { Link } from "react-router-dom";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        contrastText: "rgba(1,1,1,0.8)",
      },
      secondary: {
        main: "#6f00d8",
      },
      text: {
        primary: "rgba(1,1,1,0.8)",
        secondary: "rgba(1,1,1,0.8)",
      },
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface Post {
  title: string;
  slug: string;
  category_slug: string;
}

function HeroSearch() {
  const outerTheme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/blog/search/`, { params: { q: searchQuery } })
        .then((res) => {
          setPosts(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setPosts([]);
    }
  }, [searchQuery]);

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div className="relative w-full lg:w-4/5 flex flex-col items-center justify-center z-50">
        <div className="relative w-full flex flex-row items-center justify-start bg-white rounded-t-xl overflow-hidden">
          <div className="w-14 h-14 flex flex-col items-center justify-center mixedGradients cursor-pointer hover:opacity-60 transition duration-300 ease-linear">
            <CiSearch className="text-white text-2xl" />
          </div>
          <TextField
            id="filled-search"
            label="Szukaj"
            type="search"
            variant="filled"
            fullWidth
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {loading && (
            <CircularProgress className="absolute right-4 text-white" />
          )}
        </div>

        {posts.length > 0 && (
          <div className="shadow-2xl absolute top-full left-0 w-full bg-white rounded-b-xl overflow-hidden z-[999999999999]">
            <div className="z-50 max-h-[350px] overflow-y-auto">
              {posts.map((post) => (
                <Link
                  key={post.slug}
                  to={`/pomoc/baza-wiedzy/${post.category_slug}/${post.slug}`}
                  className="block py-2 px-2 hover:bg-gray-100 transition-colors rounded"
                >
                  {post.title}
                </Link>
              ))}
            </div>
            <Link
              to="/pomoc/baza-wiedzy"
              className="block text-white mixedGradients hover:underline w-full text-center p-2"
            >
              Zobacz wszystkie wpisy
            </Link>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default HeroSearch;
