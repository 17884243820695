function Support() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-12 lg:mt-24">
      <div className="flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Wsparcie po zakończeniu realizacji
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 mb-2">
          Strony, sklepy oraz aplikacje internetowe od strony technicznej
          posiadają złożoną strukturę. W związku z tym warto upewnić się, że w
          razie potencjalnej awarii, cyberataku bądź chęci rozbudowy projektu
          uzyskasz kompleksowe wsparcie od potencjalnego wykonawcy.
        </p>
        <p className="w-full opacity-60 mb-2">
          W iFil Group zapewniamy wsparcie za pomocą maila, panelu Klienta oraz
          czatu na żywo przez cały tydzień. Nasza infolinia dostępna jest od
          poniedziałku do piątku.
        </p>
        <p className="w-full opacity-60 mb-2">
          Dodatkowo, dzięki współpracy z jednym z polskich liderów w branży
          serwerów oraz domen możemy natychmiastowo reagować na wszelkie
          problemy i awarie.
        </p>
      </div>
      <div className="w-full p-6 lg:p-0 overflow-hidden">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/mockup3-support5.png"
          alt=""
          className="rounded-l-xl w-[95%]"
        />
      </div>
    </div>
  );
}

export default Support;
