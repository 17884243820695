import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

function Technology() {
  return (
    <>
      <div
        id="na-co-zwrocic-uwage"
        className="flex flex-col lg:flex-row items-center justify-between gap-6 lg:gap-6 text-white lg:mt-24"
      >
        <div className="gap-2 flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
          <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
            Na co zwrócić uwagę
          </p>
          <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
            Technologia
          </p>
          <span className="mainColorBg w-24 h-1 mb-6"></span>
          <p className="w-full opacity-60 ">
            Decydując się na utworzenie projektu informatycznego mamy do wyboru
            wiele technologi. Wybór odpowiedniej technologii wpływa bezpośrednio
            nie tylko na cenę, ale także pozycjonowanie, wydajność oraz wysokość
            sprzedaży.
          </p>
          <p className="w-full opacity-60 ">
            Jeżeli nie wiesz co wybrać skorzystaj z naszego porównania na
            następnej stronie bądź umów się z nami na bezpłatną konsultację!
          </p>
        </div>
        <div className="w-full p-6 lg:p-0 flex flex-col items-center justify-center">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/4technology.png"
            alt=""
            className="rounded-l-xl w-full lg:w-2/3"
          />
        </div>
      </div>
      <div className="flex flex-col px-6 lg:px-24 w-full items-start lg:items-center justify-start lg:justify-center mt-6 lg:mt-24">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę | Technologia
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold lg:mb-4 mt-4">
          Warstwy projektu
        </p>
        <span className="mainColorBg w-24 h-1 mt-4 mb-6 block"></span>
        <p className="w-full opacity-60 text-white text-xl text-left lg:text-center">
          Projekt informatyczny składa się z dwóch podstawowych warstw. Pierwsza
          to interfejs graficzny, wszystko to co widzi użytkownik. Druga warstwa
          system zarządzania treścią, jest to cała logika strony, sklepu czy też
          aplikacji, jest to niewidoczna część dla zwykłego użytkownika.
        </p>
        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-center gap-6 lg:gap-12 mt-6 lg:mt-12">
          <div className="mixedGradients rounded-xl w-full p-6 flex flex-col items-center justify-start">
            <p className="font-bold text-2xl text-center text-white mb-6">
              Interfejs graficzny
            </p>
            <div className="flex flex-row items-center justify-center flex-wrap gap-6">
              <p className="text-white text-center opacity-60 text-xl">
                Teksty
              </p>
              <p className="text-white text-center opacity-60 text-xl">
                Formularze
              </p>
              <p className="text-white text-center opacity-60 text-xl">Opisy</p>
              <p className="text-white text-center opacity-60 text-xl">
                Grafiki
              </p>
              <p className="text-white text-center opacity-60 text-xl">
                Nagłówek
              </p>
              <p className="text-white text-center opacity-60 text-xl">
                Tabele
              </p>
              <p className="text-white text-center opacity-60 text-xl">Filmy</p>
              <p className="text-white text-center opacity-60 text-xl">
                Zdjęcia
              </p>
            </div>
          </div>
          <div className="rounded-xl w-full relative overflow-hidden border broder-white">
            <div className="w-full h-full bg-white opacity-20 backdrop-blur-2xl absolute"></div>
            <div className="w-full h-full p-6 flex flex-col items-center justify-start">
              <p className="font-bold text-2xl text-center text-white mb-6">
                System zarządzania treścią
              </p>
              <div className="flex flex-row items-center justify-center flex-wrap gap-6">
                <p className="text-white text-center opacity-60 text-xl">
                  Panel admina
                </p>
                <p className="text-white text-center opacity-60 text-xl">
                  Algorytmy
                </p>
                <p className="text-white text-center opacity-60 text-xl">
                  System sklepu
                </p>
                <p className="text-white text-center opacity-60 text-xl">
                  Baza danych
                </p>
                <p className="text-white text-center opacity-60 text-xl">
                  Zabezpieczenia
                </p>
                <p className="text-white text-center opacity-60 text-xl">
                  Blog
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-6 lg:px-24 w-full items-start lg:items-center justify-start lg:justify-center mt-12 lg:mt-24">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę | Technologia
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4 text-left lg:text-left">
          Pierwsza warstwa - interfejs graficzny
        </p>
        <span className="mainColorBg w-24 h-1 lg:mt-4 mb-6 block"></span>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-12 w-full mt-6 lg:mt-12">
          <div className="flex flex-col items-center justify-start gap-6">
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh]">
              <p className="text-white text-xl">Narzędzia "WYSIWYG"</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Narzędzia tego typu pozwalają na utworzenie layoutu z
                wykorzystaniem gotowych bloków takich jak „Tekst” czy
                „Formularz”. Dzięki temu projekt można zrealizować szybciej oraz
                taniej. Niestety obciążają one wydajność co negatywnie wpływa na
                sprzedaż.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wysoka wydajność</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Efektywne pozycjonowanie</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>Możliwość personalizacji</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Brak ograniczeń technicznych</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-6 relative">
            <p className="bg-white text-center px-4 py-1 mainColor rounded-xl firstShadowLight absolute -top-6">
              REKOMENDOWANY
            </p>{" "}
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh] mixedGradients">
              <p className="text-white text-xl">Indywidualny projekt</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Indywidualne podejście polega na zaprojektowaniu strony
                graficznej oraz zaprogramowaniu jej od zera w czystym kodzie
                programistycznym. Dzięki temu projekt ładuje się szybko, jest
                wydajny oraz intuicyjny.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Wysoka</span> wydajność
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Efektywne</span>{" "}
                    pozycjonowanie
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Szeroka</span> możliwość
                    personalizacji
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Brak</span> ograniczeń
                    technicznych
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-6">
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh]">
              <p className="text-white text-xl">Gotowe motywy</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Zakupienie gotowego interfejsu znacząco obniża koszty. Niestety
                tego typu projekty posiadają znaczące ograniczenia
                personalizacji. Często tworzone są z wykorzystaniem gotowych
                narzędzi co również negatywnie przekłada się na pozycjonowanie.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wysoka wydajność</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Efektywne pozycjonowanie</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Możliwość personalizacji</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Brak ograniczeń technicznych</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-6 lg:px-24 w-full items-start lg:items-center justify-start lg:justify-center mt-12 lg:mt-24">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę | Technologia
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Druga warstwa - system zarządzania treścią (CMS)
        </p>
        <span className="mainColorBg w-24 h-1 lg:mt-4 mb-6 block"></span>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 w-full mt-6 lg:mt-12">
          <div className="flex flex-col items-center justify-start gap-6 relative">
            <p className="bg-white text-center px-4 py-1 mainColor rounded-xl firstShadowLight absolute -top-6">
              REKOMENDOWANY
            </p>
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh] mixedGradients">
              <p className="text-white text-xl">Gotowy CMS</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Do tej kategorii należą systemy takie jak WordPress. Pozwalają
                one na skorzystaniez przejrzystego panelu administracyjnego
                niższym kosztem. Pozwalają one na integrację dowolnego
                interfejsu graficznego. W skomplikowanych aplikacjach posiadają
                jednak ograniczenia techniczne
              </p>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Wyższa wydajność</span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Łatwe</span> integracje
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Niższe</span> koszty
                    utrzymania
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1 opacity-40">
                  <IoCloseOutline />
                  <span>Brak ograniczeń technicznych</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-6">
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh]">
              <p className="text-white text-xl">Indywidualny CMS</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Indywidualny system zarządzania treścią jest droższy od gotowego
                oprogramowania takiego jak WordPress. W większości przypadków
                nie ma jednak potrzeby tworzenia takiego systemu „od zera” z
                racji kosztów realizacji oraz utrzymania.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex flex-col text-white items-center gap-1">
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Wysoka</span> wydajność
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Efektywne</span>{" "}
                    pozycjonowanie
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Szeroka</span> możliwość
                    personalizacji
                  </span>
                </li>
                <li className="flex flex-row items-center justify-center gap-1">
                  <FaCheck />
                  <span>
                    <span className="fontSemiBold">Brak</span> ograniczeń
                    technicznych
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-6">
            <div className="rounded-xl flex flex-col items-center justify-start p-6 border-[1px] border-white border-opacity-50 min-h-[35vh]">
              <p className="text-white text-xl">Brak drugiej warstwy</p>
              <span className="w-5/6 h-[1px] bg-white my-2 opacity-50"></span>
              <p className="text-center text-white opacity-60">
                Istnieje możliwość rezygnacji z systemu zarządzania treścią.
                Wtedy wgrywamy jedynie pliki interfejsu graficznego na serwer.
                Nie mamy wtedy możliwości korzystania z panelu
                administracyjnego, a wszystkie zmiany należy realizować
                bezpośrednio w kodzie programistycznym.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Technology;
