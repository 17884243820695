import Hero from "../components/mvp/hero";
import Content from "../components/mvp/content";
import { Gemini } from "../components/mvp/gemini";
import Testimonials from "../components/home/testimonials";
import LogoSlider from "../components/home/logoSlider";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function Mvp() {
  return (
    <>
      <Helmet>
        <title>Minimum Viable Product | iFil Group</title>
        <meta name="title" content="Minium Viable Product | iFil Group" />
        <meta
          name="description"
          content="Projektujemy oraz implementujemy Minimum Viable Product z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy, które sprawdzą potencjał Twojego pomysłu na rynku."
          key="desc"
        />
        <meta
          property="og:title"
          content="Minium Viable Product | iFil Group"
        />
        <meta
          property="og:description"
          content="Projektujemy oraz implementujemy Minimum Viable Product z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy, które sprawdzą potencjał Twojego pomysłu na rynku."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Content />
        <Gemini />
        <Testimonials />
        <LogoSlider />
      </main>
      <Footer />
    </>
  );
}
export default Mvp;
