import FooterShort from "../components/common/footerShort";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
import Content from "../components/brief/content";
function Brief() {
  return (
    <>
      <Helmet>
        <title>Brief | iFil Group</title>
        <meta name="title" content="Formularz wyceny | iFil Group" />
        <meta
          name="description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta property="og:title" content="Formularz wyceny | iFil Group" />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Content />
      </main>
      <FooterShort />
    </>
  );
}
export default Brief;
