function GraphicInterface() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-12 lg:mt-24">
      <div className="gap-2 flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Co wchodzi w skład realizacji
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Interfejs graficzny
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 ">
          Po przeprowadzeniu badania rynku oraz potrzeb Klienta opracowujemy
          projekt układu graficznego. Skupiamy się na tym, aby layout graficzny
          był nie tylko atrakcyjny od strony wizualnej, ale także przykładamy
          dużą wagę do aspektu biznesowego.
        </p>
        <p className="w-full opacity-60 ">
          W efekcie projekt jest intuicyjny oraz zachęcający do korzystania z
          niego. Pozwoli to zwiększyć sprzedaż produktów oraz usług Klienta.
        </p>
      </div>
      <div className="w-full p-6 lg:p-0">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ifil-ui.jpeg"
          alt=""
          className="rounded-l-xl firstShadow"
        />
      </div>
    </div>
  );
}
export default GraphicInterface;
