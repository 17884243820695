function Pricing() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-6 lg:mt-24">
      <div className="flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Cena
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 mb-2">
          W sieci możemy znaleźć wiele ofert kuszących ceną. Na portalach
          społecznościowych często można spotkać możliwość utworzenia stron
          internetowych nawet za 500 zł.
        </p>
        <p className="w-full opacity-60 mb-2">
          Znacząca ilość naszych Klientów to podmioty, które skorzystały z
          takich „okazji”. Niestety w efekcie uzyskali projekt, który wymaga
          wysokich nakładów marketingowych (m.in. z powodu niskiej wydajności).
          Zmagają się oni również z problemem w pozycjonowaniu oraz brakiem
          wsparcia przy dalszej rozbudowie projektu.
        </p>
        <p className="w-full opacity-60">
          W profesjonalny projekt informatyczny zaangażowanych jest wielu
          specjalistów. W związku z tym decydując się na cięcia kosztów musimy
          liczyć się z wystąpieniem problemów w przyszłości.
        </p>
      </div>
      <div className="w-full lg:w-2/3 p-6 lg:p-0 overflow-hidden">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/mockup-pricing5.png"
          alt=""
          className="rounded-l-xl lg:translate-x-[5vw]"
        />
      </div>
    </div>
  );
}

export default Pricing;
