function Costs() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-6 lg:mt-24">
      <div className="flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Na co zwrócić uwagę
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Dalsze koszty
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 mb-2">
          Podstawowe koszty utrzymania projektu informatycznego to serwer,
          domena oraz wsparcie techniczne. Warto wcześniej zweryfikować jaki
          jest koszt utrzymania projektu przed rozpoczęciem współpracy.
        </p>
        <p className="w-full opacity-60 mb-2">
          W iFil Group posiadamy specjalnie wynegocjowane warunki z dostawcą
          domen oraz serwerów. Dodatkowo świadczymy całotygodniowe wsparcie
          techniczne w ramach realizacji usługi. Dzięki temu współpracując z
          nami minimalizujesz ryzyko dodatkowych kosztów.
        </p>
      </div>
      <div className="w-full p-6 lg:p-0 overflow-hidden">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/mockup2-pricing5.png"
          alt=""
          className="rounded-l-xl"
        />
      </div>
    </div>
  );
}

export default Costs;
