function Scope() {
  return (
    <section
      id="co-wchodzi-w-sklad-realizacji"
      className="flex flex-col items-start justify-start overflow-hidden relative px-6 lg:px-24 lg:mt-12"
    >
      <div className="flex flex-row items-end justify-between w-full mb-6">
        <div className="flex flex-col items-start justify-end">
          <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
            Co wchodzi w skład realizacji
          </p>
          <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
            Zakres realizacji
          </p>
          <span className="mainColorBg w-24 h-1"></span>
        </div>
        <div className="flex flex-row items-center justify-end gap-6">
          <p className="text-white text-xl fontRegular">
            Czegoś brakuje? Sprawdź
          </p>
          <a href="#najczesciej-zadawane-pytania">
            <button className="firstButton">
              Najczęściej zadawane pytania
            </button>
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-1.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">
            Projekt graficzny
          </p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Nie pracujemy na gotowych szablonach. Każdy produkt jest unikalny.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-2.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Responsywność</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Nasze oprogramowania dostosowane są do wszystkich rozdzielczości,
            m.in. telefonów i tabletów.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-3.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Domena</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Zapewnimy Ci na rok unikalną domenę (z certyfikatem SSL) na rok,
            która pozytywnie wpłynie na wizerunek Twojej marki!
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-4.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Serwer</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Nie musisz przejmować się kwestią techniczną, otrzymasz szybki
            serwer do każdej realizacji.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-5.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">
            Teksty i zdjęcia
          </p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Nie masz materiałów? To nic! Dysponujemy komercyjnymi bazami zdjęć
            oraz współpracujemy ze świetnymi copywriterami.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-6.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">
            Optymalizacja prędkości
          </p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Każdy projekt optymalizujemy pod kątem prędkości, tak aby uzyskiwał
            jak najlepsze wyniki.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-7.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Działania SEO</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Dbamy o techniczny aspekt pozycjonowania Twojej witryny, aby
            wznieść Twoją markę jak najwyżej.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-8.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Kwestie prawne</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Skonfigurujemy ciasteczka na Twojej stronie oraz dostarczymy
            odpowiednie dokumenty.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-9.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">
            Profile w mediach
          </p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Przygotujemy profile m.in. na Facebook-u, Instagramie oraz X!
            Przygotujemy również zestaw grafik.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-10.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">
            System analityki
          </p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Analiza ruchu na Twojej stronie jest kluczowa dla zwiększenia
            zasięgów. Z nami nie musisz się o to martwić.
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-11.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Funkcjonalności</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Potrzebujesz integracji z OpenAI? Synchronizacji z bazą GUS/REGON?
            Dla nas to nie problem!
          </p>
        </div>
        <div className="backdrop-blur-xl rounded-xl flex flex-col items-start lightWhite justify-start p-8">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/ikona-letsoffer-12.png"
            className="w-16 mb-2"
            alt=""
          />
          <p className="text-white mb-2 text-xl fontRegular">Wsparcie</p>
          <span className="w-12 h-1 mainColorBg mb-1"></span>
          <p className="text-white opacity-60">
            Wsparcie mailowe, telefoniczne oraz w formie panelu Klienta cały
            tydzień. Do tego stały Opeikun projektu.
          </p>
        </div>
      </div>
    </section>
  );
}
export default Scope;
