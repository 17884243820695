import BackgroundSquare from "../common/squareBg";

function Thanks() {
  return (
    <section className="blackColorBg relative flex lg:min-h-screen">
      <div id="top" className="w-full h-full z-10 absolute">
        <div className="sticky top-0 w-full lg:h-screen">
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col items-start justify-center z-50 px-6 py-12 lg:px-36 lg:pt-36 w-full gap-2 backdrop-blur-xl">
        <h1 className="text-6xl text-white">Dziękujemy za kontakt!</h1>
        <h2 className="text-4xl mainColor mt-2">
          Zajmujemy się Twoim zgłoszeniem
        </h2>
        <p className="text-white text-xl opacity-60 mt-4 w-2/3">
          Konsultanci pracują od poniedziałku do piątku, w godzinach 9:00 -
          16:00, w tym czasie skontaktujemy się z Tobą tak szybko jak to
          możliwe. W przypadku braku kontaktu do 24 godzin zadzwoń do nas (+48
          515 516 387) lub napisz e-mail (hello@ifil.pl).
        </p>
        <a href="/" className="mt-6">
          <button className="firstButton">strona główna</button>
        </a>
      </div>
    </section>
  );
}

export default Thanks;
