import Hero from "../components/interfaces/hero";
import Content from "../components/interfaces/content";
import { Gemini } from "../components/interfaces/gemini";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function Interfaces() {
  return (
    <>
      <Helmet>
        <title>Projektowanie interfejsów UI/UX | iFil Group</title>
        <meta
          name="title"
          content="Projektowanie interfejsów UI/UX | iFil Group"
        />
        <meta
          name="description"
          content="Kompleksowo projektujemy interfejsy graficzne dla stron internetowych, sklepów online oraz aplikacji z naciskiem na aspekt biznesowy i intuicyjność."
          key="desc"
        />
        <meta
          property="og:title"
          content="Projektowanie interfejsów UI/UX | iFil Group"
        />
        <meta
          property="og:description"
          content="Kompleksowo projektujemy interfejsy graficzne dla stron internetowych, sklepów online oraz aplikacji z naciskiem na aspekt biznesowy i intuicyjność."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Content />
        <Gemini />
      </main>
      <Footer />
    </>
  );
}
export default Interfaces;
