import React, { useEffect, useState } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

function Letsoffer() {
  const controls = useAnimation();
  const { scrollYProgress } = useScroll();
  const opacity2 = useTransform(scrollYProgress, [0.37, 0.41], [0.05, 0.1]);
  const scale = useTransform(scrollYProgress, [0.37, 0.41], [0.2, 1]);
  const translateX1 = useTransform(scrollYProgress, [0.41, 0.43], [0, 325]);
  const translateX2 = useTransform(scrollYProgress, [0.41, 0.43], [0, -325]);

  useEffect(() => {
    controls.start({ opacity: 1 });
  }, [controls]);

  useEffect(() => {
    const handleScroll = () => {
      console.log("scrollYProgress:", scrollYProgress.get());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress]);

  return (
    <section className="blackColorBg px-12 lg:px-0 pt-12 lg:pt-24 pb-12 lg:pb-36 relative flex flex-col overflow-hidden">
      <div className="text-white text-center flex flex-col items-center justify-start relative lg:pt-36">
        <motion.h3
          className="uppercase font-bold absolute top-0 inset-0"
          style={{
            letterSpacing: "0.2em",
            fontSize: "10rem",
            opacity: opacity2,
            scale: scale,
          }}
        >
          Let's <span className="mainColor fontRegular">offer</span>
        </motion.h3>
        <h2 className="text-3xl lg:text-6xl whiteHeading text-center w-full lg:w-3/4">
          Przenieś swoje oferty <br />
          na wyższy <span className="colorHeading fontRegular">poziom</span> za
          darmo
        </h2>
        <p className="text-white text-xl lg:text-3xl opacity-60 my-4 w-full lg:w-2/3 text-center">
          Nasza autorska aplikacja dedykowana firmom usługowym oraz handlowym
          pozwoli Ci na generowanie ofert oraz przesyłanie ich w jednym wygodnym
          linku do Klienta. Dostęp otrzymasz za darmo w ramach każdego projektu!
        </p>
        <a className="z-50" href="https://ifil.pl/bezplatna-konsultacja">
          <button className="firstButton mt-4">Dowiedz się więcej</button>
        </a>
      </div>
      <div className="lg:hidden mt-12">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/letsoffer.webp"
          alt=""
          className="rounded-xl firstShadowLight"
        />
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/letsoffer-ui-n.webp"
          alt=""
          className="rounded-xl firstShadowLight -translate-y-6"
        />
      </div>
      <div
        className="hidden lg:flex flex-row items-center justify-center relative w-full"
        style={{ height: "70vh" }}
      >
        <motion.div
          className="absolute object-contain rounded-xl firstShadowLight"
          style={{
            width: "45%",
            x: translateX1,
          }}
        >
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/letsoffer-ui-n.webp"
            alt=""
            className="w-full h-full object-contain rounded-xl"
          />
        </motion.div>
        <motion.div
          className="absolute object-contain rounded-xl firstShadowLight"
          style={{
            width: "45%",
            x: translateX2,
          }}
        >
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/letsoffer.webp"
            alt=""
            className="w-full h-full object-contain rounded-xl"
          />
        </motion.div>
      </div>
    </section>
  );
}

export default Letsoffer;
