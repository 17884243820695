import Hero from "../components/about/hero";

import Footer from "../components/common/footer";
import Header from "../components/common/header";

import { Helmet } from "react-helmet-async";

function About() {
  return (
    <>
      <Helmet>
        <title>O nas | iFil Group</title>
        <meta name="title" content="O nas | iFil Group" />
        <meta
          name="description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta property="og:title" content="O nas | iFil Group" />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
      </main>
      <Footer />
    </>
  );
}
export default About;
