import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import BASE_URL from "../../config";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface FormData {
  email: string;
  phone: string;
  subject: string;
  consent: boolean;
}

function BasicForm() {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    email: "",
    phone: "",
    subject: "",
    consent: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/basic-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Błąd :( Napisz na hello@ifil.pl");
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Twój adres e-mail"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          id="phone"
          label="Numer telefonu"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.phone}
          onChange={handleChange}
        />
        <TextField
          id="subject"
          label="Temat rozmowy"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.subject}
          onChange={handleChange}
        />
        <div className="flex flex-row items-start justify-start gap-2">
          <input
            type="checkbox"
            id="consent"
            className="mt-1"
            checked={formData.consent}
            onChange={handleChange}
            required={true}
          />
          <label
            htmlFor="consent"
            className="text-xs opacity-50 mb-2 text-white"
          >
            Wyrażam zgodę na kontakt telefoniczny oraz mailowy celem realizacji
            niniejszego zapytania. Oświadczam, że zapoznałem/am się z
            Regulaminem Serwisu oraz Polityką Prywatności i akceptuję
            postanowienia nieniejszych dokumentów. Wyrażam zgodę na
            wykorzystanie adresu e-mail w celach marketingowych przez iFil
            Group.
          </label>
        </div>
        <button className="firstButton" type="submit">
          Wyślij
        </button>
      </form>
    </ThemeProvider>
  );
}

export default BasicForm;
