import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

interface ReferralFormData {
  namePartner: string;
  emailPartner: string;
  referralPartner: string;
  referralEmailPartner: string;
  serviceNeeded: string;
  consentPartner: boolean;
}

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

function PartnerForm() {
  const navigate = useNavigate();
  const outerTheme = useTheme();
  const [formData, setFormData] = useState<ReferralFormData>({
    namePartner: "",
    emailPartner: "",
    referralPartner: "",
    referralEmailPartner: "",
    serviceNeeded: "",
    consentPartner: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/api/mailing/partner-form/`, formData);
      navigate("/dziekujemy#top");
    } catch (error) {
      console.error("Error submitting the form: ", error);
      alert("Failed to submit the form");
    }
  };
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextField
          name="namePartner"
          label="Twoje imię i nazwisko"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.namePartner}
          onChange={handleChange}
        />
        <TextField
          name="emailPartner"
          label="Twój adres e-mail"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.emailPartner}
          onChange={handleChange}
        />
        <TextField
          name="referralPartner"
          label="Imię i nazwisko polecanego Klienta"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.referralPartner}
          onChange={handleChange}
        />
        <TextField
          name="referralEmailPartner"
          label="Adres e-mail polecanego Klienta"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.referralEmailPartner}
          onChange={handleChange}
        />
        <TextField
          name="serviceNeeded"
          label="Usługa, której potrzebuje Klient"
          variant="standard"
          className="w-full"
          color="secondary"
          value={formData.serviceNeeded}
          onChange={handleChange}
        />
        <div className="flex flex-row items-start justify-start gap-2">
          <input
            type="checkbox"
            name="consentPartner"
            className="mt-1"
            checked={formData.consentPartner}
            onChange={handleChange}
            required={true}
            id="consentPartner"
          />
          <label
            htmlFor="consentPartner"
            className="text-xs opacity-50 mb-2 text-white"
          >
            Oświadczam, że otrzymałem/am zgodę od wyżej wymienionego Klienta na
            przekazanie jego danych w celu kontaktu handlowego ze strony iFil
            Group. Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
            realizacji współpracy partnerskiej.
          </label>
        </div>
        <button className="firstButton" type="submit">
          Wyślij
        </button>
      </form>
    </ThemeProvider>
  );
}

export default PartnerForm;
