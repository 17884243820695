import BasicForm from "../forms/basicForm";

function Cta() {
  return (
    <section className="blackColorBg py-12 lg:py-36 px-6 lg:px-48 flex flex-col lg:flex-row items-center justify-center gap-12 overflow-hidden relative">
      <div className="w-full flex flex-col items-center justify-start">
        <h2 className="text-3xl lg:text-6xl whiteHeading text-left">
          Porozmawiajmy
          <br />o tym jak możemy pomóc
          <span className="colorHeading fontRegular"> Twojemu biznesowi</span>
        </h2>
        <p className="text-white text-3xl opacity-60 my-4 text-left">
          Zbudujemy dla Ciebie projekt, który rozwinie Twój biznes dzięki
          wdrożeniu nowoczesnych technologii.
        </p>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full blackColorBg p-6 lg:p-12 firstShadowLight rounded-xl fancy relative flex flex-col items-center justify-center">
          <div className="w-5/6 z-10">
            <h2 className="text-4xl text-white mb-6">
              Wypełnij{" "}
              <span className="colorHeading fontRegular">formularz</span>
            </h2>
            <BasicForm />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Cta;
