import BackgroundSquare from "../common/squareBg";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import BASE_URL from "../../config";
import { Helmet } from "react-helmet-async";
interface Subcategory {
  title: string;
  slug: string;
}

interface Category {
  title: string;
  description: string;
  posts: Post[];
  meta_title: string;
  meta_description: string;
  subcategories: Subcategory[];
}

interface Post {
  title: string;
  slug: string;
  image_url: string;
  timing: string;
}

function Category() {
  const [category, setCategory] = useState<Category | null>(null);
  const { categorySlug } = useParams<{ categorySlug: string }>();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/blog/category/${categorySlug}/`)
      .then((res) => setCategory(res.data))
      .catch((err) => console.log(err));
  }, [categorySlug]);

  if (!category) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{category.meta_title}</title>
        <meta name="title" content={category.meta_title} />
        <meta
          name="description"
          content={category.meta_description}
          key="desc"
        />
        <meta property="og:title" content={category.meta_title} />
        <meta property="og:description" content={category.meta_description} />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <section className="blackColorBg relative flex min-h-screen">
        <div className="w-full h-full z-10 absolute">
          <div className="sticky top-0 w-full h-screen">
            <BackgroundSquare />
          </div>
        </div>
        <div className="flex flex-col z-50 px-6 py-12 lg:px-36 lg:pt-36 w-full gap-2 max-w-[1350px] mx-auto">
          <h1 className="text-3xl lg:text-6xl text-white">{category.title}</h1>
          <span className="h-1 w-12 mainColorBg my-2"></span>
          <div className="flex flex-row items-center justify-start gap-2 uppercase mainColor">
            <a
              href="/pomoc"
              className="hover:opacity-50 transition duration-300 ease-linear"
            >
              Pomoc
            </a>
            <span>/</span>
            <a
              href="/pomoc/baza-wiedzy"
              className="hover:opacity-50 transition duration-300 ease-linear"
            >
              Baza wiedzy
            </a>
            <span>/</span>
            <a
              href=""
              className="hover:opacity-50 transition duration-300 ease-linear fontSemiBold"
            >
              {category.title}
            </a>
          </div>
          <p className="text-lg text-white opacity-60 w-full lg:w-2/3">
            {category.description}
          </p>
          <div className="flex flex-row flex-wrap gap-6 mt-2 mb-6">
            {/* Renderowanie podkategorii */}
            {category.subcategories && category.subcategories.length > 0 ? (
              category.subcategories.map((subcategory) => (
                <a
                  href={`${categorySlug}/modul/${subcategory.slug}`}
                  key={subcategory.slug}
                >
                  <button className="relative group overflow-hidden border-opacity-80 border border-[#6f00d8] px-6 py-1 text-white text-opacity-90 text-sm rounded-[25px]">
                    <p className="z-20 relative">{subcategory.title}</p>
                    <div className="w-full h-full bg-gradient-to-br from-transparent to-[#6f00d8] opacity-20 group-hover:opacity-80 transition duration-300 ease-linear absolute z-10 block top-0 left-0"></div>
                  </button>
                </a>
              ))
            ) : (
              <p className=""></p>
            )}
          </div>
          <div className="grid grid-col-1 lg:grid-cols-3 gap-6">
            {category.posts.map((post) => (
              <a
                key={post.slug}
                href={`/pomoc/baza-wiedzy/${categorySlug}/${post.slug}`}
                className="border border-white border-opacity-10 cursor-pointer rounded-xl flex flex-col overflow-hidden relative hover:opacity-70 transition duration-300 ease-linear group"
              >
                <img src={post.image_url} alt={post.title} />
                <div className="p-6 flex flex-col gap-2 relative h-full">
                  <div className="w-full h-full bg-gradient-to-br from-transparent to-[#6f00d8] opacity-30 group-hover:opacity-80 transition duration-300 ease-linear absolute z-20 block top-0 left-0"></div>
                  <div className="w-full h-full blur-3xl absolute z-20 block"></div>
                  <h2 className="text-base text-white group-hover:underline z-30">
                    {post.title}
                  </h2>
                  <p className="text-white text-sm opacity-80 z-30">
                    {post.timing} min | {category.title}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Category;
