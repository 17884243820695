import React from "react";

const cardsData = [
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-letsoffer-img.jpeg",
    title: "Let's Offer",
    description:
      "Let's Offer pozwala na zarządzanie wszystkimi ofertami dla potencjalnych klientów w jednym miejscu. Ofertę utworzysz w dwie minuty! Wystarczy, że uzupełnisz podstawowe informacje za pomocą formularza, a Twoja oferta wygeneruje się sama na wcześniej aprojektowanym szablonie. Link do oferty możesz przesłać potencjalnemu Klientowi w dowolnej formie.",
    link: "https://letsoffer.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-contractorflow-img.jpeg",
    title: "ContractorFlow",
    description:
      "Nasza aplikacja webowa ContractorFlow, stworzona dla przedstawicieli handlowych i małych firm, umożliwia zarządzanie kontrahentami z jednego miejsca. Dodatkowo CRM integruje się z systemem BIR1.1, pozwalając na pobieranie informacji o firmie na podstawie NIP-u z bazy GUS.",
    link: "https://contractorflow.pl",
    category: "CRM",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/sm-byelementum.png",
    title: "By Elementum",
    description:
      "Dla marki kosmetyków do włosów przygotowaliśmy kompleksowy projekt interfejsu graficznego. Projekt zaprogramowaliśmy i wdrożyliśmy do otwarto-źródłowej platformy e-commerce.",
    link: "https://byelementum.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/lg-fsmm.png",
    title: "Fundacja Młodzi-Młodym",
    description:
      "Projekt dla Fundacji Studenckiej 'Młodzi Młodym' obejmował nie tylko projekt i implementację strony internetowej, ale także dostosowanie do integracji z poprzednią platformą, z której korzystała Fundacja. Dodatkowo stronę zintegrowaliśmy z czatem na żywo, który pozwala na szybki kontakt z Pracownikami Fundacji.",
    link: "https://fsmm.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/lg-romaniktools.png",
    title: "Romanik Tools",
    description:
      "W ramach realizacji dla Romanik Tools przeprowadziliśmy kompleksowe wdrożenie sklepu internetowego zintegrowanego z poprzednią bazą danych sklepu. Nowa szata graficzna została ulepszona przede wszystkim pod kątem User Experience oraz wydajności i SEO.",
    link: "https://romaniktools.eu",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/sm-transtec.png",
    title: "Transtec Group",
    description:
      "Firma transportowa Transtec Group w ramach naszej współpracy otrzymała gotowy oraz wdrożony do sieci projekt strony internetowej w czterech wersjach językowych. Dla poprawy pozycjonowania każda wersja językowa posiada swój unikalny adres oraz treści.",
    link: "https://transtec.group",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-okularyexpert-img.jpeg",
    title: "Okulary Expert",
    description:
      "Projekt obejmował zaprojektowanie i zakodowanie interfejsu graficznego, który został wdrożony do CMS opartego na PHP. Sklep internetowy integruje system płatności Przelewy24 oraz ERP używany w stacjonarnym salonie.",
    link: "https://okulary.expert",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-kasiatlumaczy-img.jpeg",
    title: "Kasia Tłumaczy",
    description:
      "Projekt Python z Django, automatyzujący tłumaczenie plików PDF. Aplikacja przetwarza pliki i zwraca je w wersji anglojęzycznej, wykorzystując model OpenAI dla dokładniejszych wyników niż Google Translate.",
    link: "",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-flexae-img.jpeg",
    title: "Flex Smart System",
    description:
      "Strona internetowa dla firmy technologicznej z Emiratów Arabskich, wykonana w TailwindCSS i zintegrowana z CMS opartym na PHP. Formularze kontaktowe są połączone z oprogramowaniem CRM, umożliwiając zarządzanie zgłoszeniami w jednym miejscu.",
    link: "https://flex.ae",
    category: "Strona internetowa + CRM",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-sofollow-img.jpeg",
    title: "SoFollow",
    description:
      "Projekt obejmował stworzenie sklepu internetowego z produktami wirtualnymi. Sklep integruje się z bankiem ING i systemem płatności Stripe oraz wykorzystuje geolokalizację do ustawienia odpowiedniej waluty.",
    link: "https://sofollow.eu",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-complexhome-img.jpeg",
    title: "Complex Home",
    description:
      "Sklep internetowy Complex Home ma indywidualnie zaprojektowany interfejs, co zapewnia wyższą wydajność i brak technicznych ograniczeń. Interfejs został zintegrowany z platformą e-commerce oraz systemami Przelewy24 i InPost Paczkomat.",
    link: "https://complexhome.eu",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-jakleci-img.jpeg",
    title: "Jak Leci",
    description:
      "Jak Leci to sklep internetowy wspierający organizację podróży. Projekt obejmował kompleksowe UI/UX, wdrożenie CMS oraz integrację z systemem fakturowania przez REST API. Sklep oferuje różnorodne warianty i kalkulator cen.",
    link: "https://jak-leci.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-artoni-img.jpeg",
    title: "Artoni",
    description:
      "Dla Artoni Foundation przygotowaliśmy projekt interfejsu i kompleksowo wdrożyliśmy go do systemu zarządzania treścią. Dzięki temu uzyskano wysoką wydajność i łatwość zarządzania treściami z poziomu panelu administracyjnego.",
    link: "",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-managly-img.jpeg",
    title: "Managly",
    description:
      "Aplikacja Managly to oprogramowanie dla freelancerów. Projekt obejmuje landing page i aplikację internetową z back-endem w Python Django oraz front-endem stworzonym w HTML/CSS i JavaScript.",
    link: "https://managly.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-gosiabednarek-img.jpeg",
    title: "Bednarek Studio",
    description:
      "Strona internetowa z wirtualnymi usługami i produktami. Projekt obejmował zaprojektowanie i zakodowanie interfejsu graficznego oraz wdrożenie go do WordPress WooCommerce.",
    link: "https://gosiabednarek.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-mtawedding-img.jpeg",
    title: "MTA Wedding",
    description:
      "Dla MTA Wedding odświeżyliśmy interfejs graficzny i poprawiliśmy wskaźniki SEO. Zaprojektowaliśmy interfejs UI/UX, a następnie wdrożyliśmy go w HTML/CSS/JS i PHP, co poprawiło wyniki Web Core Vitals o kilkadziesiąt punktów.",
    link: "https://mtawedding.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-apteczkanaszlaku-img.jpeg",
    title: "Apteczka na szlaku",
    description: "",
    link: "https://apteczkanaszlaku.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-katasze-img.jpeg",
    title: "Katasze",
    description:
      "W ramach projektu stworzono interfejs graficzny i wdrożono go do otwartoźródłowego CMS opartego na PHP. Sklep internetowy integruje system płatności Przelewy24 za pomocą API.",
    link: "https://katasze.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-trafficpolska-img.jpeg",
    title: "Traffic Polska",
    description:
      "Projekt dla Traffic Polska obejmował zaprojektowanie i implementację strony internetowej, która została wdrożona do CMS umożliwiającego zarządzanie stroną z panelu administracyjnego.",
    link: "https://trafficpolska.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-boatman-img.jpeg",
    title: "Boatman",
    description:
      "Dla sklepu internetowego Boatman stworzyliśmy nowy interfejs graficzny, zaprogramowany od zera i wdrożony do istniejącego sklepu na platformie WooCommerce.",
    link: "https://boatman.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-naturecoaching-img.jpeg",
    title: "Nature Coaching Institute",
    description: "",
    link: "https://naturecoachinginstitute.eu/",
    category: "Strona itnernetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-porownajsolary-img.jpeg",
    title: "Porównaj Solary",
    description:
      "Porównaj Solary to aplikacja internetowa wykorzystująca technologie back-endowe, takie jak Python Django, oraz front-endowe z interfejsem stworzonym w NextJS. Ten framework zapewnia wysoką wydajność i sprawne pozycjonowanie.",
    link: "https://porownajsolary.pl",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-marinaolchowiec-img.jpeg",
    title: "Marina Olchowiec",
    description: "",
    link: "https://marina-olchowiec.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-epojazd-img.jpeg",
    title: "E-Pojazd",
    description:
      "W ramach realizacji stworzono projekt interfejsu graficznego i kompleksowo wdrożono go do otwartoźródłowego CMS opartego na PHP, używając narzędzi WYSIWYG.",
    link: "https://e-pojazd.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-carboneperformance-img.jpeg",
    title: "Carbone Performance",
    description:
      "Sklep internetowy oparty na WooCommerce, zintegrowany z systemem ERP i Przelewy24. Dzięki temu rozwiązaniu sklep ma zewnętrzny magazyn powiązany z systemami księgowymi i kurierskimi.",
    link: "",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-basiaolearka-img.jpeg",
    title: "Basia Olearka",
    description: "",
    link: "https://basiaolearka.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-redsky-img.jpeg",
    title: "Red Sky",
    description:
      "Dla firmy Red Sky stworzyliśmy blog internetowy, projektując interfejs zgodnie z ich identyfikacją wizualną. Zrealizowaliśmy programowanie widoków i wdrożyliśmy je do WordPressa.",
    link: "https://red-sky.com/blog",
    category: "Blog",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-revopack-img.jpeg",
    title: "Revopack",
    description: "",
    link: "https://revopack.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-mkczesci-img.jpeg",
    title: "MK Części",
    description:
      "Sieć sklepów internetowych MK Części, oferująca części samochodowe, oparta jest na WordPress z WooCommerce. Strona zawiera bazę prawie miliona produktów.",
    link: "https://mkczesci.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-wpolszczi-img.jpeg",
    title: "wPolszczi",
    description:
      "Dla Kancelarii stworzyliśmy kompleksową stronę internetową z kalendarzem rezerwacji, systemem zarządzania wnioskami i integracją z bramką płatniczą Przelewy24. Zakodowanie interfejsu 'od zera' zapewniło zadowalające wyniki Web Core Vitals.",
    link: "https://wpolszczi.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-vitellolimited-img.jpeg",
    title: "Vitello Limited",
    description: "",
    link: "https://vitellolimited.com/",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-dietetycznecudawianki-img.jpeg",
    title: "Dietetyczne Cudawianki",
    description: "",
    link: "https://dietetycznecudawianki.com",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-apartamentyhappy-img.jpeg",
    title: "Apartamenty Happy",
    description: "",
    link: "https://apartamentyhappy.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-petartclub-img.jpeg",
    title: "PetArt Club",
    description: "",
    link: "https://petartclub.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-stayintorrevieja-img.jpeg",
    title: "Stay in Torrevieja",
    description:
      "System rezerwacji z podglądem dostępności i dynamicznym zarządzaniem cenami. Projekt obejmował zaprojektowanie interfejsu, programowanie strony oraz wdrożenie funkcjonalności kalendarza.",
    link: "https://stayintorrevieja.com",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-bosoboso-img.jpeg",
    title: "BosoBoso",
    description: "",
    link: "https://bosoboso.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-panoramasystem-img.jpeg",
    title: "Panorama System",
    description: "",
    link: "https://panoramasystem.eu",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-kancelariabbj-img.jpeg",
    title: "Kancelaria BBJ",
    description: "",
    link: "https://kancelariabbj.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-moneda-img.jpeg",
    title: "Moneda",
    description:
      "Wielobranżowy sklep internetowy stworzony w technologii WYSIWYG, umożliwiający Klientowi samodzielne zarządzanie interfejsem graficznym zgodnie z jego wymaganiami.",
    link: "https://moneda.com.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-gemini-img.jpeg",
    title: "Gemini Rzeszów",
    description:
      "Strona internetowa dla firmy Gemini to klasyczna witryna firmowa w formie strony statycznej, co zapewnia wysoką wydajność bez potrzeby dodatkowych systemów zarządzania treścią.",
    link: "https://geminirzeszow.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-netservis-img.jpeg",
    title: "Netservis",
    description: "",
    link: "https://netservis.com.pl",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-heritage-img.jpeg",
    title: "Heritage2000",
    description:
      "Dla sklepu Heritage2000 zaprogramowaliśmy interfejs graficzny i wdrożyliśmy go na platformie eCommerce. Sklep integruje się z popularnymi marketplace'ami.",
    link: "",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-villanette-img.jpeg",
    title: "Villanette",
    description: "",
    link: "https://villanette.pl/",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-eloco-img.jpeg",
    title: "Eloco",
    description: "",
    link: "https://eloco.pl",
    category: "E-commerce",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-akso-img.jpeg",
    title: "AKSO",
    description:
      "Realizacja obejmowała stworzenie platformy B2B z systemem logowania dla Partnerów. Administrator może dodawać produkty i ustawiać dedykowane ceny dla poszczególnych Partnerów.",
    link: "https://akso.com.pl/",
    category: "Aplikacja",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/sm-portfolio-box-carbonedetailing-img.jpeg",
    title: "Carbone Detailing",
    description:
      "Strona internetowa stworzona w HTML/CSS i JavaScript, zintegrowana z CMS, umożliwia właścicielowi łatwe zarządzanie treścią bez utraty wydajności.",
    link: "https://carbone-detailing.com",
    category: "Strona internetowa",
  },
  {
    img: "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/mockup-cards/lg-portfolio-box-opelmkczesci-img.jpeg",
    title: "Opel MK Części",
    description: "",
    link: "https://opelmkczesci.pl",
    category: "E-commerce",
  },
];

function Cards() {
  return (
    <section className="blackColorBg px-6 lg:px-24 pt-12 grid grid-cols-1 lg:grid-cols-5 gap-6 lg:gap-12">
      {cardsData.map((card, index) => (
        <div
          key={index}
          className={`${
            index % 4 === 0 || index % 4 === 3
              ? "lg:col-span-3"
              : "lg:col-span-2"
          } h-[400px] group overflow-hidden rounded-xl relative hidden lg:flex flex-col items-center justify-center`}
        >
          <img
            src={card.img}
            alt={card.title}
            className="w-full h-full object-cover z-10 group-hover:scale-110 transition duration-300 ease-linear"
          />
          <div className="w-full h-full bg-gradient-to-t from-black/80 to-transparent absolute z-20 opacity-100 group-hover:opacity-0 transition duration-300 ease-linear"></div>
          <div className="w-full h-full opacity-0 group-hover:opacity-90 bg-black transition duration-300 ease-linear absolute z-20"></div>
          <p className="z-30 absolute bottom-10 left-10 text-4xl text-white font-semibold group-hover:opacity-0 transition duration-100 ease-linear">
            {card.title}
          </p>
          <div className="z-30 absolute bottom-10 left-10 text-base text-white group-hover:opacity-100 transition duration-100 ease-linear opacity-0 max-w-[80%]">
            {card.description && <p className="mb-6">{card.description}</p>}
            {card.link && (
              <a target="_blank" href={card.link}>
                <button className="firstButton">Zobacz projekt</button>
              </a>
            )}
          </div>
          <p className="px-12 py-1 text-center text-white absolute top-10 left-10 mixedGradients z-40 rounded-xl">
            {card.category}
          </p>
        </div>
      ))}
      {cardsData.map((card, index) => (
        <a
          key={index}
          className="h-[400px] group overflow-hidden rounded-xl relative lg:hidden flex flex-col items-center justify-center"
          href={card.link}
        >
          <img
            src={card.img}
            alt={card.title}
            className="w-full h-full object-cover z-10 group-hover:scale-110 transition duration-300 ease-linear"
          />
          <p className="z-30 absolute bottom-10 left-10 text-4xl text-white font-semibold group-hover:opacity-0 transition duration-100 ease-linear">
            {card.title}
          </p>
          <p className="px-12 py-1 text-center text-white absolute top-10 left-10 mixedGradients z-40 rounded-xl">
            {card.category}
          </p>
        </a>
      ))}
    </section>
  );
}

export default Cards;
