import PortfolioHome from "../home/projects";
import Cards from "./cards";

export const products = [
  {
    title: "RedSky Blog",
    link: "https://red-sky.pl/blog",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-0.webp",
  },
  {
    title: "Carbone Performance",
    link: "https://carbone-performance.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-1.webp",
  },
  {
    title: "Carbone Detailing",
    link: "https://carbone-detailing.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-2.webp",
  },

  {
    title: "wPolszczi",
    link: "https://wpolszczi.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-3.webp",
  },
  {
    title: "ContractorFlow",
    link: "https://contractorflow.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-4.webp",
  },
  {
    title: "Managly",
    link: "https://managly.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-5.webp",
  },

  {
    title: "MTA Wedding",
    link: "https://mtawedding.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-6.webp",
  },
  {
    title: "Kasia Tłumaczy",
    link: "https://kasiatlumaczy.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-7.webp",
  },
  {
    title: "Boatman",
    link: "https://boatman.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-8.webp",
  },
  {
    title: "Bednarek Studio",
    link: "https://gosiabednarek.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-9.webp",
  },
  {
    title: "Stay in Torrevieja",
    link: "https://stayintorrevieja.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-10.webp",
  },
  {
    title: "Porównaj Solary",
    link: "https://porownajsolary.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-11.webp",
  },
  {
    title: "MK Części",
    link: "https://opelmkczesci.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-12.webp",
  },
  {
    title: "E-Pojazd",
    link: "https://e-pojazd.com",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-13.webp",
  },
  {
    title: "Traffic Polska",
    link: "https://trafficpolska.pl",
    thumbnail:
      "https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/project-14.webp",
  },
];

function Portfolio() {
  return (
    <>
      <div
        id="realizacje"
        className="flex flex-col p-6 lg:px-24 items-start justify-start"
      >
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          O nas
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Nasze realizacje
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <Cards />
      </div>
    </>
  );
}

export default Portfolio;
