import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import BackgroundSquare from "../common/squareBg";

function Testimonials() {
  const bgColor = {
    backgroundColor: "rgb(34, 34, 34)",
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className="relative w-full">
      <div className="absolute w-full h-full z-10">
        <BackgroundSquare />
      </div>
      <section
        id="testimonials"
        className="flex flex-col px-6 lg:px-36 items-center justify-center relative z-20"
      >
        <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6">
          Opinie
        </p>
        <h2 className="text-3xl lg:text-6xl text-center mb-12">
          <span className="whiteHeading fontRegular">Co mówią nasi</span>{" "}
          <span className="colorHeading fontRegular">Klienci</span>
        </h2>

        <div className="w-full flex flex-col lg:flex-row gap-4">
          <div className="w-full">
            <div className="rounded-xl lightWhite gap-6 backdrop-blur-xl py-6 px-12 flex flex-row items-center justify-start">
              <img
                className="w-20"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/opinie-google.png"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <p className="font-semibold text-2xl text-white">5.0 / 5.0</p>
                <p className="text-lg text-gray-400">Opinie na Google</p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="rounded-xl lightWhite gap-6 backdrop-blur-xl py-6 px-12 flex flex-row items-center justify-start">
              <img
                className="w-20"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/opinie-fb.png"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <p className="font-semibold text-2xl text-white">5.0 / 5.0</p>
                <p className="text-lg text-gray-400">Opinie na Facebook-u</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 w-full gap-4 relative mt-4">
          <div className="flex flex-col gap-4">
            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Małgorzata Bednarek</p>
              <p className="text-gray-400">
                Od początku kontakt był świetny. Profesjonalnie opracowana
                oferta i umowa. Kolejne etapy współpracy pokazały, iż warto
                podjąć współpracę z iFil nie tylko ze względu na ich pomysłowość
                (...) Gorąco polecam!
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Katarzyna Szebesta</p>
              <p className="text-gray-400">
                Jestem bardzo zadowolona ze współpracy i efektu końcowego mojej
                nowej strony internetowej. Wszystko przebiegło zdalnie, bez
                żadnych opóźnień, profesjonalnie i konkretnie.{" "}
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Dagmara Obsłuska</p>
              <p className="text-gray-400">
                Kulturalna współpraca na najwyższym poziomie, sprawnie i z
                cierpliwością dla moich licznych pytań. Szczerze polecam!
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Dorota Jurkiewicz</p>
              <p className="text-gray-400">
                Profesjonalizm, znajomość tematu, zrozumienie potrzeb nie tylko
                klienta ale przede wszystkim rynku są cechy które nasuwają się
                po współpracy z iFil Web Design. Każdy etap współpracy był
                przejrzyście przedstawiony i w 100% realizowany. Strona
                zaprojektowana przez iFil wygląda pięknie.
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Seweryn Bargiel</p>
              <p className="text-gray-400">
                Współpraca z firmą iFil na bardzo dużym poziomie. Szybki kontakt
                ze strony operatora strony. Pomoc we wszystkich aspektach
                prowadzenia strony internetowej. Firm godna polecenia
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Dorota Andrasiak</p>
              <p className="text-gray-400">
                Bardzo polecam iFil! Przyjemna współpraca, z wykonanej strony
                jestem bardzo zadowolona...
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Edyta Mycek</p>
              <p className="text-gray-400">
                Bardzo dobra współpraca. Potrzebowaliśmy strony gotowej na
                szybko i udało się. Bardzo duża elsatyczność. Świetny kontakt.
                Szybko nanoszone wszelkie poprawki jakie chcieliśmy. Szczerze
                polecam.
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Dagmara Obłuska</p>
              <p className="text-gray-400">
                Kulturalna współpraca na najwyższym poziomie, sprawnie i z
                cierpliwością dla moich licznych pytań. Szczerze polecam!
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Anna Jarecka</p>
              <p className="text-gray-400">
                Pełen profesjonalizm, bardzo dobra komunikacja z klientem,
                szybka i przede wszystkim mieszcząca się w obiecanym terminie
                realizacja zlecenia.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Grzegorz Żurawski</p>
              <p className="text-gray-400">
                Pełen profesjonalizmu w budowaniu strony sklepu internetowego.
                Wzorowy kontakt,bardzo szybka odpowiedź na pytania. Wiele
                trafnych sugestii dotyczących różnych elementów strony. Ceny
                bardzo konkurencyjne, a jakość usług na najwyższym poziomie.
                Będę polecał każdemu. Pozdrawiam
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Julia Baryła</p>
              <p className="text-gray-400">
                Profesjonalna obsługa, łatwy kontakt. Zawsze pomocni!
              </p>
            </div>

            <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
              <p className="text-white font-semibold">Wioleta Jasińska</p>
              <p className="text-gray-400">
                Strona internetowa wykonana według ustaleń. Jestem bardzo
                zadowolona z efektu wizualnego
              </p>
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-0 h-96 testimonialGradient"></div>
      </section>
    </div>
  );
}

export default Testimonials;
