import CustomerCarousel from "./customerCarousel";
import ProjectGlobe from "./globe";
import { OfferCards } from "./offerCards";

function HowWeCanHelp() {
  return (
    <>
      <section className="blackColorBg z-24">
        <CustomerCarousel />
        <OfferCards />
        <ProjectGlobe />
      </section>
    </>
  );
}
export default HowWeCanHelp;
