function WhyIfil() {
  return (
    <section className="blackColorBg py-12 lg:py-24 px-6 lg:px-48 flex flex-col items-center justify-end gap-12 overflow-hidden relative">
      <div className="w-full flex flex-col">
        <p className="whiteHeading text-3xl lg:text-8xl fontRegular">
          Wyróżniamy się{" "}
          <span className="colorHeading fontRegular">jakością</span>
        </p>
        <p className="whiteHeading text-2xl lg:text-5xl mt-6 fontRegular">
          ...jak każda inna firma.{" "}
          <span className="colorHeading fontRegular">Dlaczego, więc iFil?</span>
        </p>
      </div>
      <div className="flex flex-col lg:grid grid-cols-3 gap-4 w-full">
        <div className="col-span-1 rounded-xl overflow-hidden text-white mixedGradients relative">
          <div className="px-12 pt-12 pb-6">
            <p className="text-white fontRegular text-2xl mb-2">
              Responsywność
            </p>
            <p>
              Wszystkie realizacje dostosowane są w pełni responsywne. Dzięki
              temu Twój projekt będzie wyświetlał się poprawnie na każdym
              urządzeniu.
            </p>
          </div>
          <img
            className="lg:absolute bottom-0"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/iphone-mockup.png"
            alt=""
          />
        </div>
        <div className="col-span-2 lightWhite p-12 flex flex-col items-center rounded-xl text-white overflow-hidden gap-12">
          <div className="flex flex-col">
            <p className="mainColor fontRegular text-2xl mb-2">
              Wydajność i SEO
            </p>
            <p>
              Przykładamy szczególną wagę do odpowiedniej wydajności Twojego
              projektu, która wpływa nie tylko na pozytywny odbiór przez
              użytkownika, ale także przeglądarki internetowe.
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-12">
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>Dobre praktyki</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow scale-125">
              <p className="fontRegular text-4xl">100</p>
              <p>Dostępność</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>SEO</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lightWhite rounded-xl text-white flex flex-col lg:flex-row items-center justify-between relative overflow-hidden">
          <div className="flex flex-col w-full p-12">
            <p className="mainColor fontRegular text-2xl mb-2">
              Wsparcie po projekcie
            </p>
            <p>
              Zapewniamy kompleksową oraz bezpłatną pomoc naszym Klientom na
              każdym etapie współpracy, również po realizacji projektu!
            </p>
          </div>
          <div className="flex flex-col items-end justify-end h-full w-full">
            <img
              className="rounded-tl-xl w-3/4 lg:w-full mt-6 lg:mt-12 firstShadow"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/macbook-mockup-n.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white p-12 overflow-hidden">
          <div className="flex flex-col w-full mb-6">
            <p className="mainColor fontRegular text-2xl mb-2">
              Zaplecze techniczne
            </p>
            <p>
              Z nami nie musisz martwić się o serwer, domenę oraz certyfikat
              SSL. Wszystko otrzymasz w pełni skonfigurowane - w jednym miejscu.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center lightWhite rounded-full w-full pt-2 pb-4 firstShadow text-xl">
              www.<span className="mainColor fontRegular">twojastrona</span>.pl
            </p>
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white relative overflow-hidden">
          <div className="flex flex-col w-full pt-12 px-12">
            <p className="text-white fontRegular text-2xl mb-2 mainColor">
              Zarządzenie i analityka
            </p>
            <p>
              Otrzymasz dostęp do intuicyjnego panelu administracyjnego oraz
              systemu analityki, dzięki czemu będziesz mógł śledzić ruch na
              swojej stronie.
            </p>
          </div>
          <div className="flex flex-col items-end justify-end w-full pt-6">
            <img
              className="firstShadow rounded-tl-xl"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/analytics-mockup.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-2 mixedGradients flex flex-col lg:flex-row gap-12 items-center justify-between rounded-xl text-white p-12">
          <div className="flex flex-col w-full">
            <p className="text-white fontRegular text-2xl mb-2">
              Kompleksowe realizacje
            </p>
            <p>
              Oprócz kwestii technicznych oraz pełnego wsparcia dbamy również
              m.in. o zgodność Twojego projektu z obowiązującymi przepisami
              prawnymi.
            </p>
          </div>
          <div className="w-full bg-white p-4 lg:p-6 rounded-xl flex flex-col gap-2 shadow-2xl">
            <p className="blackColor fontRegular">Przetwarzanie danych</p>
            <p className="blackColor leading-tight text-sm">
              Celem zapewnienia jak najwyższej jakości korzystania z naszych
              stron wykorzystujemy Cookies.
            </p>
            <div className="flex flex-row items-center justify-start gap-1">
              <button className="firstButton">Akceptuję</button>
              <button className="hidden lg:flex secondButton">Odrzucam</button>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lightWhite rounded-xl text-white flex flex-col lg:flex-row items-center justify-between relative overflow-hidden">
          <div className="flex flex-col w-full p-12 mb-12 lg:mb-0">
            <p className="mainColor fontRegular text-2xl mb-2">
              Oferta z kilkoma wariantami
            </p>
            <p>
              Realizacja projektu informatycznego może odbyć się przy użyciu
              różnorodnych technologii. Nasze oferty obejmują kilka opcji, co
              ułatwia lepsze porównanie dostępnych możliwości.
            </p>
          </div>
          <div className="flex flex-row items-center justify-center lg:hidden">
            <div className="p-8 translate-x-8 -translate-y-12 mainColorBg rounded-xl flex-col items-center justify-center mb-6">
              <p>I wariant</p>
              <p className="fontRegular text-2xl">3400 PLN</p>
            </div>
            <div className="p-8 -translate-x-8 -translate-y-6 bg-white rounded-xl mainColor firstShadow flex-col items-center justify-center mb-6">
              <p>II wariant</p>
              <p className="fontRegular text-2xl">4800 PLN</p>
            </div>
          </div>
          <div className="hidden lg:flex flex-col items-center justify-center lg:h-full w-full relative">
            <div className="p-8 mainColorBg top-6 left-6 rounded-xl flex flex-col items-center justify-center absolute">
              <p>I wariant</p>
              <p className="fontRegular text-2xl">3400 PLN</p>
            </div>
            <div className="p-8 bg-white rounded-xl mainColor firstShadow bottom-6 right-6 rounded-2l flex flex-col items-center justify-center absolute">
              <p>II wariant</p>
              <p className="fontRegular text-2xl">4800 PLN</p>
            </div>
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white p-12 overflow-hidden">
          <div className="flex flex-col w-full mb-6">
            <p className="mainColor fontRegular text-2xl mb-2">
              Ekspresowy kontakt
            </p>
            <p>
              Dbamy o jak najwyższe standardy komunikacji z naszymi Klientami.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center ">
            <p className="text-center lightWhite rounded-full w-full pt-2 pb-4 mainColorBg firstShadow text-xl">
              odpowiedź do 30 min
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WhyIfil;
