import BackgroundSquare from "../common/squareBg";

function PageNotFound404() {
  return (
    <section className="blackColorBg relative flex lg:min-h-screen">
      <div id="top" className="w-full h-full z-10 absolute">
        <div className="sticky top-0 w-full lg:h-screen">
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col items-start justify-center z-50 px-6 py-12 lg:px-36 lg:pt-36 w-full gap-2 backdrop-blur-xl">
        <h1 className="text-6xl text-white">404</h1>
        <h2 className="text-4xl mainColor mt-2">
          Nietety, taka strona nie istnieje
        </h2>
        <a href="/" className="mt-6">
          <button className="firstButton">strona główna</button>
        </a>
      </div>
    </section>
  );
}

export default PageNotFound404;
