function Content() {
  return (
    <section className="blackColorBg lg:px-24 lg:pt-12 px-6">
      <div className="flex flex-col items-center justify-center lh:px-24 pt-12">
        <h3 className="text-3xl lg:text-7xl text-center">
          <span className="whiteHeading fontRegular">
            Nie pozostawaj w tyle
          </span>{" "}
          <br />
          <span className="whiteHeading fontRegular">za swoją</span>{" "}
          <span className="colorHeading fontRegular">konkurencją</span>
        </h3>

        <p className="text-white text-xl lg:text-3xl opacity-60 my-4 w-full lg:w-2/3 text-center">
          Nowoczesne interfejsy dostosowane do potrzeb Twoich Klientów.
        </p>
      </div>
      <div className="flex flex-col lg:grid grid-cols-3 gap-4 w-full mt-12">
        <div className="col-span-1 rounded-xl overflow-hidden text-white mixedGradients relative">
          <div className="px-12 pt-12 pb-6">
            <p className="text-white fontRegular text-2xl mb-2">
              Responsywność
            </p>
            <p>
              Wszystkie realizacje dostosowane są w pełni responsywne. Dzięki
              temu Twój projekt będzie wyświetlał się poprawnie na każdym
              urządzeniu.
            </p>
          </div>
          <img
            className="lg:absolute bottom-0"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/iphone-mockup.png"
            alt=""
          />
        </div>
        <div className="col-span-2 lightWhite p-12 flex flex-col items-center rounded-xl text-white overflow-hidden gap-12">
          <div className="flex flex-col">
            <p className="mainColor fontRegular text-2xl mb-2">
              Wydajność i SEO
            </p>
            <p>
              Przykładamy szczególną wagę do odpowiedniej wydajności Twojego
              projektu, która wpływa nie tylko na pozytywny odbiór przez
              użytkownika, ale także przeglądarki internetowe.
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-12">
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>Dobre praktyki</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow scale-125">
              <p className="fontRegular text-4xl">100</p>
              <p>Dostępność</p>
            </div>
            <div className="w-36 h-36 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
              <p className="fontRegular text-4xl">100</p>
              <p>SEO</p>
            </div>
          </div>
        </div>
        <div className="col-span-2 lightWhite rounded-xl text-white flex flex-col lg:flex-row items-center justify-between relative overflow-hidden">
          <div className="flex flex-col w-full p-12">
            <p className="mainColor fontRegular text-2xl mb-2">
              Wsparcie po projekcie
            </p>
            <p>
              Zapewniamy kompleksową oraz bezpłatną pomoc naszym Klientom na
              każdym etapie współpracy, również po realizacji projektu!
            </p>
          </div>
          <div className="flex flex-col items-end justify-end h-full w-full">
            <img
              className="rounded-tl-xl w-3/4 lg:w-full lg:mt-12 firstShadow"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/macbook-mockup-n.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white p-12 overflow-hidden">
          <div className="flex flex-col w-full mb-6">
            <p className="mainColor fontRegular text-2xl mb-2">
              Nacisk na konwersję
            </p>
            <p>
              Oprócz atrakcyjnego interfejsu skupiamy się na zachęceniu
              potencjalnych Klientów do skorzystania z Twojej oferty.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center lightWhite rounded-full w-full pt-1 pb-2 firstShadow text-xl">
              <span className="text-white">Bezpłatna konsultacja</span>
            </p>
          </div>
        </div>
        <div className="col-span-1 lightWhite rounded-xl text-white relative overflow-hidden">
          <div className="flex flex-col w-full pt-12 px-12">
            <p className="text-white fontRegular text-2xl mb-2 mainColor">
              Zarządzenie i analityka
            </p>
            <p>
              Otrzymasz dostęp do intuicyjnego panelu administracyjnego oraz
              systemu analityki, dzięki czemu będziesz mógł śledzić ruch na
              swojej stronie.
            </p>
          </div>
          <div className="flex flex-col items-end justify-end w-full pt-6">
            <img
              className="firstShadow rounded-tl-xl"
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/analytics-mockup.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-2 mixedGradients flex flex-col lg:flex-row gap-12 items-center justify-between rounded-xl text-white p-12 mb-12 lg:mb-0">
          <div className="flex flex-col w-full">
            <p className="text-white fontRegular text-2xl mb-2">
              Kompleksowe realizacje
            </p>
            <p>
              Oprócz kwestii technicznych oraz pełnego wsparcia dbamy również
              m.in. o zgodność Twojego projektu z obowiązującymi przepisami
              prawnymi oraz integracje z systemami kurierskimi i płatniczymi.
            </p>
          </div>
          <div className="w-full bg-white p-6 rounded-xl flex flex-col gap-2 shadow-2xl">
            <p className="blackColor fontRegular">Przetwarzanie danych</p>
            <p className="blackColor leading-tight text-sm">
              Celem zapewnienia jak najwyższej jakości korzystania z naszych
              stron wykorzystujemy Cookies.
            </p>
            <div className="flex flex-row items-center justify-start gap-1">
              <button className="firstButton">Akceptuję</button>
              <button className="hidden lg:flex secondButton">Odrzucam</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
