import BackgroundSquare from "../common/squareBg";
import HowWeWork2 from "../letsofferComponentsApp/2howWeWork";
import Scope3 from "../letsofferComponentsApp/3scope";
import GraphicInterface3 from "../letsofferComponentsApp/3graphicInterface";
import Assets3 from "../letsofferComponentsApp/3assets";
import Hosting3 from "../letsofferComponentsApp/3hosting";
import Help3 from "../letsofferComponentsApp/3help";
import Support3 from "../letsofferComponentsApp/3support";
import Pricing4 from "../letsofferComponentsApp/4pricing";
import Technology5 from "../letsofferComponentsApp/5technology";
import PricingWebsite1 from "../letsofferComponentsApp/1websiteOffer";
import Supervisor0 from "../letsofferComponentsApp/0supervisor";
import VideoWebsite1 from "../letsofferComponentsApp/1video";
import Pricing5 from "../letsofferComponentsApp/5pricing";
import Costs5 from "../letsofferComponentsApp/5costs";
import Support5 from "../letsofferComponentsApp/5support";
import Cta from "../letsofferComponentsApp/cta";
import Benefits6 from "../letsofferComponentsApp/6benefits";
import Faq7 from "../letsofferComponentsApp/7faq";
import Feature8 from "../letsofferComponentsApp/8feature";
import LetsOffer8 from "../letsofferComponentsApp/8letsoffer";
import Environment8 from "../letsofferComponentsApp/8environment";
import Reference8 from "../letsofferComponentsApp/8reference";
import Portfolio8 from "../letsofferComponentsApp/8portfolio";
import Contact9 from "../letsofferComponentsApp/9contact";
import Timeline1 from "../letsofferComponentsApp/1timeline";

function WebsiteOffer() {
  return (
    <section className="blackColorBg relative flex min-h-screen">
      <div className="w-full h-full z-10 absolute">
        <div className="sticky top-0 w-full h-screen">
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col z-50 pt-12 lg:pt-48 pb-6 lg:pb-24 w-full gap-2">
        <Supervisor0 />
        <PricingWebsite1 />
        <Timeline1 />
        <VideoWebsite1 />
        <HowWeWork2 />
        <Scope3 />
        <GraphicInterface3 />
        <Assets3 />
        <Hosting3 />
        <Help3 />
        <Support3 />
        <Pricing4 />
        <Technology5 />
        <Pricing5 />
        <Costs5 />
        <Support5 />
        <Benefits6 />
        {/* <Cta /> */}
        <Faq7 />
        <Feature8 />
        <LetsOffer8 />
        <Environment8 />
        <Reference8 />
        <Portfolio8 />
        <Contact9 />
      </div>
    </section>
  );
}
export default WebsiteOffer;
