function Process() {
  return (
    <div className="mixedGradients px-6 lg:px-24 py-6 lg:py-12 grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-24">
      <div className="text-white flex flex-col gap-2">
        <p className="text-4xl">01</p>
        <p className="text-3xl">Co się wydarzy dalej?</p>
        <span className="w-12 h-[2px] bg-white mb-2"></span>
        <p className="text-lg">
          Po uzupełnieniu formularza skontaktujemy się z Tobą między 9:00 a
          16:00 celem zebrania wstępnego wywiadu. Możesz również skorzystać z{" "}
          <a
            className="underline cursor-pointer"
            href="https://outlook.office365.com/owa/calendar/iFilGroupBooking@ifil.pl/bookings/"
          >
            kalendarza
          </a>{" "}
          i umówić się na dogodny termin.
        </p>
      </div>
      <div className="text-white flex flex-col gap-2">
        <p className="text-4xl">02</p>
        <p className="text-3xl">Dobór Opiekuna</p>
        <span className="w-12 h-[2px] bg-white mb-2"></span>
        <p className="text-lg">
          Pracownik biura przeprowadzi wstępny wywiad oraz dopasuje opiekuna
          projektu, który będzie posiadał odpowiednie kompetencje. Opiekun
          skontaktuje się z Tobą w przeciągu 24 godzin.
        </p>
      </div>
      <div className="text-white flex flex-col gap-2">
        <p className="text-4xl">03</p>
        <p className="text-3xl">Konsultacja</p>
        <span className="w-12 h-[2px] bg-white mb-2"></span>
        <p className="text-lg">
          Podczas rozmowy z opiekunem projektu omówisz swoje oczekiwania oraz
          uzyskasz odpowiedzi na pytania dotyczące realizacji potencjalnego
          projektu informatycznego.
        </p>
      </div>
    </div>
  );
}
export default Process;
