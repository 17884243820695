import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Subcategory from "../components/support/blogSubcategory";

function SupportBlogSubcategory() {
  return (
    <>
      <Header />
      <main className="relative z-40">
        <Subcategory />
      </main>
      <Footer />
    </>
  );
}
export default SupportBlogSubcategory;
