import React, { useState, useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";
import { PinContainer } from "../additional/3d-pin";

function Hero() {
  const offer = ["aplikacje", "strony", "sklepy", "interfejsy", "systemy CRM"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % offer.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.2], [0.95, 1.9]);

  return (
    <section className="blackColorBg px-6 flex flex-col items-center justify-start overflow-hidden relative">
      <div className="w-full h-full object-cover absolute inset-0">
        {" "}
        <BackgroundSquare />
      </div>
      <div className="flex flex-col items-center justify-center pt-12 lg:pt-36 z-20">
        <h1 className="mainColor fontLight text-sm lg:text-xl text-center">
          iFil Group - O nas
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">Naszym celem jest</span>
          <br />
          <span className="whiteHeading fontRegular">wsparcie</span>{" "}
          <span className="colorHeading fontRegular">Twojego biznesu</span>
        </h2>

        <p className="text-white text-base lg:text-2xl opacity-60 mb-12 w-full lg:w-2/3 text-center">
          Od 2020 r. zajmujemy się kompleksową realizacją projektów
          informatycznych, m.in. stron internetowych, sklepów on-line oraz
          aplikacji internetowych. Dzięki naciskowi na ciągły rozwój dostarczamy
          naszym Klientom wydajne oraz intuicyjne rozwiązania biznesowe.
        </p>
        <a href="/bezplatna-konsultacja">
          <button className="secondButton flex flex-row items-center justify-center gap-1">
            <span>Porozmawiajmy o tym jak możemy Ci pomóc</span>
            <svg
              width={25}
              height={25}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4.00655 7.93309C3.93421 9.84122 4.41713 13.0817 7.6677 16.3323C8.45191 17.1165 9.23553 17.7396 10 18.2327M5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C15.2529 20.0243 14.1963 19.9541 13 19.6111"
                  stroke="#6f00d8"
                  stroke-width="1.2"
                  stroke-linecap="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </a>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 w-full z-50 px-24 gap-6 lg:gap-24">
        <div className="h-[25rem] lg:h-[30rem] w-full flex items-center justify-center ">
          <a href="https://ifil.pl">
            <PinContainer title="ifil.pl" href="https://ifil.pl">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  iFil Group
                </h3>
                <div className="text-base !m-0 !p-0 font-normal">
                  <span className="text-white text-lg opacity-60 ">
                    Strony, sklepy on-line i aplikacje
                  </span>
                </div>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
                  alt=""
                  className="m-auto opacity-70 w-5/6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[25rem] lg:h-[30rem] w-full flex items-center justify-center ">
          <a href="https://letsoffer.pl">
            <PinContainer title="letsoffer.pl" href="https://letsoffer.pl">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Let's offer
                </h3>
                <div className="text-base !m-0 !p-0 font-normal">
                  <span className="text-white text-lg opacity-60 ">
                    System ofertowania dla firm
                  </span>
                </div>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/About/letsoffer-white.png"
                  alt=""
                  className="m-auto opacity-70 w-5/6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[25rem] lg:h-[30rem] w-full flex items-center justify-center ">
          <a href="https://managly.pl">
            <PinContainer title="managly.pl" href="https://managly.pl">
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  Managly
                </h3>
                <div className="text-base !m-0 !p-0 font-normal">
                  <span className="text-white text-lg opacity-60 ">
                    Aplikacja dla freelancerów
                  </span>
                </div>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/managly-pl/managly-logo.png"
                  alt=""
                  className="m-auto opacity-70 w-5/6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
        <div className="h-[25rem] lg:h-[30rem] w-full flex items-center justify-center ">
          <a href="https://contractorflow.pl">
            <PinContainer
              title="contractorflow.pl"
              href="https://contractorflow.pl"
            >
              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[17rem] h-[20rem] ">
                <h3 className="max-w-xs text-2xl !pb-2 !m-0 font-bold text-slate-100">
                  ContractorFlow
                </h3>
                <div className="text-base !m-0 !p-0 font-normal">
                  <span className="text-white text-lg opacity-60 ">
                    CRM dla branży handlowej
                  </span>
                </div>
                <img
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/About/contractorflow-white.webp"
                  alt=""
                  className="m-auto opacity-70 w-5/6"
                />
              </div>
            </PinContainer>
          </a>
        </div>
      </div>
    </section>
  );
}
export default Hero;
