function Content() {
  return (
    <section className="blackColorBg px-6 lg:px-24 pt-12 flex flex-col items-center justify-center gap-24">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Obniżamy koszty na marketing i reklamę
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Dzięki odpowiednio dobranej technologii oraz przeprowadzeniu
          optymalizacji projektu zaoszczędzisz swoje środki już od pierwszych
          dni kampanii marketingowej. Prędkość strony oraz User Experience
          przekładają się bezpośrednio na konwersję i sprzedaż. W związku z tym
          w przypadku dbałości o takie aspekty jak czystość kodu, UX oraz
          wydajność działania marketingowe stają się znacznie efektywniejsze!
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/analiticsus.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Poprawiamy wizerunek w sieci
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Projekty realizujemy z dbałością o aspekt biznesowy i funkcjonalny,
          ale także o estetykę i wizerunek marki. Dzięki temu Twoja firma
          zyskuje nowoczesny i profesjonalny wizerunek w sieci. Wpływa to na
          zwiększenie zaufania klientów oraz na poprawę konwersji.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/jakleci.jpeg"
          className="rounded-xl max-w-3/4 lg:max-w-[800px] firstShadowDropLight -rotate-6"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Przykładamy dużą wagę do
          <br />
          wydajności i bezpieczeństwa
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Nie korzystamy z gotowych rozwiązań. Każdy projekt tworzymy w czystym
          kodzie programistycznym, co pozwala na optymalizację i dostosowanie
          projektu potrzeb Twojego biznesu. W efekcie Twój projekt działa
          odpowiednio szybko. Czystość kodu i pełny dostęp do niego znacząco
          poprawia bezpieczeństwo Twojej infrastruktury oraz danych Twoich
          Klientów.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/vscode.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Dbamy o Twoją niezależność
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Każdy projekt integrujemy z systemem zarządzania treścią, który
          pozwoli Ci na pełną swobodę w zarządzeniu swoim projektem. Dzięki temu
          nie będziesz zależny od zewnętrznych firm i będziesz mógł samodzielnie
          zarządzać zmianami. Dodatkowo rejestrujemy całą infrastrukturę, m.in.
          serwer i domenę, na Twoje dane, co daje Ci pełną kontrolę nad
          własnością.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/crm.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight -rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Zapewniamy ciągłe wsparcie
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Dysponujemy działem wsparcia, który jest do Twojej dyspozycji cały
          tydzień. Wspieramy naszych Klientów w utrzymaniu infrastruktury, ale
          także w rozwoju projektu. Dzięki temu masz pewność, że Twój projekt
          działa bez zarzutu, a Ty zawsze możesz liczyć na nasze wsparcie.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/support.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Zwiększamy konwersję i sprzedaż
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Nie pracujemy na gotowych motywach. Każdy projekt tworzymy od podstaw,
          po dogłębnej analizie rynku Twojej branży oraz potrzeb projektu. W
          efekcie uzyskujesz indywidualny projekt nastawiony na zwiększenie
          zysków Twojej marki.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/uxchart.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight -rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Uwalniamy Twój czas
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-12 lg:mb-16 max-w-3/4 lg:max-w-[800px]">
          Przykładamy dużą wagę do automatyzacji procesów, co pozwala na
          uwalnianie Twojego czasu. Dzięki temu możesz skupić się na rozwoju
          swojego biznesu, a nie na codziennych rutynach.
        </p>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/automation.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight rotate-6 rounded-xl"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center pb-36">
        <h2 className="text-2xl lg:text-5xl text-center mb-2 whiteHeading">
          Dostarczamy narzędzia do rozwoju sprzedaży
        </h2>
        <p className="text-white opacity-60 text-center text-lg mb-6 max-w-3/4 lg:max-w-[800px]">
          Opracowaliśmy autorskie narzędzia dla naszych Klientów. Pierwszym z
          nich jest aplikacja Let's Offer, która pozwala na tworzenie
          indywidualnych ofert spójnych z identyfikacją Twojej marki w mniej niż
          90 sekund! Dla naszych Klientów posiadamy również system CRM
          ContractorFlow, który pozwala na zarządzanie bazą kontrahentów oraz
          szansami sprzedaży.
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 mb-12 lg:mb-16">
          <a href="https://letsoffer.pl">
            <button className="firstButton">Let's Offer</button>
          </a>
          <a href="https://contractorflow.pl">
            <button className="secondButton">ContractorFlow</button>
          </a>
        </div>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/ui-table.png"
          className="max-w-3/4 lg:max-w-[800px] firstShadowDropLight -rotate-6 rounded-xl"
          alt=""
        />
      </div>
    </section>
  );
}
export default Content;
