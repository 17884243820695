function Benefits() {
  return (
    <div
      id="korzysci-biznesowe"
      className="flex flex-col items-start lg:items-center justify-start lg:justify-center text-white p-6 mt-12 lg:mt-0 lg:p-24"
    >
      <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
        Korzyści biznesowe
      </p>
      <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4 text-left lg:text-left">
        Projekt to nie tylko ładna strona!
      </p>
      <span className="mainColorBg w-24 h-1 mb-6"></span>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-12">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/benefits-ico-1.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Poprawa wizerunku</p>
            <p className="text-lg opacity-60">
              Profesjonalny projekt wzmacnia postrzeganie Twojej firmy.
              Estetycznyi funkcjonalny design buduje zaufaniei wyróżnia Cię na
              tle konkurencji.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/benefits-ico-2.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Wyższa konwersja</p>
            <p className="text-lg opacity-60">
              Odpowiednio zaprojektowany oraz przemyślany interfejs, nawigacja,
              struktura oraz szybkie ładowanie poprawia współczynnik konwersji
              na stronie. W efekcie zyskujesz większą ilość Klientów.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/benefits-ico-3.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">
              Niższe koszty marketingowe
            </p>
            <p className="text-lg opacity-60">
              Kompleksowa optymalizacja zapewni odpowiednią wydajność oraz
              techniczną optymalizację pozycjonowania. Pozwoli to na znaczącą
              redukcję kosztów marketingowych.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2 lg:gap-6">
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/benefits-ico-4.png"
            alt="Ikona"
            className="w-24"
          />
          <div>
            <p className="font-bold text-2xl mb-1">Automatyzacja procesów</p>
            <p className="text-lg opacity-60">
              Zaawansowane technologie automatyzują codzienne czynności,
              oszczędzając czas i zasoby, które można przeznaczyć na rozwój
              firmy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
