function Help() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-12 lg:mt-24">
      <div className="gap-2 flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Co wchodzi w skład realizacji
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Instruktaż i wsparcie
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 ">
          Działamy aktywnie w kierunku edukacji naszych Klientów. Regularnie
          rozwijamy nasz blog oraz nagrywamy bezpłatne kursy video.
        </p>
        <p className="w-full opacity-60 ">
          Po każdym projekcie zapewniamy komplet poradników oraz przeprowadzamy
          instruktaż w formie wideokonferencji.
        </p>
      </div>
      <div className="w-full p-6 lg:p-0">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/3help.png"
          alt=""
          className="rounded-l-xl firstShadow"
        />
      </div>
    </div>
  );
}
export default Help;
