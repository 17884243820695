import BackgroundSquare from "../common/squareBg";
import TextField from "@mui/material/TextField";
import PsdField from "@mui/material/TextField";
import axios from "axios";

import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      secondary: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      info: {
        main: "#6f00d8",
        light: "#6f00d8",
        dark: "#6f00d8",
        contrastText: "rgba(255,255,255,0.6)",
      },
      text: {
        primary: "rgba(255,255,255,0.6)",
        secondary: "rgba(255,255,255,0.6)",
        disabled: "rgba(255,255,255,0.18)",
      },
      divider: "rgba(255,255,255,0.6)",
    },
    typography: {
      fontFamily: '"LuxeUnoLight"',
    },
  });

interface ErrorDetails {
  [key: string]: string[]; // Zakładając, że backend zwraca tablicę stringów jako komunikaty błędów dla każdego pola
}

function Register() {
  const outerTheme = useTheme();
  const [errors, setErrors] = useState<ErrorDetails>({});
  const navigate = useNavigate();

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username");
    const email = formData.get("email");
    const firstName = formData.get("firstName");
    const lastName = formData.get("lastName");
    const password = formData.get("password");

    try {
      const response = await axios.post(`${BASE_URL}/api/accounts/register/`, {
        username,
        email,
        first_name: firstName,
        last_name: lastName,
        password,
      });
      setErrors({});
      navigate("/panel/login");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Registration failed:", error.response.data);
        setErrors(error.response.data as ErrorDetails);
      }
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <section className="blackColorBg flex flex-col items-center justify-start overflow-hidden relative py-24 lg:h-screen lg:pt-24">
        <div className="w-full h-full object-cover absolute inset-0">
          {" "}
          <BackgroundSquare />
        </div>
        <div className="flex flex-col items-center justify-center gap-4 z-20 w-full h-full">
          <form
            onSubmit={handleRegister}
            action=""
            className="lightWhite p-12 rounded-xl firstShadowLight backdrop-blur-2xl flex flex-col gap-4"
          >
            <h2 className="text-white text-2xl">Rejestracja</h2>{" "}
            <TextField
              id="standard-basic"
              label="Nazwa uzytkownika"
              variant="standard"
              className="w-72"
              color="secondary"
              name="username"
              error={!!errors.username}
              helperText={errors.username ? errors.username.join(" ") : ""}
            />
            <TextField
              id="standard-basic"
              label="Adres e-mail"
              variant="standard"
              className="w-72"
              color="secondary"
              name="email"
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.join(" ") : ""}
            />
            <TextField
              id="standard-basic"
              label="Imię"
              variant="standard"
              className="w-72"
              color="secondary"
              name="firstName"
            />
            <TextField
              id="standard-basic"
              label="Nazwisko"
              variant="standard"
              className="w-72"
              color="secondary"
              name="lastName"
            />
            <PsdField
              id="standard-basic"
              label="Hasło"
              variant="standard"
              className="w-72"
              color="secondary"
              type="password"
              name="password"
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.join(" ") : ""}
            />
            <PsdField
              id="standard-basic"
              label="Powtórz hasło"
              variant="standard"
              className="w-72"
              color="secondary"
              type="password"
              name="password2"
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.join(" ") : ""}
            />
            <button type="submit" className="firstButton mt-4">
              Zarejestruj się
            </button>
            <a
              href="/panel/login"
              className="text-white opacity-60 hover:opacity-40 transition duration-300 ease-linear text-center"
            >
              Mam już konto
            </a>
          </form>
        </div>
      </section>
    </ThemeProvider>
  );
}
export default Register;
