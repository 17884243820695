import React, { useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

function ProjectImage() {
  const controls = useAnimation();
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0.73, 0.76], [0.7, 1.2]);

  useEffect(() => {
    controls.start({ scale: 1 });
  }, [controls]);

  return (
    <>
      <section className="blackColorBg hidden lg:flex flex-col items-center justify-end gap-12 py-64 overflow-hidden">
        <motion.div
          className="container flex flex-col items-center justify-center relative"
          style={{ scale: scale }}
        >
          <div className="absolute bottom-12 right-24 px-6 w-40 h-40 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
            <p className="text-center">Projekt dla naszego Klienta</p>
          </div>
          <img
            className="w-2/3 rounded-xl firstShadow"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/carboneperformance.webp"
            alt="Mockup"
          />
        </motion.div>
      </section>
      <section className="blackColorBg flex lg:hidden px-6 py-36 relative">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Projects/carboneperformance.webp"
          alt=""
          className="rounded-xl firstShadow"
        />{" "}
        <div className="absolute bottom-24 right-6 px-6 w-24 h-24 mixedGradients flex flex-col items-center justify-center text-white rounded-full firstShadow">
          <p className="text-center text-sm">Projekt dla Klienta</p>
        </div>
      </section>
    </>
  );
}

export default ProjectImage;
