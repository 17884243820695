import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import BASE_URL from "../../config";

interface PricingWebsite {
  var1_timing: number;
}

function Timeline() {
  const [offer, setOffer] = useState<PricingWebsite | null>(null);
  const { offerSlug } = useParams<{ offerSlug: string }>();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/letsoffer/appoffer/${offerSlug}/`)
      .then((res) => setOffer(res.data))
      .catch((err) => console.log(err));
  }, [offerSlug]);

  if (!offer) {
    return <div>Loading...</div>;
  }

  return (
    <div id="harmonogram" className="flex flex-col z-50 w-full gap-2">
      <div className="px-6 lg:px-24 flex flex-col mt-12 lg:mt-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Wycena indywidualna
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-6 mt-4">
          Proponowany harmonogram prac
        </p>
        <span className="mainColorBg w-24 h-1 mb-12"></span>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 w-full">
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              <div className="w-16 h-16 rounded-full mixedGradients shrink-0 flex flex-col items-center justify-center">
                <p className="text-white fontSemiBold uppercase">Start</p>
              </div>
              <span className="w-full h-[1px] bg-white block opacity-60"></span>
            </div>
            <div className="w-full">
              <p className="text-2xl fontSemiBold text-white">Umowa i brief</p>
              <p className="text-white text-lg">
                Wszystko rozpoczyna się od podpisanej umowy oraz zebrania
                informacji o projekcie.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              <div className="w-16 h-16 rounded-full mixedGradients shrink-0 flex flex-col items-center justify-center">
                <p className="text-white fontSemiBold uppercase text-center leading-[1.1em] text-[18px]">
                  {offer.var1_timing}
                  <br />
                  <span className="text-xs font-light">dzień</span>
                </p>
              </div>
              <span className="w-full h-[1px] bg-white block opacity-60"></span>
            </div>
            <div className="w-full">
              <p className="text-2xl fontSemiBold text-white">Projekt UI/UX</p>
              <p className="text-white text-lg">
                Do tego dnia przedstawimy Państwu proponowaną szatę graficzną.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              <div className="w-16 h-16 rounded-full mixedGradients shrink-0 flex flex-col items-center justify-center">
                <p className="text-white fontSemiBold uppercase text-center leading-[1.1em] text-[18px]">
                  {offer.var1_timing + 5}
                  <br />
                  <span className="text-xs font-light">dzień</span>
                </p>{" "}
              </div>
              <span className="w-full h-[1px] bg-white block opacity-60"></span>
            </div>
            <div className="w-full">
              <p className="text-2xl fontSemiBold text-white">
                Sesja poprawkowa
              </p>
              <p className="text-white text-lg">
                Rekomendujemy przesłanie nam swoich odczuć oraz poprawek w
                przeciągu około 5 dni.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              <div className="w-16 h-16 rounded-full mixedGradients shrink-0 flex flex-col items-center justify-center">
                <p className="text-white fontSemiBold uppercase text-center leading-[1.1em] text-[18px]">
                  {offer.var1_timing + 12}
                  <br />
                  <span className="text-xs font-light">dzień</span>
                </p>{" "}
              </div>
              <span className="w-full h-[1px] bg-white block opacity-60"></span>
            </div>
            <div className="w-full">
              <p className="text-2xl fontSemiBold text-white">
                Wdrożenie projektu
              </p>
              <p className="text-white text-lg">
                Gdy otrzymamy komplet informacji od Państwa w przeciągu 7 dni
                dokonamy uruchomienia projektu.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              <div className="w-16 h-16 rounded-full mixedGradients shrink-0 flex flex-col items-center justify-center">
                <p className="text-white fontSemiBold uppercase text-center leading-[1.1em] text-[18px]">
                  {offer.var1_timing + 13}+
                  <br />
                  <span className="text-xs font-light">dzień</span>
                </p>{" "}
              </div>
              <span className="w-full h-[1px] bg-white block opacity-60"></span>
            </div>
            <div className="w-full">
              <p className="text-2xl fontSemiBold text-white">
                Wsparcie i rozwój
              </p>
              <p className="text-white text-lg">
                Po zakończeniu projektu zapewniamy kompleksowe wsparcie Ciebie i
                Twojego projektu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Timeline;
