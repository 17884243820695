function Support() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-0 lg:gap-6 text-white mt-12 lg:mt-24">
      <div className="gap-2 flex-col flex text-xl w-full p-6 lg:pl-24 items-start justify-start">
        <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
          Co wchodzi w skład realizacji
        </p>
        <p className="text-3xl lg:text-5xl text-white font-semibold mb-4 mt-4">
          Opieka powdrożeniowa
        </p>
        <span className="mainColorBg w-24 h-1 mb-6"></span>
        <p className="w-full opacity-60 ">
          Oprócz zapewnienia kompleksowych instruktaży w formie video oraz
          pisemnej, a także przeprowadzeniu wideokonferencji podczas, której
          omawiamy jak korzystać z projektu, zapewniamy również opiekę po
          zakończeniu projektu.
        </p>
        <p className="w-full opacity-60 ">
          Posiadamy dział wsparcia, który działa mailowo cały tydzień oraz
          telefonicznie i za pomocą czatu na żywo od poniedziałku do piątku.
        </p>
        <p className="w-full opacity-60">
          W ramach opieki powdrożeniowej pomożemy Ci ze zmianą treści na
          stronie, zarekomendujemy Ci najlepsze rozwiązania, zainterweniujemy
          podczas wszelkich awarii. Z nami nie musisz obawiać się o dodatkowe
          koszty bądź brak dostępności usług.
        </p>
      </div>
      <div className="w-full p-6 lg:p-0">
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/LetsOffer/3support.png"
          alt=""
          className="rounded-l-xl firstShadow"
        />
      </div>
    </div>
  );
}
export default Support;
