function Partner() {
  return (
    <section className="blackColorBg flex flex-col items-start justify-start overflow-hidden relative px-6 lg:px-36 pt-6 lg:pt-36 text-white gap-4 documentsBox">
      <h1 className="text-3xl lg:text-5xl text-white mb-12">
        Regulamin Programu Partnerskiego
      </h1>
      <>
        <>
          <h2>§1 Wstęp</h2>
          <ol>
            <li>
              1. Niniejszy Regulamin określa warunki uczestnictwa w programie
              partnerskim, który polega na polecaniu przez Partnerów usług
              Organizatora Nowym Klientom w zamian za Prowizję, a także określa
              zasady przyznawania Prowizji oraz jej wysokość (dalej ‘Program
              Partnerski’). Program Partnerski obejmuje także świadczenie przez
              Organizatora na rzecz Partnera usług prowadzenia Konta
              Partnerskiego w aplikacji internetowej dostępnej pod adresem
              https://ifil.pl.
            </li>
            <li>
              2. Organizatorem Programu Partnerskiego (dalej 'Organizator') jest
              iFil Group Filip Kania, NIP: 8943219325, REGON: 526343129 z
              siedzibą we Wrocławiu.
            </li>
            <li>
              3. Usługi świadczone przez Organizatora są realizowane przy użyciu
              serwisu dostępnego pod adresem https://ifil.pl (dalej ‘Panel
              Partnera’).
            </li>
          </ol>
          <h2>§2 Definicje</h2>
          <ul>
            <li>
              <b>Umowa</b> - umowa pomiędzy Organizatorem a Partnerem, która
              dotyczy uczestnictwa w Programie Partnerskim.
            </li>
            <li>
              <b>Organizator</b> - iFil Group Filip Kania, NIP: 8943219325,
              REGON: 526343129 z siedzibą we Wrocławiu.
            </li>
            <li>
              <b>Partner</b> - osoba fizyczna, która posiada pełną zdolność do
              czynności prawnych, osoba fizyczna prowadząca działalność
              gospodarczą lub osoba prawna, jednostka organizacyjna, która nie
              posiada osobowości prawnej, której ustawa przyznaje zdolność
              prawną, spełniająca wszystkie wymagania dotyczące Partnera
              określone w niniejszym Regulaminie, która zawarła Umowę z
              Organizatorem.
            </li>
            <li>
              <b>Nowy Klient</b> - osoba fizyczna o pełnej zdolność do czynności
              prawnych, osoba prawna lub jednostka organizacyjna nieposiadająca
              osobowości prawnej, ale mogąca we własnym imieniu nabywać prawa i
              zaciągać zobowiązania, w tym Konsument jak i Przedsiębiorca, która
              nigdy nie korzystała z Usług Organizatora,
            </li>
            <li>
              <b>Konsument</b> - Nowy Klient, który zawarł umowę o świadczenie
              danej Usługi z Organizatorem poprzez Polecenie Partnera.
            </li>
            <li>
              <b>Rejestracja</b> - utworzenie nowego konta w Panelu Partnera
              oraz uzupełnienie wszystkich wymaganych przy rejestracji danych.
            </li>
            <li>
              <b>Zamówienie</b> - oświadczenie woli złożone przez Nowego Klienta
              zmierzające do zawarcia umowy o świadczenie Usługi z
              Organizatorem.
            </li>
            <li>
              <b>Polecenie</b> - działanie, które doprowadziło do Zamówienia i
              zakupu usług Organizatora przez Nowego Klienta na koncie Nowego
              Klienta, które jest zgodne z Regulaminem Programu Partnerskiego.
            </li>
          </ul>
          <h2>§3 Postanowienia ogólne</h2>
          <ol>
            <li>
              1. Celem Programu Partnerskiego jest zwiększenie sprzedaży Usług
              Organizatora na rzecz Nowych Klientów przy pomocy Partnerów.
            </li>
            <li>
              2. W celu przystąpienia do Programu Partnerskiego Partner
              zobowiązany jest do:
              <ul>
                <li>Rejestracji w Panelu Partnera,</li>
                <li>Akceptacji Regulaminu Programu Partnerskiego,</li>
                <li>
                  Zapoznanie się z informacjami dostępnymi na stronie
                  Organizatora.
                </li>
              </ul>
            </li>
            <li>3. Korzystanie z Programu Partnerskiego jest dobrowolne.</li>
            <li>4. Umowa zawierana jest na czas nieokreślony.</li>
            <li>
              5. Partner ma możliwość zerwania umowy powiadamiając o tym fakcie
              Organizatora drogą elektroniczną z wykorzystaniem poczty
              elektronicznej (info@ifil-webdesign.pl).
            </li>
            <li>
              6. Partner zobowiązany jest uczestniczyć w Programie Partnerskim w
              sposób zgodny z dobrymi obyczajami oraz prawem. Mając także na
              uwadze poszanowanie dóbr osobistych, danych osobowych oraz praw
              autorskich i własności intelektualnej Organizatora, Poleconych
              oraz innych podmiotów trzecich.
            </li>
            <li>
              7. Partnera obowiązuje zakaz udostępniania treści o charakterze
              bezprawnym oraz zakaz podawania fałszywych danych Partnera i
              danych nieistniejących podmiotów.
            </li>
          </ol>
          <h2>§4 Działania Partnera celem promocji Usług Organizatora</h2>
          <ol>
            <li>
              1. Partner ma możliwość wykorzystania materiałów promocyjnych
              (dalej ‘Materiały promocyjne’) dostarczanych przez Organizatora.
            </li>
            <li>
              2. Partner zobowiązany jest do niezmieniania treści materiałów
              promocyjnych.
            </li>
            <li>
              3. Partner ma obowiązek natychmiast usunąć wskazane przez
              Organizatora Materiały Promocyjne.
            </li>
            <li>
              4. W przypadku zaniechania przez Partnera usunięcia wskazanych
              Materiałów Promocyjnych może zawiesić konto Partnera lub rozwiązać
              Umowę ze skutkiem natychmiastowym.
            </li>
            <li>
              5. Organizator ma prawo do monitorowania Materiałów Promocyjnych
              wykorzystywanych przez Partnera i nakazania zaprzestania
              rozpowszechniania wybranych Materiałów Promocyjnych, bez podania
              przyczyny.
            </li>
            <li>
              6. Partner nie może wykorzystywać form promocji wskazanych przez
              Organizatora jako Niedozwolone Sposoby Promocji, które zostały
              określone w paragrafie 6 Regulaminu Programu Partnerskiego.
            </li>
          </ol>
          <h2>§5 Niedozwolone sposoby promocji</h2>
          <ol>
            <li>
              1. Partnerowi nie wolno promować Usług Organizatora przy użyciu
              Niedozwolonych Sposobów Promocji, które zostały wymienione poniżej
              w niniejszym Regulaminie:
              <ol>
                <li>
                  a. Umieszczanie w Materiałach Promocyjnych treści, które
                  naruszają prawo osób trzecich, prawa powszechne, zasady
                  współżycia społecznego, normy moralne i obyczajowe oraz dobre
                  obyczaje.
                </li>
                <li>
                  b. Wszelkie działania prowadzące do naruszenia praw oraz
                  interesów Organizatora.
                </li>
                <li>
                  c. Tworzenie płatnych kampanii promocyjnych, które
                  wykorzystują nazwę marki iFil Group, iFil Web Design, iFil
                  Solutions, iFil Security oraz iFil Academy.
                </li>
                <li>
                  d. Nieuczciwe pozycjonowanie Materiałów Promocyjnych w
                  wyszukiwarkach internetowych, w szczególności stosowanie
                  systemów wymiany linków, używanie słów kluczowych
                  niezwiązanych z treścią strony oraz wykorzystywanie nazw marki
                  iFil Group, iFil Web Design, iFil Solutions, iFil Security
                  oraz iFil Academy.
                </li>
                <li>e. Dopuszczanie się czynów nieuczciwej konkurencji.</li>
                <li>f. Stosowanie nieuczciwych praktyk rynkowych.</li>
                <li>
                  g. Nakłanianie Poleconych do zmiany dostawcy usług tworzenia
                  stron internetowych, sklepów internetowych, systemów
                  rezerwacji oraz usług graficznych, aplikacji internetowych i
                  mobilnych.
                </li>
                <li>h. Spamowanie.</li>
                <li>
                  i. Agresywna oraz natarczywa Promocja Usług Organizatora.
                </li>
                <li>
                  j. Promocja Usług Organizatora podmiotom, które nie wyraziły
                  na to zgody.
                </li>
              </ol>
            </li>
            <li>
              2. W przypadku, gdy dojdzie do złamania Regulaminu Programu
              Partnerskiego przez Partnera Organizator zastrzega sobie prawo do
              zawieszenia oraz zamknięcia konta Partnera.
            </li>
            <li>
              3. Zawieszenie konta Partnera obejmuje:
              <ol>
                <li>
                  a. Blokadę możliwości zlecenia wypłaty Prowizji z tytułu
                  Programu Partnerskiego.
                </li>
                <li>b. Blokadę naliczania nowych Prowizji.</li>
              </ol>
            </li>
            <li>
              4. Partner, którego konto zostało zawieszone i zamknięte nie ma
              możliwości ponownego przystąpienia do Programu partnerskiego.
            </li>
          </ol>
          <h2>§6 Sposoby naliczania prowizji</h2>
          <ol>
            <li>
              1. Prowizja jest kwotą, którą otrzymuje Partner za Polecenie Usług
              Organizatora.
            </li>
            <li>
              2. Prowizja obliczana jest na podstawie poniższych Stawek
              Prowizyjnych:
              <ol>
                <li>
                  a. Polecenie usługi oferowanej przez Organizatora na kwotę od
                  800 PLN do 1100 PLN netto – prowizja wynosi 100 PLN netto.
                </li>
                <li>
                  b. Polecenie usługi oferowanej przez Organizatora na kwotę od
                  1101 PLN do 1800 PLN netto – prowizja wynosi 200 PLN netto.
                </li>
                <li>
                  c. Polecenie usługi oferowanej przez Organizatora na kwotę od
                  1801 PLN do 2500 PLN netto – prowizja wynosi 300 PLN netto.
                </li>
                <li>
                  d. Polecenie usługi oferowanej przez Organizatora na kwotę od
                  2501 PLN do 5000 PLN netto – prowizja wynosi 350 PLN netto.
                </li>
                <li>
                  e. Polecenie usługi oferowanej przez Organizatora na kwotę
                  wyższą niż 5001 PLN netto – prowizja wynosi 8%.
                </li>
                <li>
                  f. Polecenie usługi pozycjonowania przez Partnera Organizatora
                  - prowizja wynosi jednorazowo 10% od miesięcznej wartości
                  umowy.
                </li>
                <li>
                  g. Polecenie usług serwerowych przez Partnera Organizatora -
                  prowizja wynosi jednorazowo 10% od miesięcznej wartości umowy.
                </li>
              </ol>
            </li>
            <li>
              3. Maksymalna kwota jednorazowej prowizji wynosi 3000 PLN netto.
            </li>
            <li>
              4. Prowizja naliczana jest do konta Partnera w Panelu Partnera w
              przeciągu 14 dni roboczych od zaksięgowania środków Nowego Klienta
              na rzecz usługi poleconej przez Partnera.
            </li>
            <li>
              5. Saldo Prowizji uwzględnia wszystkie niewypłacone środki
              zgromadzone na Koncie Partnera w Panelu Partnera.
            </li>
            <li>
              6. Środki wypłacane są przez Organizatora w przeciągu 14 dni
              roboczych od złożenia dyspozycji wypłaty przez Partnera.
            </li>
            <li>
              7. Organizator zastrzega sobie prawo przeprowadzenia akcji
              promocyjnych, w ramach których Partnerom może przysługiwać
              dodatkowa Prowizja.
            </li>
            <li>
              8. W sytuacji, gdy Nowy Klient nie opłaci Usługi, bądź nie
              dokończy podjętej realizacji Usługi u Organizatora Prowizja dla
              Partnera nie zostaje naliczona.
            </li>
            <li>
              9. Zlecenie wypłaty Prowizji następuje w wyniku przesłanej
              wiadomości drogą mailową z autoryzowanego adresu e-mail
              przypisanego do Konta Partnera, na adres Organizatora –
              hello@ifil.pl, bądź z poziomu Panelu Partnera.
            </li>
            <li>
              10. Konsumenci zobowiązani są do przesłania wiadomości z
              informacją o kwocie wypłaty Prowizji w ramach zgromadzonego Salda
              Prowizji na Koncie Partnera w Panelu Partnera, numerze konta
              bankowego, imienia i nazwiska, adresu zamieszkania, numeru
              rachunku bankowego, PESELu oraz nazwy i adresu urzędu skarbowego.
            </li>
            <li>
              11. Przedsiębiorcy zobowiązani są do przesłania wiadomości
              mailowej z załączeniem faktury VAT wystawionej przez Partnera na
              wartość kwoty netto do wypłaty Prowizji. Faktura musi posiadać
              14-dniowy okres zapłaty, a w jej tytule umieszczony musi zostać
              Identyfikator Partnera.
            </li>
            <li>
              12. Zlecenie wypłaty Prowizji nie może obejmować kwoty wyższej,
              niż zgromadzone na Koncie Partnera w Panelu Partnera Saldo
              Prowizji.
            </li>
            <li>13. Prowizja wypłacana jest maksymalnie raz w miesiącu.</li>
          </ol>
          <h2>§7 Reklamacje</h2>
          <ol>
            <li>
              1. Reklamacje dotyczące Programu Partnerskiego należy składać w
              formie elektronicznej, droga mailową na adres hello@ifil.pl w
              terminie 14 dni od daty zdarzenia, którego dotyczy reklamacja.
            </li>
            <li>
              2. Reklamacja musi zawierać Login Polecającego oraz opis
              zgłaszanego zdarzenia.
            </li>
            <li>
              3. Organizator rozpatrzy reklamację w terminie 30 dni roboczych od
              daty jej doręczenia.
            </li>
            <li>
              4. O wyniku reklamacji Partner zostanie powiadomiony droga
              mailową.
            </li>
            <li>
              5. Brak odpowiedzi na reklamację we wskazanym terminie nie oznacza
              jej uznania przez Organizatora.
            </li>
          </ol>
          <h2>§8 Klauzula poufności</h2>
          <ol>
            <li>
              1. Partner zobowiązany jest do zachowania w tajemnicy wszelkich
              informacji, które uzyskał w związku z Umową zawartą pomiędzy
              Organizatorem a Partnerem.
            </li>
            <li>
              2. Do tajemnic należy między innymi informacje technologiczne,
              ekonomiczne, finansowe, handlowe oraz organizacyjne.
            </li>
          </ol>
          <h2>§9 Postanowienia końcowe</h2>
          <ol>
            <li>
              1. We wszelkich kwestiach nieuregulowanych w niniejszej umowie
              zastosowanie będzie miał Kodeks Cywilny.
            </li>
            <li>
              2. Wszelkie spory mogące wynikać w związku z realizacją niniejszej
              umowy rozstrzygać będzie sąd właściwy miejscowo dla Wykonawcy.
            </li>
          </ol>
        </>
      </>
    </section>
  );
}
export default Partner;
