import React, { useState, useEffect } from "react";

const generateRandomOpacity = () => Math.random() * 0.5 + 0.1; // Generowanie losowej wartości przezroczystości

const BackgroundSquare: React.FC = () => {
  const [squares, setSquares] = useState<boolean[][]>([]);

  useEffect(() => {
    const numRows = 10;
    const numCols = 10;

    const initialSquares = Array.from({ length: numRows }, () =>
      Array.from({ length: numCols }, () => Math.random() > 0.5)
    );

    setSquares(initialSquares);

    const interval = setInterval(() => {
      setSquares((prevSquares) =>
        prevSquares.map((row) =>
          row.map((square) => (Math.random() > 0.5 ? !square : square))
        )
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="grid grid-cols-10 gap-1 w-full h-full blackColorBg">
      {squares.map((row, rowIndex) =>
        row.map((square, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            className={`${
              square ? "bg-zinc-950" : "bg-transparent"
            } transition-opacity duration-300`}
            style={{ opacity: generateRandomOpacity() }}
          ></div>
        ))
      )}
    </div>
  );
};

export default BackgroundSquare;
