import Hero from "../components/companyWebsites/hero";
import Content from "../components/companyWebsites/content";
import { Gemini } from "../components/companyWebsites/gemini";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function CompanyWebsites() {
  return (
    <>
      <Helmet>
        <title>Wizytówki firmowe | iFil Group</title>
        <meta name="title" content="Wizytówki firmowe | iFil Group" />
        <meta
          name="description"
          content="Tworzymy strony internetowe, wizytówki firmowe, strony onepage z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          key="desc"
        />
        <meta property="og:title" content="Wizytówki firmowe | iFil Group" />
        <meta
          property="og:description"
          content="Tworzymy strony internetowe, wizytówki firmowe, strony onepage z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Content />
        <Gemini />
      </main>
      <Footer />
    </>
  );
}
export default CompanyWebsites;
