import React, { useRef, useState } from "react";
import { FaRegCirclePlay } from "react-icons/fa6";

function VideoWebsite() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div
      id="video"
      className="relative px-6 pb-6 lg:pb-0 pt-12 lg:px-24 lg:pt-24 flex flex-col items-start justify-start overflow-hidden"
    >
      <p className="text-white text-base uppercase mixedGradients rounded-xl py-1 px-4">
        Wycena indywidualna
      </p>
      <p className="text-3xl lg:text-5xl text-white font-semibold mb-6 mt-4">
        Omówienie w formie video
      </p>
      <span className="mainColorBg w-24 h-1 mb-12"></span>
      <div className="relative flex flex-col firstShadowLight overflow-hidden">
        <video
          className="rounded-xl lg:rounded-t-xl z-10 w-full translate-y-2"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/video-offer-min.mp4"
          ref={videoRef}
          onClick={handlePlayPause}
          controls={false}
        ></video>
        {!isPlaying && (
          <div
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-t-xl cursor-pointer z-50"
            onClick={handlePlayPause}
          >
            <FaRegCirclePlay className="text-6xl mainColor" />
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoWebsite;
