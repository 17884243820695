import React, { useState, useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

import BackgroundSquare from "../common/squareBg";
import LogoSlider from "../home/logoSlider";
import { FaPenToSquare } from "react-icons/fa6";

function Hero() {
  return (
    <section className="flex flex-col items-center justify-start gap-12 overflow-hidden relative pt-12 lg:pt-32">
      <div className="flex flex-col items-center justify-center px-6 pb-6 z-20">
        <img
          className="flex lg:hidden w-1/2"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
          alt=""
        />
        <h1 className="mainColor fontLight text-sm lg:text-xl">
          iFil Group - Nasze projekty i wdrożenia
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">Poznaj nasze</span> <br />
          <span className="whiteHeading fontRegular">zrealizowane</span>{" "}
          <span className="colorHeading fontRegular">projekty</span>
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 mb-12 w-full lg:w-2/3 text-center">
          Nie korzystamy z gotowych szablonów, każdy nasz Klient otrzymuje
          unikalny projekt dostosowany do jego potrzeb.
        </p>
        <a
          className="flex flex-col items-center justify-center gap-2"
          href="/wycena"
        >
          <button className="group firstButton flex flex-row items-center justify-center gap-2 mb-2 text-xl">
            <FaPenToSquare className="text-white text-xl group-hover:text-[#6f00d8] transition duration-100 ease-linear" />
            <span>Skorzystaj z bezpłatnej wyceny</span>
          </button>
          <p className="text-white opacity-60 text-xl">
            To zajmuje tylko 60 sekund!
          </p>
        </a>
      </div>
    </section>
  );
}
export default Hero;
