import { useEffect, useState } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import { FlipWords } from "../additional/flipWords";

function LowerRisk() {
  const words = ["ryzyko.", "koszty.", "czas."];

  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

  const { scrollYProgress } = useScroll();

  const translateXLeft = useTransform(scrollYProgress, [0.3, 0.4], [0, -200]);
  const translateXRight = useTransform(scrollYProgress, [0.3, 0.4], [-200, 0]);
  const translateXLeftMobile = useTransform(
    scrollYProgress,
    [0.28, 0.34],
    [-200, -400]
  );
  const translateXRightMobile = useTransform(
    scrollYProgress,
    [0.27, 0.32],
    [-200, 0]
  );

  useEffect(() => {
    controlsLeft.start({ opacity: 1 });
    controlsRight.start({ opacity: 1 });
  }, [controlsLeft, controlsRight]);

  return (
    <>
      <div className="w-full hidden lg:flex flex-col items-center justify-center blackColorBg overflow-x-hidden relative">
        <div className="grid grid-cols-2 relative gap-6">
          <div className="gradient-lowerrisk w-full h-full absolute z-20"></div>
          <div className="overflow-hidden">
            <div className="flex flex-col gap-6 w-full">
              <motion.div
                className="slide-right flex flex-row items-center justify-end gap-6"
                style={{ x: translateXRight }}
                animate={controlsRight}
              >
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-1%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-2%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-3%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-4%20Duz%CC%87y.jpeg"
                  alt=""
                />
              </motion.div>
              <motion.div
                className="slide-left flex flex-row items-center justify-end gap-6"
                style={{ x: translateXLeft }}
                animate={controlsLeft}
              >
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-5%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-6%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-7%20Duz%CC%87y.jpeg"
                  alt=""
                />
                <img
                  className="h-[200px] rounded-xl"
                  src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-8%20Duz%CC%87y.jpeg"
                  alt=""
                />
              </motion.div>
            </div>
          </div>
          <div className="z-50">
            <div className="w-full flex flex-col items-start justify-center px-6 lg:pl-0 lg:pr-24 z-50 h-full">
              <p className="text-white px-6 rounded-xl py-1 mixedGradients mb-6">
                Korzyści biznesowe
              </p>
              <h2 className="text-6xl whiteHeading text-left">
                Obniżamy <FlipWords words={words} />
              </h2>
              <p className="text-white w-full opacity-60 text-xl mb-6">
                Projekt informatyczny to nie tylko interfejs graficzny. Głównych
                problemów nie widać na pierwszy rzut oka! Zadbamy o każdy aspekt
                Twojej realizacji.
              </p>
              <div className="flex flex-wrap gap-2">
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Optymalizacja wydajności
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Pozycjonowanie
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Bezpieczeństwo danych
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Pomoc techniczna
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Poradniki
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Automatyzacja procesów
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Szybka reakcja na incydenty
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Najnowsze technologie
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Niskie koszty utrzymania
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Dedykowany opiekun
                </p>
                <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30 bg-gradient-to-br from-white/0 to-white/10 hover:to-violet-700/25 transition duration-300 ease-linear">
                  Wsparcie cały tydzień
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <motion.div
              className="slide-right flex flex-row items-center justify-center gap-6"
              style={{ x: translateXRight }}
              animate={controlsRight}
            >
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-9%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-10%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-1%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-2%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-3%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-14%20Duz%CC%87y.jpeg"
                alt=""
              />
            </motion.div>
            <motion.div
              className="slide-left flex flex-row items-center justify-center gap-6 mt-6"
              style={{ x: translateXLeft }}
              animate={controlsLeft}
            >
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-15%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-16%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-17%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-18%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-19%20Duz%CC%87y.jpeg"
                alt=""
              />
              <img
                className="h-[200px] rounded-xl"
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-20%20Duz%CC%87y.jpeg"
                alt=""
              />
            </motion.div>
          </div>
        </div>
      </div>
      <div className="w-full flex lg:hidden flex-col items-center gap-12 py-12 justify-center blackColorBg overflow-x-hidden relative">
        <motion.div
          className="slide-right flex flex-row items-center justify-center gap-6"
          style={{ x: translateXRightMobile }}
          animate={controlsRight}
        >
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-9%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-10%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-1%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-2%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-add-3%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-14%20Duz%CC%87y.jpeg"
            alt=""
          />
        </motion.div>
        <div className="z-50">
          <div className="w-full flex flex-col items-start justify-center px-6 lg:pl-0 lg:pr-24 z-50 h-full">
            <p className="text-white px-6 rounded-xl py-1 mixedGradients">
              Korzyści biznesowe
            </p>
            <h2 className="text-3xl whiteHeading text-left">
              Obniżamy{" "}
              <span className="-translate-x-2">
                <FlipWords words={words} />
              </span>
            </h2>
            <p className="text-white w-full mt-2 opacity-60 text-xl mb-6">
              Projekt informatyczny to nie tylko interfejs graficzny. Głównych
              problemów nie widać na pierwszy rzut oka! Zadbamy o każdy aspekt
              Twojej realizacji.
            </p>
            <div className="flex flex-wrap gap-2">
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Optymalizacja wydajności
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Pozycjonowanie
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Bezpieczeństwo danych
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Pomoc techniczna
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Poradniki
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Automatyzacja procesów
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Szybka reakcja na incydenty
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Najnowsze technologie
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Niskie koszty utrzymania
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Dedykowany opiekun
              </p>
              <p className="text-white border border-white px-4 py-1 rounded-xl text-xs lg:text-sm border-opacity-30">
                Wsparcie cały tydzień
              </p>
            </div>
          </div>
        </div>
        <motion.div
          className="slide-left flex flex-row items-center justify-center gap-6 mt-6"
          style={{ x: translateXLeftMobile }}
          animate={controlsLeft}
        >
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-15%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-16%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-17%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-18%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-19%20Duz%CC%87y.jpeg"
            alt=""
          />
          <img
            className="h-[200px] rounded-xl"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/Home/lower-risk/lowerrisk-20%20Duz%CC%87y.jpeg"
            alt=""
          />
        </motion.div>
      </div>
    </>
  );
}

export default LowerRisk;
