import { Helmet } from "react-helmet-async";
import Booking from "../components/account/booking";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
function AccountBooking() {
  return (
    <>
      <main className="relative z-40">
        <Helmet>
          <title>Panel Klienta | iFil Group</title>
          <meta name="title" content="Panel Klienta | iFil Group" />
          <meta
            name="description"
            content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
            key="desc"
          />
          <meta property="og:title" content="Panel Klienta | iFil Group" />
          <meta
            property="og:description"
            content="Tworzymy strony internetowe, sklepy on-line, aplikacje, systemy CRM z naciskiem na wydajność, bezpieczeństwo UI/UX, SEO oraz aspekt biznesowy"
          />
          <meta
            property="og:image"
            content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
          />
        </Helmet>
        <Booking />
      </main>
    </>
  );
}
export default AccountBooking;
