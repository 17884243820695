import Hero from "../components/reservation/hero";
import Content from "../components/reservation/content";
import { Gemini } from "../components/reservation/gemini";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { Helmet } from "react-helmet-async";
function Reservation() {
  return (
    <>
      <Helmet>
        <title>Systemy rezerwacji | iFil Group</title>
        <meta name="title" content="Systemy rezerwacji | iFil Group" />
        <meta
          name="description"
          content="Integracje systemów, aplikacji, stron i sklepów internetowych z wykorzystaniem sztucznej inteligencji, OpenAI oraz ChatGPT."
          key="desc"
        />
        <meta property="og:title" content="Systemy rezerwacji | iFil Group" />
        <meta
          property="og:description"
          content="Integracje systemów, aplikacji, stron i sklepów internetowych z wykorzystaniem sztucznej inteligencji, OpenAI oraz ChatGPT."
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/ifil-opengraph.png"
        />
      </Helmet>
      <Header />
      <main className="relative z-40">
        <Hero />
        <Content />
        <Gemini />
      </main>
      <Footer />
    </>
  );
}
export default Reservation;
