import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Category from "../components/support/blogCategory";

function SupportBlogCategory() {
  return (
    <>
      <Header />
      <main className="relative z-40">
        <Category />
      </main>
      <Footer />
    </>
  );
}
export default SupportBlogCategory;
