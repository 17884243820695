import React, { useState, useEffect } from "react";
import axios from "axios";
import BackgroundSquare from "../common/squareBg";
import BASE_URL from "../../config";

interface Post {
  title: string;
  slug: string;
  created_at: string;
  image_url: string;
  timing: string;
  category_title: string;
  category_slug: string;
}

interface Subcategory {
  title: string;
  slug: string;
}

interface Category {
  title: string;
  slug: string;
  description: string;
  posts: Post[];
  subcategories: Subcategory[];
}

function Blog() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [latestPost, setLatestPost] = useState<Post | null>(null);
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/blog/categories/`)
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`${BASE_URL}/api/blog/latest-post/`)
      .then((res) => setLatestPost(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`${BASE_URL}/api/blog/featured-posts/`)
      .then((res) => setFeaturedPosts(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <section className="blackColorBg relative flex min-h-screen">
        <div className="w-full h-full z-10 absolute">
          <div className="sticky top-0 w-full h-screen">
            <BackgroundSquare />
          </div>
        </div>
        <div className="flex flex-col z-50 px-6 py-12 lg:px-36 lg:pt-36 w-full gap-2 max-w-[1350px] mx-auto">
          <div className="flex flex-col lg:grid grid-cols-5 gap-12">
            <div className="lg:col-span-3">
              {/* Najnowszy wpis */}
              {latestPost && (
                <a
                  href={`/pomoc/baza-wiedzy/${latestPost.category_slug}/${latestPost.slug}`}
                  className="cursor-pointer rounded-xl flex flex-col overflow-hidden relative hover:opacity-70 transition duration-300 ease-linear group"
                >
                  <img src={latestPost.image_url} alt={latestPost.title} />
                  <div className="top-12 left-0 mixedGradients rounded-r-xl px-6 py-2 text-white text-center absolute">
                    Najnowszy wpis
                  </div>
                  <div className="mixedGradients p-6 flex flex-col gap-2">
                    <h2 className="text-2xl text-white group-hover:underline">
                      {latestPost.title}
                    </h2>
                    <p className="text-white text-base opacity-80">
                      {latestPost.timing} min | {latestPost.category_title}
                    </p>
                  </div>
                </a>
              )}
            </div>
            <div className="lg:col-span-2 flex flex-col">
              <p className="text-2xl font-bold text-white">Polecane wpisy</p>
              <span className="my-2 h-[5px] w-full mixedGradients block"></span>
              {/* Polecane wpisy */}
              {featuredPosts.map((post) => (
                <a
                  key={post.slug}
                  href={`/pomoc/baza-wiedzy/${post.category_slug}/${post.slug}`}
                  className="flex flex-col gap-1 border-b border-b-white border-opacity-20 py-2 group cursor-pointer"
                >
                  <h3 className="text-lg text-white group-hover:underline group-hover:opacity-50 transition duration-300 ease-linear">
                    {post.title}
                  </h3>
                  <p className="text-white opacity-50">{post.category_title}</p>
                </a>
              ))}
            </div>
          </div>

          {/* Kategorie */}
          {categories.map((category) => (
            <div
              key={category.slug}
              className="flex flex-col mt-6 lg:mt-12 gap-2 lg:gap-6"
            >
              <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-between gap-2 lg:gap-6">
                <h2 className="shrink-0 text-3xl text-white">
                  {category.title}
                </h2>
                <span className="my-2 h-[5px] w-full mixedGradients block"></span>
                <a
                  className="shrink-0"
                  href={`/pomoc/baza-wiedzy/${category.slug}`}
                >
                  <button className="firstButton firstShadowLight">
                    zobacz wszystko
                  </button>
                </a>
              </div>
              <div className="hidden lg:flex flex-col lg:flex-row lg:flex-wrap gap-2 lg:gap-6">
                {/* Renderowanie podkategorii */}
                {category.subcategories.map((subcategory) => (
                  <a
                    href={`/pomoc/baza-wiedzy/${category.slug}/modul/${subcategory.slug}`}
                    key={subcategory.slug}
                  >
                    <button className="relative group overflow-hidden border-opacity-80 border border-[#6f00d8] px-6 py-1 text-white text-opacity-90 text-sm rounded-[25px]">
                      <p className="z-20 relative">{subcategory.title}</p>
                      <div className="w-full h-full bg-gradient-to-br from-transparent to-[#6f00d8] opacity-20 group-hover:opacity-80 transition duration-300 ease-linear absolute z-10 block top-0 left-0"></div>
                    </button>
                  </a>
                ))}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {category.posts.slice(0, 3).map((post) => (
                  <a
                    key={post.slug}
                    href={`/pomoc/baza-wiedzy/${category.slug}/${post.slug}`}
                    className="border border-white border-opacity-10 cursor-pointer rounded-xl flex flex-col overflow-hidden relative hover:opacity-70 transition duration-300 ease-linear group"
                  >
                    <img src={post.image_url} alt={post.title} />
                    <div className="p-6 flex flex-col gap-2 relative h-full">
                      <div className="w-full h-full bg-gradient-to-br from-transparent to-[#6f00d8] opacity-30 group-hover:opacity-80 transition duration-300 ease-linear absolute z-20 block top-0 left-0"></div>
                      <div className="w-full h-full blur-3xl absolute z-20 block"></div>
                      <h2 className="text-base text-white group-hover:underline z-30">
                        {post.title}
                      </h2>
                      <p className="text-white text-sm opacity-80 z-30">
                        {post.timing} min | {post.category_title}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Blog;
