import { LuPenSquare } from "react-icons/lu";
import BackgroundSquare from "../common/squareBg";
import OfferForm from "../forms/offerForm";
import { FiPhoneCall } from "react-icons/fi";
import { PricingProcessModal } from "../modals/pricingProcess";
function Content() {
  return (
    <section className="blackColorBg flex flex-col items-center justify-start relative">
      <div className="w-full h-full object-cover absolute inset-0 z-10">
        <div className="w-full lg:h-screen sticky top-0">
          <BackgroundSquare />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-12 lg:py-36 z-20 px-6 lg:px-36">
        <h1 className="mainColor fontLight text-sm text-center lg:text-xl">
          iFil Group - Bezpłatna wycena
        </h1>
        <h2 className="text-3xl lg:text-7xl text-center mb-6">
          <span className="whiteHeading fontRegular">Uzyskaj bezpłatną</span>
          <br />
          <span className="whiteHeading fontRegular">wycenę</span>{" "}
          <span className="colorHeading fontRegular">Twojego projektu</span>
        </h2>
        <p className="text-white text-xl lg:text-2xl opacity-60 mb-12 w-full lg:w-2/3 text-center">
          Poniżej znajdziesz minimum informacji, których potrzebujemy do
          opracowania jak najlepszej oferty. O więcej szczegółów dopytamy już na
          etapie briefu podczas naszej potencjalnej współpracy.
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 mb-6">
          <a href="#formularz">
            <button className="firstButton flex flex-row items-center justify-center gap-2">
              <LuPenSquare className="text-xl text-white" />
              <span className="">Wypełnij formularz</span>
            </button>
          </a>
          <a href="/kontakt">
            <button className="secondButton flex flex-row items-center justify-center gap-2">
              <FiPhoneCall className="text-xl mainColor" />
              <span className="">Wolę porozmawiać</span>
            </button>
          </a>
        </div>
        <PricingProcessModal />
        <div
          id="formularz"
          className="w-full lg:w-2/3 flex flex-col items-start justify-start p-6 lg:p-12 rounded-xl firstShadowLight backdrop-blur-3xl"
        >
          <OfferForm />
        </div>
      </div>
    </section>
  );
}
export default Content;
