"use client";
import React, { useEffect } from "react";
import { IconArrowNarrowLeft, IconArrowNarrowRight } from "@tabler/icons-react";
import { cn } from "../../utils/cn";
import { motion } from "framer-motion";
import { HiCursorClick } from "react-icons/hi";

interface CarouselProps {
  items: JSX.Element[];
  initialScroll?: number;
}

type Card = {
  src: string;
  title: string;
  category: string;
  link: string;
};

export const Carousel = ({ items, initialScroll = 0 }: CarouselProps) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(true);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
      checkScrollability();
    }
  }, [initialScroll]);

  const checkScrollability = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="flex flex-col lg:flex-row items-start lg:items-end justify-start lg:justify-between px-6 lg:px-24">
        <div className="flex flex-col items-start justify-start pt-12 mb-6 lg:mb-0">
          <p className="text-white px-4 rounded-xl py-1 mixedGradients mb-6">
            Nasze oferta
          </p>
          <h3 className="text-3xl lg:text-6xl">
            <span className="whiteHeading fontRegular">Jak możemy</span>
            <br />
            <span className="whiteHeading fontRegular">pomóc Twojej</span>{" "}
            <span className="colorHeading fontRegular">marce</span>
            <span className="whiteHeading fontRegular">?</span>
          </h3>
        </div>
        <div className="flex justify-end gap-2">
          <button
            className="relative z-40 h-10 w-10 rounded-full mainColorBg flex items-center justify-center disabled:opacity-50 hover:opacity-70 transition duration-100 ease-linear"
            onClick={scrollLeft}
            disabled={!canScrollLeft}
          >
            <IconArrowNarrowLeft className="h-6 w-6 text-white" />
          </button>
          <button
            className="relative z-40 h-10 w-10 rounded-full mainColorBg flex items-center justify-center disabled:opacity-50 hover:opacity-70 transition duration-100 ease-linear"
            onClick={scrollRight}
            disabled={!canScrollRight}
          >
            <IconArrowNarrowRight className="h-6 w-6 text-white" />
          </button>
        </div>
      </div>
      <div className="relative w-full">
        <div
          className="flex w-full overflow-x-scroll overscroll-x-auto py-10 scroll-smooth [scrollbar-width:none]"
          ref={carouselRef}
          onScroll={checkScrollability}
        >
          <div
            className={cn(
              "absolute right-0 z-[1000] h-auto w-[5%] overflow-hidden bg-gradient-to-l"
            )}
          ></div>

          <div
            className={cn(
              "flex flex-row justify-start gap-4 max-w-[87%] mx-auto"
            )}
          >
            {items.map((item, index) => (
              <motion.div
                initial={{
                  opacity: 0,
                  y: 20,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * index,
                    ease: "easeOut",
                  },
                }}
                key={"card" + index}
                className="last:pr-[5%] md:last:pr-[33%] rounded-3xl"
              >
                {item}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center lg:items-center justify-start lg:justify-end px-6 lg:px-24 gap-4">
        <p className="text-white text-xl">Poznaj nasze wszystkie usługi</p>
        <a href="/oferta">
          <button className="firstButton">Oferta</button>
        </a>
      </div>
    </div>
  );
};

export const Card = ({ card, index }: { card: Card; index: number }) => {
  return (
    <motion.a
      href={card.link}
      className="group rounded-3xl bg-gray-100 dark:bg-neutral-900 h-80 w-72 md:h-[30rem] md:w-96 overflow-hidden flex flex-col items-start justify-start relative z-10"
    >
      <HiCursorClick className="absolute top-8 right-6 text-white text-2xl z-50 animate-pulse" />
      <div className="absolute h-full top-0 inset-x-0 bg-gradient-to-b from-black/90 to-black/30 z-30 pointer-events-none" />
      <div className="relative z-40 p-8 h-full flex flex-col items-start justify-between">
        <motion.p className="text-white text-xs lg:text-sm uppercase font-medium font-sans text-left mixedGradients px-4 rounded-xl py-1">
          {card.category}
        </motion.p>
        <motion.p className="text-white text-xl md:text-3xl max-w-xs text-left [text-wrap:balance]">
          {card.title}
        </motion.p>
      </div>
      <img
        src={card.src}
        alt={card.title}
        className="object-cover absolute z-10 inset-0 w-full h-full group-hover:scale-110 transition duration-300 ease-linear"
      />
    </motion.a>
  );
};
