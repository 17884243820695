import React from "react";
import { MacbookScroll } from "../additional/macbook";

export function MacbookScrollDemo() {
  return (
    <div className="hidden lg:block overflow-hidden blackColorBg w-full overflow-x-hidden">
      <MacbookScroll
        src={`https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/problems/vscode.png`}
        showGradient={false}
      />
    </div>
  );
}
